import action from "../helpers/rest-client";
import { PaginationOutDto } from "../types/pagination";

export class ErrorCommunity extends Error { }

const getFilters = async (payload: PaginationOutDto | null) => {
  if (!payload || Object.keys(payload).length === 0) return "";

  const sort_str =
    payload?._sort && payload?._sort_by
      ? `&_sort=${payload?._sort}&_sort_by=${payload?._sort_by}`
      : "";

  const paginate = `&paginate=${payload?.paginate !== undefined ? payload?.paginate : true
    }`;

  const group_id_in_str =
    !!payload?.group_id_in && payload?.group_id_in.length
      ? `&group_id_in=${payload?.group_id_in.toString()}`
      : "";

  const group_id_not_in_str =
    !!payload?.group_id_not_in && payload?.group_id_not_in.length
      ? `&group_id_not_in=${payload?.group_id_not_in.toString()}`
      : "";

  const experiences_gt_str = !!payload?.experiences_gt
    ? `&experiences_gt=${payload?.experiences_gt ? "1" : "0"} `
    : "";

  const free_experiences_gt_str = !!payload?.free_experiences_gt
    ? `&free_experiences_gt=${payload?.free_experiences_gt}`
    : "";

  const all_group_types_gt_str = !!payload?.all_group_types_gt
    ? `&all_group_types_gt=${payload?.all_group_types_gt}`
    : "";

  return `${paginate}${sort_str}${group_id_in_str}${group_id_not_in_str}${experiences_gt_str}${free_experiences_gt_str}${all_group_types_gt_str}`;
};

class Community {
  public async getSubscribers({ page, id, ...rest }: PaginationOutDto) {
    const filtersString = await getFilters(rest);

    return action
      .Get({
        url: `/api/community/${id}/subscribers?page=${page}${filtersString}`,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async exportSubscribers(payload?: PaginationOutDto) {
    const filtersString = payload ? await getFilters(payload) : "";

    action
      .Get({
        url: `/api/community/export/subscribers?${filtersString}`,
      })
      .then((response) => {
        const binary = new Uint8Array(response.data);

        const buftype =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8";

        const url = window.URL.createObjectURL(
          new Blob([binary], {
            type: buftype,
          })
        );

        // Create blob link to download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Suscriptores" + ".xlsx");

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      })
      .catch((error) => {
        return error;
      });
  }

  public async getCommunityMetrics() {
    return action
      .Get({
        url: `/api/community/metrics`,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw new ErrorCommunity("Error al obtener metricas de la comunidad.");
      });
  }

  public async subscribe({ idExpert, name, email }) {
    return action
      .Post({
        url: `/api/community/new-subscriber`,
        body: { idExpert, name, email },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }
}

const community = new Community();
export default community;
