import { ChangePasswordOutDto, SignInData, SignOutData, SignUpData } from "./../../types";
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";

export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";

export const SIGN_OUT = "SIGN_OUT";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_OUT_ERROR = "SIGN_OUT_ERROR";

export const PASSWORD_RECOVERY = "PASSWORD_RECOVERY";
export const PASSWORD_RECOVERY_SUCCESS = "PASSWORD_RECOVERY_SUCCESS";
export const PASSWORD_RECOVERY_ERROR = "PASSWORD_RECOVERY_ERROR";
export const RESET_PASSWORD_RECOVERY = "RESET_PASSWORD_RECOVERY";

export const SEND_PASSWORD_RECOVERY_EMAIL = "SEND_PASSWORD_RECOVERY_EMAIL";
export const SEND_PASSWORD_RECOVERY_EMAIL_SUCCESS = "SEND_PASSWORD_RECOVERY_EMAIL_SUCCESS";
export const SEND_PASSWORD_RECOVERY_EMAIL_ERROR = "SEND_PASSWORD_RECOVERY_EMAIL_ERROR";
export const RESET_SEND_PASSWORD_RECOVERY_EMAIL = "RESET_SEND_PASSWORD_RECOVERY_EMAIL";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const RESET_CHANGE_PASSWORD = "RESET_CHANGE_PASSWORD";

export const LOCAL_SIGN_UP_SUCCESS = "SIGN_IN_SUCCESS";
export const LOCAL_SIGN_UP_ERROR = "SIGN_IN_ERROR";

export interface SignIn {
  type: typeof SIGN_IN;
  payload: SignInData;
}
export interface SignInSuccess {
  type: typeof SIGN_IN_SUCCESS;
  payload: boolean | string;
}
export interface SignInOut {
  type: typeof SIGN_IN_ERROR;
  payload: boolean | string;
}

export interface SignUp {
  type: typeof SIGN_UP;
  payload: SignUpData;
}
export interface SignUpSuccess {
  type: typeof SIGN_UP_SUCCESS;
  payload: boolean | string;
}
export interface SignUpOut {
  type: typeof SIGN_UP_ERROR;
  payload: boolean | string;
}

export interface SignOut {
  type: typeof SIGN_OUT;
  payload: SignOutData;
}
export interface SignOutSuccess {
  type: typeof SIGN_OUT_SUCCESS;
  payload: boolean | string;
}
export interface SignOutOut {
  type: typeof SIGN_OUT_ERROR;
  payload: boolean | string;
}

export interface PasswordRecovery {
  type: typeof PASSWORD_RECOVERY;
  payload: string;
}
export interface PasswordRecoverySuccess {
  type: typeof PASSWORD_RECOVERY_SUCCESS;
  payload: null;
}
export interface PasswordRecoveryError {
  type: typeof PASSWORD_RECOVERY_ERROR;
  payload: boolean | string;
}
export interface ResetPasswordRecovery {
  type: typeof RESET_PASSWORD_RECOVERY;
  payload: null;
}


export interface ChangePassword {
  type: typeof CHANGE_PASSWORD;
  payload: ChangePasswordOutDto;
}
export interface ChangePasswordSuccess {
  type: typeof CHANGE_PASSWORD_SUCCESS;
  payload: null;
}
export interface ChangePasswordError {
  type: typeof CHANGE_PASSWORD_ERROR;
  payload: boolean | string;
}
export interface ResetChangePassword {
  type: typeof RESET_CHANGE_PASSWORD;
  payload: null;
}


export interface SendPasswordRecoveryEmail {
  type: typeof SEND_PASSWORD_RECOVERY_EMAIL;
  payload: { email: string };
}
export interface SendPasswordRecoveryEmailSuccess {
  type: typeof SEND_PASSWORD_RECOVERY_EMAIL_SUCCESS;
  payload: null;
}
export interface SendPasswordRecoveryEmailError {
  type: typeof SEND_PASSWORD_RECOVERY_EMAIL_ERROR;
  payload: boolean | string;
}
export interface ResetSendPasswordRecoveryEmail {
  type: typeof RESET_SEND_PASSWORD_RECOVERY_EMAIL;
  payload: null;
}

export interface State {
  signin: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  signup: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  signout: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  passwordRecovery: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  sendPasswordRecoveryEmail: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
  changePassword: {
    states: {
      loading: boolean;
      success: boolean;
      error: boolean | string;
    };
  };
}

export const initialState: State = {
  signin: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  signup: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  signout: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  passwordRecovery: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  sendPasswordRecoveryEmail: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  },
  changePassword: {
    states: {
      loading: false,
      success: false,
      error: false,
    },
  }
};

export type Actions =
  | SignIn
  | SignInSuccess
  | SignInOut
  | SignUp
  | SignUpSuccess
  | SignUpOut
  | SignOut
  | SignOutSuccess
  | SignOutOut
  | PasswordRecovery
  | PasswordRecoverySuccess
  | PasswordRecoveryError
  | ResetPasswordRecovery
  | SendPasswordRecoveryEmail
  | SendPasswordRecoveryEmailSuccess
  | SendPasswordRecoveryEmailError
  | ResetSendPasswordRecoveryEmail
  | ChangePassword
  | ChangePasswordSuccess
  | ChangePasswordError
  | ResetChangePassword;
