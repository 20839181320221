import Carousel from "../../../../../components/Carousel";
import { Separator } from "../../../../../components/StyledComponents";
import CardArticle from "../../../../Dashboard/Posts/components/CardArticle";
import { Post } from "../../../../../types/post";
import { connect } from "react-redux";
import { Group } from "../../../../../types";
import { Title } from './styles'

interface ComponentProps {
  group: Group;
  featuredPosts: { data: Post[] };
  featuredPostsStates: { loading: boolean; error: string | boolean; success: boolean };
}

const Component = ({ featuredPosts, featuredPostsStates, group }: ComponentProps) => {
  if (!featuredPosts || featuredPosts.data.length === 0 || featuredPostsStates.loading) {
    return null;
  }

  const filteredPosts = featuredPosts.data.filter(
    (p) => (!p.published_at && !!p.show_scheduled_post) || !!p.published_at
  );

  return (
    <>
      <Title>Pineados</Title>

      <Separator height={{ desktop: "16px", mobile: "16px" }} />

      <Carousel
        items={filteredPosts}
        renderItem={(post, index) => (
          <CardArticle
            key={`featured-content-${index}`}
            post={post}
            groupInformation={group}
            showPinAction={false}
          />
        )}
        slidesToShow={3}
        scrollAmount={250}
        animationSpeed={400}
        showArrows={filteredPosts?.length >= 4}
      />

      <Separator height={{ desktop: '26px', mobile: '26px' }} />
    </>
  );
};

const state = ({ userStore, postStore, groupStore }) => {
  const { data: featuredPosts, states: featuredPostsStates } = postStore.featuredPosts;
  const { data: group } = groupStore.group;
  return { group, featuredPosts, featuredPostsStates };
};

export default connect(state)(Component);
