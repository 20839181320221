import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
} from "react-router-dom";
import Layout from "../../components/Layout/MainLayout";
import ChangePassword from "./ChangePassword";
import PasswordRecovery from "./PasswordRecovery";
import PasswordRecoveryEmailSent from "./PasswordRecoveryEmailSent";

const Component = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/password-recovery`}
        render={(props) =>
          <Layout useLocation={useLocation}>
            <PasswordRecovery {...props} />
          </Layout>}
      />

      <Route
        path={`${path}/change-password`}
        render={(props) =>
          <Layout useLocation={useLocation}>
            <ChangePassword {...props} />
          </Layout>}
      />

      <Route
        path={`${path}/password-recovery-sent`}
        render={(props) =>
          <Layout useLocation={useLocation}>
            <PasswordRecoveryEmailSent {...props} />
          </Layout>}
      />

      <Route path={`${path}/*`} render={() => <Redirect to={"/404"} />} />
    </Switch>
  );
};

export default Component
