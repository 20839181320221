
import Grid from "../../../components/Grid";
import { initialValues, schema } from "../../../constants/forms/password-recovery";
import { Formik } from "formik";
import Image from '../../../components/Image'
import Button from "../../../components/Button";
import { Content, Title, Text } from "./styles";
import { connect, useDispatch } from "react-redux";
import Snackbar from "../../../components/Snackbar";
import {
  resetSendPasswordRecoveryEmail,
  sendPasswordRecoveryEmail,
} from "../../../store/actions/auth";
import emailIcon from "../../../assets/icons/open-email-inbox.svg";
import { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import arrowIcon from "../../../assets/icons/arrow_forward_up_right.svg";
import Icon from "../../../components/Icon";

interface ComponentProps {
  sendPasswordRecoveryEmailStates;
}

const emailProviders = {
  "gmail": { web: "https://mail.google.com/", mobile: "googlegmail://" },
  "yahoo": { web: "https://mail.yahoo.com/", mobile: "ymail://" },
  "outlook": { web: "https://outlook.live.com/mail/", mobile: "ms-outlook://" },
  "icloud": { web: "https://www.icloud.com/mail", mobile: "message://" },
  "live": { web: "https://outlook.live.com/mail/", mobile: "ms-outlook://" },
  "msn": { web: "https://outlook.live.com/mail/", mobile: "ms-outlook://" },
  "hotmail": { web: "https://outlook.live.com/mail/", mobile: "ms-outlook://" }
};


const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


const Component = ({
  sendPasswordRecoveryEmailStates,
}: ComponentProps) => {
  const theme = useTheme() as { logo: { small: string, big: string } };
  const [email, setEmail] = useState<string>('')
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const [timeoutDuration, setTimeoutDuration] = useState(60000); // 1 minuto inicial
  const [timeLeft, setTimeLeft] = useState(timeoutDuration / 1000);
  const history = useHistory();


  const onSubmit = async (email) => {
    setEmail(email);
    dispatch(sendPasswordRecoveryEmail({ email }));
    setIsDisabled(true);
    setTimeLeft(180);

    const countdown = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    setTimeout(() => {
      setIsDisabled(false);
      clearInterval(countdown);
    }, 180000);
  };

  useEffect(() => {
    const storedEmail = localStorage.getItem("passwordRecoveryStartedForEmail");
    if (!storedEmail) {
      history.replace("/auth/password-recovery");
    } else {
      setEmail(storedEmail);
    }

    return () => {
      localStorage.removeItem("passwordRecoveryStartedForEmail");
    };
  }, [history]);

  const openInbox = () => {
    const domain = email.split("@")[1].split(".")[0];
    const provider = emailProviders[domain];
    if (provider) {
      window.open(isMobile ? provider.mobile : provider.web, "_blank");
      // window.location.href = isMobile ? provider.mobile : provider.web;
    }
  };

  useEffect(() => {
    setIsDisabled(true);
    const countdown = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);

    setTimeout(() => {
      setIsDisabled(false);
      clearInterval(countdown);
    }, 60000);

    return () => clearInterval(countdown);
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
      <Grid.Container style={{ maxWidth: "370px" }}>
        <Grid.Row>
          <Grid.Col>
            <Content>
              <Image
                src={emailIcon}
                color="black"
                style={{ display: 'block' }}
                width="128px"
                height="auto"
                className="logo-desktop"
                alt="email"
                lazy
              />
            </Content>
          </Grid.Col>
        </Grid.Row>
        <div style={{ height: 34 }} />
        <Grid.Row>
          <Grid.Col>
            <Title>Continua desde tu casilla de email</Title>
          </Grid.Col>
        </Grid.Row>
        <div style={{ height: 22 }} />
        <Grid.Row>
          <Grid.Col>
            <Text>
              Te hemos enviado un correo electronico a {email}. Si no lo encuentras, <strong>por favor revisa la carpeta de SPAM.</strong>
            </Text>
          </Grid.Col>
        </Grid.Row>
        <div style={{ height: 22 }} />
        <Grid.Row>
          <Grid.Col>
          <Content>
              <div style={{ height: 22 }} />
              <div style={{ display: "flex", gap: 16, flexDirection: "column" }}>
                {emailProviders[email.split("@")[1]?.split(".")[0]] && (
                  <Button
                  onClick={openInbox}
                  options={{ type: "filled", size: "lg", skin: "lightBlue" }}
                  >
                    Abrir mi bandeja de entrada <Icon icon={arrowIcon} color="white" size="14px" style={{"margin-left": 5}} />
                  </Button>
                )}
                <Button
                  onClick={() => onSubmit(email)}
                  disabled={isDisabled}
                  options={{ type: "outline", size: "lg", skin: "purple" }}
                >
                  {isDisabled ? `Reenviar email (${timeLeft}s)` : "Reenviar email"}
                </Button>
                <Button
                  onClick={() => history.push("/auth/password-recovery")}
                  options={{ type: "outline", size: "lg", skin: "purple" }}
                >
                   {"< Recuperar otra cuenta"}
                </Button>
              </div>
            </Content>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </div>
  );
};

const state = ({ authStore }) => {
  const { states: sendPasswordRecoveryEmailStates } =
    authStore.sendPasswordRecoveryEmail;

  return {
    sendPasswordRecoveryEmailStates,
  };
};

export default connect(state)(Component);
