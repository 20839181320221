import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Separator } from "../../../../../components/StyledComponents";
import { Group } from "../../../../../types";
import { GetPostsOutDto, Post } from "../../../../../types/post";
import Grid from "../../../../../components/Grid";
import { Link as RrdLink, useLocation } from "react-router-dom";
import { Center } from "../../../../../components/StyledComponents";
import NoPostFound from '../NoPostFound';
import CardAticle from "../../../../Dashboard/Posts/components/CardArticle";
import {
  Filters, Filter, Input, Label, CardsGrid, Total, GlobalStyle, Title, HorizontalScroll,
  HorizontalScrollWrapper, CategoryName
} from "./styles";
import EmptyState from "../../../components/EmptyState";
import InfinityScroll from '../../../../../components/InfinityScroll'
import { getAllCategories } from "../../../../../store/actions/category";
import { CategoryDto } from "../../../../../types/category";
import { getPosts, resetGetPosts } from "../../../../../store/actions/post";
import slugify from "slugify";
import Carousel from "../../../../../components/Carousel";

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  allCategories: CategoryDto[];
  allCategoriesState: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  group,
  allCategories,
  allCategoriesState
}: ComponentProps) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [selectedCategory, setSelectedCategory] = useState<CategoryDto | undefined>(undefined);

  const categories = useMemo(() => {
    return [{
      id: "show-all",
      name: "Todos",
      count_posts: allCategories?.reduce((accumulator, currentValue) => accumulator + (Number(currentValue?.count_posts) || 0), 0)
    },
    ...(allCategories ? allCategories.filter(c => (c?.count_posts || 0) > 0) : [])
    ]
  }, [allCategories])

  const thereAreCategories = useMemo(() => {
    return !!allCategories && allCategories?.length > 0
  }, [allCategories])

  const thereArePosts = useMemo(() => {
    const foundPosts = allCategories?.map((categories) => categories?.last_posts).flat()
    return foundPosts?.length > 0
  }, [allCategories])

  /** Start the new pagination with new filter */
  const filterByCategory = (category) => {
    const newSelectedCategory = category === "show-all" ? undefined : category;
    setSelectedCategory(newSelectedCategory);
  }

  useEffect(() => {
    dispatch(getAllCategories({ group_id: group?.id, with_posts: true, only_showable_posts: false }));
  }, [])

  return (
    <>
      <GlobalStyle />

      <Grid.Container>
        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            <Filters>
              {categories?.filter(category => category?.count_posts && category?.count_posts > 0).map((category) => {

                return (
                  <Filter key={`category-filter-${category.id}`}>
                    <Input
                      name="filter"
                      type="radio"
                      id={`filter-${category.id}`}
                      value={category.id}
                      onChange={(event) => {
                        if (event.target.checked) {
                          filterByCategory(category)
                        }
                      }}
                      checked={(selectedCategory?.id === category.id) || (!selectedCategory && category.id === 'show-all')}
                    />
                    <Label htmlFor={`filter-${category.id}`}>
                      {category.name} {category?.count_posts && <Total>{category?.count_posts}</Total>}
                    </Label>
                  </Filter>
                );
              })}
            </Filters>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <div style={{ height: 36 }} />

      {(!thereAreCategories && !thereArePosts && !allCategoriesState.loading) ||
        (((!thereAreCategories && !thereArePosts) ||
          allCategoriesState.error) &&
          !allCategoriesState.loading) ? (
        <EmptyState text={
          <>
            <strong>Aún no se escribio ningún artículo.</strong>
            <br /> Aguarda a que se creen para verlos!
          </>
        } />
      ) : (
        <Grid.Container>
          <Grid.Row>
            <Grid.Col lg={10} offset={{ lg: 1 }}>
              {allCategories?.filter((category) => {
                const bool = category?.last_posts && category?.last_posts?.length > 0

                return !!selectedCategory && String(selectedCategory?.id) !== "show-all"
                  ? category.id === selectedCategory.id && bool
                  : bool
              })?.map((category, index) => {
                return (
                  <>
                    <Grid.Row>
                      <Grid.Col>
                        <Center horizontal="space" vertical="bottom">

                          <CategoryName>
                            {category?.name}
                          </CategoryName>

                          <RrdLink
                            style={{
                              flexShrink: 0,
                              color: "#6C5CE7",
                              textDecoration: "none",
                              borderBottom: "0px solid"
                            }}
                            to={{
                              pathname: `/group/${group?.id}/p/c/${category?.id}`,
                              state: { from: location?.pathname }
                            }}
                          >
                            Ver todos
                          </RrdLink>
                        </Center>
                      </Grid.Col>
                    </Grid.Row>

                    <div style={{ height: 6 }} />

                    <Grid.Row>
                      <Grid.Col>
                        {category?.last_posts && category?.last_posts?.filter(p => !!p.published_at || (!p.published_at && !!p.show_scheduled_post)).length > 0 ? (
                          <>
                            <Carousel
                              items={category?.last_posts?.filter(p => !!p.published_at || (!p.published_at && !!p.show_scheduled_post))}
                              renderItem={(post, index) => (
                                <CardAticle
                                  post={post}
                                  groupInformation={group}
                                  showPinAction={false}
                                />
                              )}
                              slidesToShow={3}
                              scrollAmount={250}
                              animationSpeed={400}
                              showArrows={category?.last_posts?.filter(p => !!p.published_at || (!p.published_at && !!p.show_scheduled_post))?.length >= 4}
                            />
                          </>
                        ) : (
                          <CardsGrid style={{ gridAutoRows: 205 }}>
                            <NoPostFound />
                          </CardsGrid>
                        )}
                      </Grid.Col>
                    </Grid.Row>

                    <div style={{ height: 26 }} />
                  </>
                )
              })}
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      )}

      <Separator height={{ desktop: "32px", mobile: "16px" }} />
    </>
  );
};

const state = ({ userStore, postStore, groupStore, categoryStore }) => {
  const { data: allCategories, states: allCategoriesState } =
    categoryStore.allCategories;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;
  const { data: userInformation, states: userInformationStates } =
    userStore.information;
  const { data: group, states: groupStates } = groupStore.group;

  return {
    subscriptions,
    subscriptionsStates,
    userInformation,
    userInformationStates,
    group,
    groupStates,
    allCategories,
    allCategoriesState,
  };
};

export default connect(state)(Component);
