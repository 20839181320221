import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Success } from "./styles";
import { getUrlParamByName } from "../../../helpers/get-url-param";
import Loader from "../../../components/Loader";
import Grid from "../../../components/Grid";
import { saveUserSubscription } from "../../../store/actions/subscription";
import logo from "./../../../assets/logo.svg";
import { getCookie } from "../../../helpers/cookies-handler";
import { User } from "../../../types";
import { newFreeSubscription } from "../../../helpers/newFreeSubscriptions";

interface ComponentProps {
  subscription;
  subscriptionStates;
  match;
  userInformation: User
}



const Component = ({
  match,
  subscription,
  subscriptionStates,
  userInformation
}: ComponentProps) => {
  const [groupId, setGroupId] = useState();
  const dispatch = useDispatch();

  const email = localStorage.getItem("KLOUSER_SUBSCRIBER_EMAIL") || "";
  const name = localStorage.getItem("KLOUSER_SUBSCRIBER_NAME") || "";

  useEffect(() => {
    if (match.params) {
      setGroupId(match.params.idGroup);
    }
  }, [match]);

  useEffect(() => {
    if (groupId) {
      dispatch(
        saveUserSubscription({
          isFree: true,
          groupId: Number(groupId),
          referral_id: getCookie("referral_id"),
          email: !!userInformation ? userInformation?.email : email,
          name: !!userInformation ? `${userInformation?.name} ${userInformation?.last_name}` : name,
        })
      );
    }
  }, [groupId, userInformation]);

  useEffect(() => {
    if (subscriptionStates.success && subscription?.group?.slug) {
      newFreeSubscription(subscription, groupId)
      window.location.href = `/group/${subscription.group.slug}/activity-summary`;
    }
  }, [subscriptionStates, subscription]);

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <Success>
            <img src={logo} alt="Klouser" width="183px" height="36px" />
            <p>Estamos procesando tu subscripción.</p>
            <Loader color="#bb85ff" />
          </Success>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

const state = ({ subscriptionStore, userStore }) => {
  const { data: subscription, states: subscriptionStates } =
    subscriptionStore.userSubscription;
  const { data: userInformation } = userStore.information;

  return {
    userInformation,
    subscription,
    subscriptionStates,
  };
};
export default connect(state)(Component);
