import Helmet from "react-helmet";
import { connect, useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import Grid from "../../../components/Grid";
import Loader from "../../../components/LogoLoader";
import { getGroupBySlug, resetGetGroup } from "../../../store/actions/group";
import { Group, SessionInDto, Subscription, User } from "../../../types";
import NextSession from "./components/NextSessions";
import SessionExpired from "./components/SessionsExpired";
import { Layout, useLayout } from "../../../providers/LayoutProvider";
import NoSubscribed from "../components/NoSubscribed";
import { getAllSessions, resetGetAllSessions } from "../../../store/actions/session";
import { isOwnGroup } from "../../../helpers/isOwnGroup";
import { Redirect } from "react-router-dom";
import { isCongreso } from "../../../helpers/isCongreso";
import { isCommunity } from "../../../helpers/isCommunity";
import EmptyState from "../components/EmptyState";
import LastSessionsExpired from "./components/LastSessionsExpired";
import { Tabs, Tab, TabsContainer, TabButton } from './styles'
import { useWindowSize } from "../../../hooks/useWindowSize";
import WelcomeSection from "../components/WelcomeSection";
import { GetWelcomeContentPostInDto } from "../../../types/welcome-section";
import { getUserPreferences } from "../../../store/actions/preferences";
import MPVerifyingPaymentSection from "../components/MPVerifyingPaymentSection";
import MPDisapprovedPaymentSection from "../components/MPNewSubscribersDisapprovedPaymentSection";
import MPVerifyYourPaymentInformationSection from "../components/MPVerifyYourPaymentInformationSection";
import MPOldSubscribersDisapprovedPaymentSection from "../components/MPOldSubscribersDisapprovedPaymentSection";
import { showPreference } from "../../../helpers/preferenceVisible";
import { isSubscribed } from "../../../helpers/isSubscribed";
import { getUserSubscriptions } from "../../../store/actions/user";

interface ComponentProps {
  subscriptions: Subscription[];
  subscriptionsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  isLoggedIn: boolean;
  sessions: SessionInDto[];
  sessionsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  match;
  user: User;
  userStates
  welcomeContentSection: GetWelcomeContentPostInDto
  preferences
  preferencesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  subscriptions,
  subscriptionsStates,
  user,
  userStates,
  sessions,
  sessionsStates,
  group,
  groupStates,
  match,
  preferences,
  preferencesStates,
  welcomeContentSection


}: ComponentProps) => {
  const dispatch = useDispatch();
  const { setLayout, layout } = useLayout();
  const { isMobile } = useWindowSize()
  const [activeTab, setActiveTab] = useState<string>("calendar");
  const [goToSurvey, setGoTuSurvey] = useState<boolean>(false)

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      toolbar: {
        visible: isOwnGroup({ group, expertId: user?.expert?.id })
      },
      header: {
        visible: true,
        beforeHeader: <><MPVerifyingPaymentSection /><MPVerifyYourPaymentInformationSection /><MPDisapprovedPaymentSection /><MPOldSubscribersDisapprovedPaymentSection /></>
      },
      body: {
        visible: true,
      },
    }),
    [layout, subscriptions, group, user]
  );

  const showMinimized = useMemo(() => {
    if (!!preferences && !!group) {
      const slug = `minimize-welcome-section-${group?.welcome_section}`
      return preferences?.find(preference => preference.preferenceSlug === slug)
    }
  }, [preferences, group])


  const redirect = useMemo(() => {
    if (!!group) {
      return groupStates?.error || isCongreso(group?.id) || isCommunity(group?.group_type) || (!isOwnGroup({ group, expertId: user?.expert?.id }) && group?.status === 'draft')
    }

    return false
  }, [group, user])

  useEffect(() => {
    if (
      !!match?.params &&
      (!group?.slug || (!!group?.slug && group?.slug !== match?.params?.group))
    ) {
      dispatch(resetGetAllSessions());
      match?.params && dispatch(getGroupBySlug(match?.params?.group));
    }
  }, [match]);

  useEffect(() => {
    dispatch(getUserPreferences())
    dispatch(getUserSubscriptions());

    return () => {
      dispatch(resetGetGroup())
    }
  }, [])

  useEffect(() => {
    if (group?.survey) {
      setGoTuSurvey(
        showPreference({
          preferences,
          preferencesStates,
          id: `survey-${group?.survey}`,
        })
      )
    }
  }, [preferences, preferencesStates, group, subscriptions])

  useEffect(() => {
    setLayout(layoutData);
  }, [subscriptions, group]);

  useEffect(() => {
    if (!!group && group?.id !== match?.params?.group && !sessions) {
      match.params && dispatch(getAllSessions({ idGroup: group?.id }));
    }
  }, [group]);

  if (
    preferencesStates.loading ||
    groupStates.loading ||
    subscriptionsStates.loading ||
    userStates.loading
  ) {
    return <Loader />;
  }

  if (goToSurvey && !isOwnGroup({ group, expertId: user?.expert?.id }) && isSubscribed(subscriptions, group?.id)) {
    return <Redirect to={`/group/${group.slug}/survey`} />;
  }

  if (redirect) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <Helmet>
        ‍<title></title>‍
        <meta name="description" content="" />
      </Helmet>

      {!!group && isMobile && (
        <>
          <TabsContainer>
            <div style={{ height: 36 }} />
            <Tabs>
              <Tab>
                <TabButton
                  onClick={() => setActiveTab("calendar")}
                  active={activeTab === 'calendar'}
                >
                  Calendario
                </TabButton>
              </Tab>
              <Tab>
                <TabButton
                  onClick={() => setActiveTab("past")}
                  active={activeTab === 'past'}
                >
                  Grabaciones
                </TabButton>
              </Tab>
            </Tabs>
          </TabsContainer>

          <div style={{ height: 36 }} />
        </>
      )}

      {!showMinimized && (
        <Grid.Container>
          <Grid.Row>
            <Grid.Col lg={10} offset={{ lg: 1 }}>
              <WelcomeSection minimized={true} showCloseButton={true} />
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      )}

      <Grid.Container>
        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            {(!sessions || sessions.length <= 0) && !sessionsStates.loading ? (
              <div style={{ textAlign: "center" }}>
                <EmptyState text={
                  <>
                    <strong>Aún no se han planeado encuentros en vivo.</strong>
                    <br /> No dejes de prestar atención a las novedades.
                  </>
                } />
              </div>
            ) : (
              <>
                {(!isMobile || activeTab === "calendar") && (
                  <>
                    <NextSession />
                    <div style={{ height: 26 }} />
                  </>
                )}

                {(!isMobile || activeTab === "past") && (
                  <>
                    <LastSessionsExpired />
                    <div style={{ height: 26 }} />

                    <SessionExpired />
                    <div style={{ height: 36 }} />
                  </>
                )}

              </>
            )}
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <NoSubscribed />
    </>
  )
};

const state = ({ userStore, sessionStore, groupStore, preferencesStore, welcomeContentStore }) => {
  const { isLoggedIn } = userStore;
  const { data: user, states: userStates } = userStore.information;
  const { data: sessions, states: sessionsStates } = sessionStore.all;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: subscriptions, states: subscriptionsStates } = userStore.subscriptions;
  const { data: preferences, states: preferencesStates } = preferencesStore.preferences;
  const { data: welcomeContentSection } = welcomeContentStore.welcomeContentSection;

  return {
    user,
    userStates,
    subscriptions,
    subscriptionsStates,
    isLoggedIn,
    sessions,
    sessionsStates,
    group,
    groupStates,
    preferences,
    preferencesStates,
    welcomeContentSection
  };
};

export default connect(state)(Component);
