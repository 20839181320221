import * as Yup from "yup";
import services from "../../services";


let typingTimeout;


export const schema = ({ validationRunning }) => Yup.object().shape({
  email: Yup.string()
    .required("Ingresa tu corréo electrónico")
    .trim("El corréo electrónico no puede contener espacio")
    .email("Ingresa un corréo electrónico válido")
    .test(
      "checkUserEmailExist",
      `No existe una cuenta con el email. Revisa si escribiste bien tu correo y si es la cuenta con la que te registraste.`,
      function async(email) {
        //clear timeout if user is typing and timeout is set
        if (typingTimeout) clearTimeout(typingTimeout);

        return new Promise((resolve, reject) => {
          //delay until user stops typing

          typingTimeout = setTimeout(async () => {
            //check if email is valid
            if (!email) return resolve(true);

            validationRunning(true)

            const { response } = await services.auth.validateEmail(email);

            validationRunning(false)

            if (response?.status > 500) {
              return resolve(
                this.createError({
                  message: "Error inesperado al validar el e-mail",
                })
              );
            }

            if (response?.status > 400) {
              return resolve(false);
            }

            if (!response || response?.status < 400) {
              return resolve(true);
            }
          }, 1000);
        });
      }
    )
});

interface FormValues {
  email: string
}

export const initialValues: FormValues = {
  email: ''
};