import styled from "styled-components";
import LinkUi from "../../../../../components/Link";

export const Title = styled("div")`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
`;

export const Text = styled("p")`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin: 16px 0px 32px;
`;

export const LossBenefit = styled("li")`
  display: flex;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 3px;
  
`;


export const AlignEnd = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`

export const Error = styled("span")`
  margin-top: 20px;
  font-size:12px;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  width: 100%;
  color: #ff0000;
`



export const Link = styled(LinkUi)`
  margin: 0px auto 20px;

  &:first-child {
    width: auto;
  }

  &:last-child {
    margin-bottom: 0px
  }

  @media (max-width: ${(props) => props.theme.mediaBreakpoints.smMax}) {
    &:first-child {
      width: 100%;
    }
  }

  @media (min-width: ${(props) => props.theme.mediaBreakpoints.mdMin}) and (max-width: ${(props) => props.theme.mediaBreakpoints.mdMax}) {
    &:first-child {
      width: 42%;
    }
  }
`;