import Link from "../../../../components/Link";
import { Page } from "../../components/styles";
import Loader from '../../../../components/Loader';
import LogoLoader from "../../../../components/LogoLoader";
import Confetti from 'react-confetti';
import {
  getYouTubeUrl,
  isYoutubeUrl,
  isInstagramUrl,
  isVimeoUrl,
  getVimeoVideoIdFromUrl
} from "../../../../helpers/you-tube";
import AspectRatio from "../../../../components/AspectRatio";
import MPBlockedSection from '../../../Group/components/MPBlockedSection'
import {
  Text,
  Title,
  Image,
  Audio,
  BlockContentMessage,
  BlockContentMessageTitle,
  InLine,
  WelcomeContentTitle,
  NextContentLink,
  NextContentButton,
  PrevContentLink,
  GlobalStyles,
  LoaderWrapper,
  LoaderBackground
} from "./styles";
import linkIcon from "../../../../assets/icons/open-link.svg";
import Icon from "../../../../components/Icon";
import Helmet from "react-helmet";
import Grid from "../../../../components/Grid";
import { connect, useDispatch } from "react-redux";
import { createRef, useCallback, useEffect, useMemo, useState } from "react";
import slugify from "slugify";
import userAvatar from "../../../../assets/avatar.svg";
import { AttachedFile } from "../../../../types/attached-file";
import InstagramReel from "../components/InstagramReel";
import VimeoVideo from "../components/VimeoVideo";

import { useMarkdown } from "../../../../hooks/useMarkdown";
import { RemirrorRenderer } from "@remirror/react-renderer";
import { hideModal, showModal } from "../../../../store/actions/modal";
import { getUrlParamByName } from "../../../../helpers/get-url-param";
import Header from "./components/Header";
import { useWindowSize } from "../../../../hooks/useFormHook";
import { DateSpliteDto, dateSpliter } from "../../../../helpers/dates-hanlder";
import { Redirect, useHistory, useLocation } from "react-router";
import { useLayout } from "../../../../providers/LayoutProvider";
import { resetSubscription } from "../../../../store/actions/community";
import { getFreeSubscriptions } from "../../../../helpers/getFreeSubscriptions";
import { isSubscribed } from "../../../../helpers/isSubscribed";
import PdfViewer from '../../../../components/PdfViewer';
import ArrowBackIcon from '../../../../assets/icons/arrow-backward.svg'
import ArrowForwardIcon from '../../../../assets/icons/arrow-foward.svg'
import NoSubscribed from "../components/NoSubscribed";
import { finishWelcomeContentPost, getWelcomeContentPost, finishWelcomeContentSection, resetFinishWelcomeContentPost, resetFinishWelcomeContentSection, resetGetWelcomeContentPost } from "../../../../store/actions/welcome-content";
import { WelcomeContentPostDto } from '../../../../types/welcome-section'
import ProgressBar from './components/ProgressBar'
import ModalConfirmDelete from "../components/ModalConfirmDelete";
import ModalCongrats from "./components/ModalCongrats";
interface ComponentProps {
  userInformation;
  userSubscriptions;
  welcomeContentPost: WelcomeContentPostDto;
  welcomeContentPostStates;
  match;
  avatar?: any;
  isLoggedIn: boolean;
  subscriptionStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  finishWelcomeContentPostStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
  finishWelcomeContentSectionStates: {
    success: boolean;
    loading: boolean;
    error: boolean | string;
  };
}

interface LocationState {
  from: string;
}

const Loading = ({ children, isLoading }) => {
  return (
    <LoaderWrapper>
      {children}
      {isLoading && (
        <LoaderBackground >
          <Loader />
        </LoaderBackground>
      )}
    </LoaderWrapper>
  )
}

const Component = ({
  userInformation,
  userSubscriptions,
  welcomeContentPost,
  welcomeContentPostStates,
  match,
  subscriptionStates,
  isLoggedIn,
  finishWelcomeContentPostStates,
  finishWelcomeContentSectionStates
}: ComponentProps) => {
  const location = useLocation()
  const previousPage = (location?.state as LocationState)?.from;

  const { typeMap, markMap, jsonStr, textStr } = useMarkdown({
    brief: welcomeContentPost?.body,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [isConfettiVisible, setIsConfettiVisible] = useState(false);
  const { layout, setLayout } = useLayout();
  const { isMobile, width, height } = useWindowSize();
  const [createdAt, setCreatedAt] = useState<DateSpliteDto>();
  const [audioFiles, setAudioFiles] = useState<AttachedFile[]>([]);
  const [imageFiles, setImageFiles] = useState<AttachedFile[]>([]);
  const [pdfFiles, setPdfFiles] = useState<AttachedFile[]>([]);
  const [videoFiles, setVideoFiles] = useState<AttachedFile[]>([]);
  const [showSubscriptionModal, setShopSubscriptionModal] = useState(false);
  const [isCommunityPost, setIsCommunityPost] = useState(false);
  const [isFreePost, setIsFreePost] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [viewAsSubscriber, setViewAsSubscriber] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 });


  const [videoSizes, setVideoSizes] = useState<{
    aspectRatio: number;
    width: number;
    height: number;
  }>();

  const videoRef = createRef<HTMLVideoElement>();

  const handleLoad = () => {
    const videoElement = videoRef?.current;

    if (videoElement) {
      if (videoElement.videoWidth && videoElement.videoHeight) {
        const aspectRatio =
          videoElement?.videoWidth / videoElement?.videoHeight;
        setVideoSizes({
          aspectRatio: aspectRatio,
          width: videoElement.videoWidth,
          height: videoElement?.videoHeight,
        });
      }
    }
  };

  const nextWelcomeContentPostUrl = useMemo(() => `/group/${welcomeContentPost?.welcome_section?.group?.id}/wc/${welcomeContentPost?.next_content?.id}`, [welcomeContentPost])
  const activitySummaryUrl = useMemo(() => `/group/${welcomeContentPost?.welcome_section?.group?.slug}/activity-summary`, [welcomeContentPost])

  const finishWelcomContent = useCallback((e) => {
    const button = e.currentTarget;
    const buttonRect = button.getBoundingClientRect();
    const x = buttonRect.left + buttonRect.width / 2;
    const y = buttonRect.top + buttonRect.height / 2;

    setPosition({ x, y });

    dispatch(finishWelcomeContentSection({ idWelcomeContentSection: welcomeContentPost?.welcome_section?.id }))
  }, [welcomeContentPost])

  const goToNextWelcomContentPost = useCallback(() => {
    if (welcomeContentPost?.completed) {
      window.location.href = nextWelcomeContentPostUrl;
    } else {
      dispatch(finishWelcomeContentPost({ idWelcomeContentPost: welcomeContentPost?.id }))
    }
  }, [welcomeContentPost])


  const isUserSubscribed = useMemo(() => {
    return isLoggedIn && isSubscribed(userSubscriptions, welcomeContentPost?.welcome_section?.group?.id)
  }, [userSubscriptions, welcomeContentPost, isLoggedIn])

  useEffect(() => {
    //TODO consultar con jona que onda esto, le agregue el !welcomeContentPost para evitar requests de mas
    if (!!match.params && !welcomeContentPost && !welcomeContentPostStates.loading) {
      dispatch(getWelcomeContentPost({ idWelcomeContentPost: match.params.id }));
    }
  }, [match]);

  useEffect(() => {
    setCreatedAt(dateSpliter(welcomeContentPost?.published_at));

    if (!!welcomeContentPost && !!welcomeContentPost?.attached_files) {
      setAudioFiles(
        welcomeContentPost?.attached_files?.filter((attached_file) =>
          attached_file?.mime?.includes("audio")
        )
      );

      setImageFiles(
        welcomeContentPost?.attached_files?.filter((attached_file) =>
          attached_file?.mime?.includes("image")
        )
      );

      setPdfFiles(
        welcomeContentPost?.attached_files?.filter((attached_file) =>
          attached_file?.mime?.includes("pdf")
        )
      );

      setVideoFiles(
        welcomeContentPost?.attached_files?.filter((attached_file) =>
          attached_file?.mime?.includes("video")
        )
      );
    }
  }, [welcomeContentPost]);

  useEffect(() => {
    const isLoggedIn = !!userInformation;
    const isSubscribed =
      userSubscriptions?.filter(
        (subscription) => subscription.id === welcomeContentPost?.welcome_section?.group?.id
      ).length > 0;
    const isPostOwner =
      !!welcomeContentPost &&
      !!userInformation &&
      welcomeContentPost?.welcome_section?.group?.expert?.id === userInformation?.expert?.id;
    const isCommunityPost = welcomeContentPost?.welcome_section?.group?.group_type === "community";

    setIsFreePost(isFreePost);
    setIsCommunityPost(isCommunityPost);
    setIsOwner(isPostOwner);

    setShopSubscriptionModal(
      isCommunityPost &&
      (!isLoggedIn || (isLoggedIn && !isSubscribed && !isPostOwner))
    );
  }, [welcomeContentPost, userInformation]);


  useEffect(() => {
    if (showSubscriptionModal) {
      dispatch(showModal("ask-for-user-information"))
    }
  }, [showSubscriptionModal])

  const backUrl = useMemo(() => {
    if (!!welcomeContentPost && isOwner && !!previousPage) {
      return previousPage;
    } else {

      // if(getUrlParamByName("new") === "t"){
      //   return `/dashboard/experience/${welcomeContentPost?.welcome_section?.group?.id}/configuration`
      // }

      return `/group/${welcomeContentPost?.welcome_section?.group?.slug}/activity-summary/`;
    }
  }, [isOwner, welcomeContentPost, previousPage])

  const headerTitle = useMemo(() => {
    return "Contenido de Bienvenida"
  }, [welcomeContentPost])

  useEffect(() => {
    if (isOwner) {
      if (getUrlParamByName("new") === "t") {
        dispatch(showModal("modal-new-post-created"));
        window.history.pushState(null, document.title, history.location.pathname);
      }

      if (getUrlParamByName("viewAsSubscriber") === "true") {
        setViewAsSubscriber(true)
      }
    }
  }, [isOwner]);

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: headerTitle,
        back: backUrl,
        showBack: welcomeContentPost?.welcome_section?.group?.group_type !== "community"
      },
    });
  }, [welcomeContentPost, backUrl, headerTitle, isOwner]);

  useEffect(() => {
    if (subscriptionStates?.success) {
      dispatch(resetSubscription());
    }
  }, [subscriptionStates]);

  useEffect(() => {
    return () => {
      dispatch(resetGetWelcomeContentPost())
    }
  }, [])

  const isMpInactiveSubscription = useMemo(() => {
    const groupSubscription = userSubscriptions?.find(subscription => subscription?.group?.id === welcomeContentPost?.welcome_section?.group)
    const mpInactiveSubscriptionStates = ['retrying', 'not_paid']
    return groupSubscription?.status && mpInactiveSubscriptionStates.includes(groupSubscription?.status)
  }, [userSubscriptions, welcomeContentPost])

  useEffect(() => {
    if (!!userSubscriptions) {
      const regex = /\/group\/([^\/]+)/;
      const result = location.pathname.match(regex);
      const id = result && result[1]
      const pendingInvite = userSubscriptions?.find((subscrition) => subscrition?.group?.id === Number(id) && subscrition.status === 'pending_invite')

      if (!!pendingInvite) {
        dispatch(showModal("pending-invitation"))
      }
    }
  }, [userSubscriptions, location]);

  useEffect(() => {
    if (finishWelcomeContentPostStates.success) {
      dispatch(resetFinishWelcomeContentPost())
      window.location.href = nextWelcomeContentPostUrl;
    }
  }, [finishWelcomeContentPostStates])

  useEffect(() => {
    if (finishWelcomeContentSectionStates.success) {
      dispatch(resetFinishWelcomeContentSection())
      setIsConfettiVisible(true);
      setTimeout(() => {
        dispatch(showModal("section-congratulation"))
      }, 1000); // 1 seconds

      setTimeout(() => {
        setIsConfettiVisible(false);
        window.location.href = `${activitySummaryUrl}`;
      }, 4000); // 4 seconds
    }
  }, [finishWelcomeContentSectionStates])

  if (welcomeContentPostStates.error) {
    return <Redirect to="/404" />;
  }

  if (welcomeContentPostStates.loading) {
    return <LogoLoader />;
  }

  return (
    <Page
      style={{
        ...(isFreePost &&
          !isCommunityPost && { paddingBottom: isMobile ? "168px" : "252px" }),
      }}
    >
      <GlobalStyles />

      <Helmet>
        <title>
          {`${welcomeContentPost?.welcome_section?.group?.group_name} - ${welcomeContentPost?.title}`}
        </title>
        <meta name="description" content={`${welcomeContentPost?.welcome_section?.group?.group_name} - ${welcomeContentPost?.title}`} />
      </Helmet>



      <Grid.Container style={{ position: 'relative', zIndex: 1 }}>
        <Grid.Row>
          <Grid.Col lg={8} offset={{ lg: 2 }}>
            <div style={{ height: 16 }} />

            <Header viewAsSubscriber={viewAsSubscriber} />

            <div style={{ height: 36 }} />

            <Title>{welcomeContentPost?.title}</Title>

            <div style={{ height: 32 }} />

            <>

              {videoFiles.length > 0 &&
                videoFiles.map((attached_file) => (
                  <>
                    <video
                      onContextMenu={e => e.preventDefault()}
                      controlsList="nodownload"
                      ref={videoRef}
                      controls
                      width="100%"
                      key={`attached-file-${slugify(attached_file.original_name)}`}
                      style={{
                        marginBottom: "10px",
                        display: "block",
                        ...(videoSizes && {
                          maxHeight:
                            videoSizes?.height > 640
                              ? videoSizes?.height / 2
                              : videoSizes?.height,
                          maxWidth:
                            videoSizes?.width > 1280
                              ? videoSizes?.width / 2
                              : videoSizes?.width,
                        }),
                      }}
                      onLoad={handleLoad}
                      onLoadedMetadata={handleLoad}
                    >
                      <source src={attached_file.signed_url} type={attached_file.mime} />
                      Tu navegador no soporta Video.
                    </video >

                    <div style={{ height: 16 }} />
                  </>
                )
                )}

              {!!welcomeContentPost?.attached_url && (
                <>
                  {isYoutubeUrl(welcomeContentPost?.attached_url) && (
                    <AspectRatio
                      ratio="16/9"
                      maxWidth={940}
                      key={`attached-file-${slugify(
                        welcomeContentPost?.attached_url
                      )}`}
                    >
                      <iframe
                        style={{ maxWidth: "100%" }}
                        src={getYouTubeUrl(welcomeContentPost?.attached_url)}
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </AspectRatio>
                  )}

                  {isVimeoUrl(welcomeContentPost?.attached_url) && (
                    <VimeoVideo videoId={getVimeoVideoIdFromUrl(welcomeContentPost?.attached_url)} isPreview={true} />
                  )}

                  {isInstagramUrl(welcomeContentPost?.attached_url) && (
                    <InstagramReel
                      src={welcomeContentPost?.attached_url}
                      key={`attached-file-${slugify(
                        welcomeContentPost?.attached_url
                      )}`}
                    />
                  )}

                  {(!isVimeoUrl(welcomeContentPost?.attached_url)) && !isYoutubeUrl(welcomeContentPost?.attached_url) &&
                    !isInstagramUrl(welcomeContentPost?.attached_url) && (
                      <Link
                        target="_blank"
                        href={welcomeContentPost?.attached_url}
                        options={{
                          type: "filled",
                          skin: "purple",
                          size: "lg",
                          native: false,
                        }}
                      >
                        <InLine style={{ gap: "10px" }}>
                          Click aquí para acceder al contenido
                          <Icon icon={linkIcon} color="white" />
                        </InLine>
                      </Link>
                    )}

                  <div style={{ height: 16 }} />


                </>
              )}


              {audioFiles.length > 0 &&
                audioFiles.map((attached_file) => (
                  <>
                    <Audio key={`attached-file-${slugify(attached_file.original_name)}`}>
                      <span>
                        Escucha el audio:{" "}
                        {attached_file.original_name.replace(".mp3", "").replace(".ogg", "")}
                      </span>
                      <audio
                        controls
                        onContextMenu={e => welcomeContentPost.block_files_download ? e.preventDefault() : "undefined"}
                        controlsList={welcomeContentPost.block_files_download ? "nodownload" : undefined}
                        style={{
                          margin: "10px 0px 0px 0px",
                          height: "40px",
                          width: "100%",
                        }}
                      >
                        <source
                          src={attached_file.signed_url}
                          type={attached_file.mime}
                        />
                      </audio>
                    </Audio>
                    <div style={{ height: 16 }} />
                  </>
                )
                )}

              {imageFiles.length > 0 &&
                imageFiles.map((attached_file) => (
                  <>
                    <Image
                      style={{ display: 'block' }}
                      onContextMenu={e => welcomeContentPost.block_files_download ? e.preventDefault() : "undefined"}
                      src={attached_file.signed_url}
                      key={`attached-file-${slugify(attached_file.original_name)}`}
                    />
                    <div style={{ height: 16 }} />
                  </>
                )
                )}

              {pdfFiles.length > 0 &&
                pdfFiles.map((pdfFile) => (
                  <>
                    <PdfViewer
                      fileUrl={pdfFile.signed_url}
                      fileName={pdfFile.original_name}
                      blockDownloadFiles={welcomeContentPost.block_files_download || false}
                      key={`attached-file-${slugify(pdfFile.original_name)}`}
                    />
                    <div style={{ height: 16 }} />
                  </>
                )
                )}

              <Text>
                {!jsonStr && !!textStr && textStr}

                {!!jsonStr && (
                  <RemirrorRenderer
                    json={jsonStr}
                    typeMap={typeMap}
                    markMap={markMap}
                  />
                )}

                {!jsonStr && !textStr && ""}
              </Text>

              <div style={{ height: 16 }} />
            </>
            {/* )} */}

            {isMpInactiveSubscription ? (
              <MPBlockedSection />
            ) : (
              <NoSubscribed />
            )}

            <div style={{ height: 36 }} />

            {/* <Reactions isSubscribed={isUserSubscribed || isOwner} style={{ position: 'relative', zIndex: 1 }} />

            <div style={{ height: 36 }} /> */}

            {(!!isUserSubscribed || !!isOwner) && (
              <>
                <ProgressBar />

                <div style={{ height: 36 }} />


                <div style={{ display: "flex", alignItems: 'center', flexDirection: 'column', gap: 23 }}>
                  <WelcomeContentTitle>¿Ya has visto este contenido?</WelcomeContentTitle>

                  <div style={{ display: "flex", alignItems: 'center', flexDirection: isMobile ? "column-reverse" : "row", gap: 20 }}>
                    {welcomeContentPost?.prev_content?.id && (
                      <PrevContentLink
                        target="_self"
                        href={`/group/${welcomeContentPost?.welcome_section?.group?.id}/wc/${welcomeContentPost?.prev_content?.id}`}
                      >
                        <Icon icon={ArrowBackIcon} color="black" />
                        <span> Contenido Anterior</span>
                      </PrevContentLink>
                    )}

                    {welcomeContentPost?.next_content?.id && (
                      <Loading isLoading={finishWelcomeContentPostStates.loading}>
                        <NextContentButton
                          onClick={goToNextWelcomContentPost}
                        >
                          <span>Próximo: {welcomeContentPost?.next_content?.title || ""}</span>
                          <Icon icon={ArrowForwardIcon} color="white" />
                        </NextContentButton>
                      </Loading>
                    )}

                    {!welcomeContentPost?.next_content && !welcomeContentPost?.welcome_section?.user_finished_welcome_section && (
                      <Loading isLoading={finishWelcomeContentSectionStates.loading}>
                        <NextContentButton
                          onClick={finishWelcomContent}
                        >
                          <span>Finalizar sección de bienvenida  🎉</span>
                        </NextContentButton>
                      </Loading>
                    )}

                    {isConfettiVisible && <Confetti height={height * 1.2} confettiSource={{ h: 0, x: 10, y: height, w: 10 }}
                      recycle={false}
                      numberOfPieces={400}
                      gravity={0.1}  // Hacer que el confetti suba (valor negativo)
                      initialVelocityY={20}
                      initialVelocityX={10}
                    />
                    }
                    <ModalCongrats />
                  </div>
                </div>
              </>
            )}

          </Grid.Col>
        </Grid.Row>
      </Grid.Container>


      <div style={{ height: "50px" }} />

      <ModalConfirmDelete />
    </Page >
  );
};

const state = ({ userStore, welcomeContentStore, communityStore }) => {
  const { isLoggedIn } = userStore;
  const { data: userInformation } = userStore.information;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { data: welcomeContentPost, states: welcomeContentPostStates } = welcomeContentStore.welcomeContentPost;
  const { states: finishWelcomeContentPostStates } = welcomeContentStore.finishWelcomeContentPost;
  const { states: finishWelcomeContentSectionStates } = welcomeContentStore.finishWelcomeContentSection;
  const { states: subscriptionStates } = communityStore.subscription;

  return {
    isLoggedIn,
    userInformation,
    userSubscriptions,
    welcomeContentPost,
    welcomeContentPostStates,
    subscriptionStates,
    finishWelcomeContentPostStates,
    finishWelcomeContentSectionStates
  };
};


export default connect(state)(Component);
