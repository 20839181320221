import { Thumbnail, ImageWrapper, PageNumber } from './styles';
import { pdfjs, Document, Page } from 'react-pdf';
import { useState } from 'react';

const Component = ({ totalPages, setCurrentPage, currentPage }) => {

  return (
    <>
      {Array.from({ length: totalPages }, (_, index) => (
        <Thumbnail className='thumbnail' >
          <>
            <ImageWrapper active={currentPage === index + 1} onClick={() => setCurrentPage(index + 1)}>
              <Page
                pageNumber={index + 1}
                onClick={() => {
                  setCurrentPage(index + 1);
                }}
                scale={1}  // Ajusta el zoom para las miniaturas
                width={120}   // Tamaño del thumbnail
                height={170}
                renderAnnotationLayer={false}
                renderTextLayer={false}
              />
            </ImageWrapper>

            <PageNumber active={currentPage === index + 1}>Página {index + 1}</PageNumber>
          </>
        </Thumbnail >
      ))}
    </>
  )
};

export default Component