import CoverPic from '../CoverPic';

import {
  Title, Column, GroupName, Line, Discount, Total, Currency, FooterMessage, Price
} from "./styles";
import dateHandlder from "../../../../../helpers/dates-hanlder";
import { connect, useDispatch } from "react-redux";
import { formatPrice } from '../../../../../helpers/price-formatter';
import { useEffect, useMemo, useState } from 'react';
import { getUrlParamByName } from '../../../../../helpers/get-url-param';
import { DiscountDto, PlanDto } from '../../../../../types';
import constants from '../../../../../constants';
import { getSubscription } from '../../../../../store/actions/subscription-admin';

const groupTypes = {
  community: "comunidad",
  free_experience: "taller",
  experience: "taller",
  membership: "membresía",
};


const periodsUnitsTranslations = {
  "month": { singular: "mes", plural: "meses" },
  "quarter": { singular: "trimestre", plural: "trimestres" },
  "half-year": { singular: "semestre", plural: "semestres" },
  "year": { singular: "año", plural: "años" },
}


const Component = ({ group, plan, subscription, subscriptionStates }) => {
  const [discount, setDiscount] = useState<DiscountDto>();
  const [secondaryCurrency, setSecondaryCurrency] = useState<string | null>('');
  const [suscriptionEnded, setSuscriptionEnded] = useState<boolean>();
  const dispatch = useDispatch();

  const paymentDiscountTranslated = (plan?: PlanDto) => {
    const discount = plan?.["discounts"][0];

    //We only have to show the discount text if there is a discount and this is not a reactivation
    if (discount && !subscription) {
      const periodSelected = discount["period"] / (plan?.["period"] || 1); //Cantidad de cobros
      return `${periodSelected > 1 ? `Los primeros ${periodSelected} pagos` : 'Pago'} con descuento.`
    } else {
      return ""
    }
  }

  const paymentPeriodTranslated = (plan?: PlanDto) => {
    if (plan?.["period_unit"] && plan?.["period"]) {
      const periodSelected = plan?.["period"]; //Cantidad de cobros
      const selectedPeriodUnit = periodsUnitsTranslations[plan?.["period_unit"]] //Meses / Trimestres / Años / Etc...
      return `Pagas ${plan?.["period"] <= 1 ? "por" : `cada ${plan?.["period"]}`} ${selectedPeriodUnit[periodSelected > 1 ? "plural" : "singular"]}.`
    } else {
      return ""
    }
  }


  const explainText = (plan?: PlanDto) => {
    const paymentCicles = plan?.['billing_cycles'] ? `${plan?.['billing_cycles']} cobros en total.` : "";
    const paymentPeriod = plan?.["type"] === "charge" ? "Pagá 1 sola vez." : paymentPeriodTranslated(plan);
    const paymentDiscount = paymentDiscountTranslated(plan);

    return `${paymentCicles} ${paymentPeriod} ${paymentDiscount}`;
  }

  const planPeriod = (plan?: PlanDto) => {
    if (plan) {
      if (plan.type === 'subscription') {
        const isPlural = plan?.period && plan?.period > 1;
        const period = plan?.period_unit && periodsUnitsTranslations[plan?.period_unit]
        return `Cada ${plan?.period} ${period[isPlural ? "plural" : "singular"]}`
      } else {
        return "Pago único"
      }
    }
  }

  const formatDatePlus10Days = (utcSeconds) => {
    const ts = Number(utcSeconds);
  
    if (!ts || isNaN(ts)) {
      console.warn("Timestamp is invalid:", utcSeconds);
      return "Invalid date";
    }
  
    const date = new Date(ts*1000);
    if (isNaN(date.getTime())) {
      console.warn("Date conversion failed for:", ts);
      return "Invalid date";
    }
  
    date.setUTCDate(date.getUTCDate() + 10);
  
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    // const year = date.getUTCFullYear();
  
    return `${day}/${month}`;
  };
  

  
  useEffect(() => {
    if (!!plan) {
      const selectedPlanDiscount = plan?.discounts?.length > 0 ? plan?.discounts[0] : null;
      const selectedPlanSecondaryCurrency = !!plan?.secondary_currency_rate ? constants.currencies.filter(c => c.iso === plan?.showable_secondary_currency)[0]?.iso : null
      setDiscount(selectedPlanDiscount)
      setSecondaryCurrency(selectedPlanSecondaryCurrency)
    }

    if (!!group?.registration_closing_date) {
      setSuscriptionEnded(
        dateHandlder.isExpired(group?.registration_closing_date)
      );
    }
  }, [group, plan]);

  return (
    <Column>
      <div style={{ width: '100%', maxWidth: 350 }}>
        <Title>Resumen del pedido</Title>
        <div style={{ height: 20 }} />
        <CoverPic />
        <div style={{ height: 20 }} />
        <GroupName>{group?.group_name}</GroupName>
        <div style={{ height: 20 }} />
        <Line />
        <div style={{ height: 12 }} />
        <Price>Precio {!!subscription && "actual del plan"} <span>$ {formatPrice(plan?.price)}</span></Price>
        <div style={{ height: 14 }} />

        {!!subscription && subscription?.current_amount !== plan?.price ? (
          <Discount>Descuento si reactivas antes del {formatDatePlus10Days(subscription?.cancelled_at)} <span>- ${formatPrice((plan?.price - subscription?.current_amount))}</span></Discount>
        ) : (
          <Discount>Descuento promocional <span>- ${formatPrice(plan?.discounts[0]?.amount || 0)}</span></Discount>
        )}
        
        <div style={{ height: 12 }} />
        <Line />
        <div style={{ height: 20 }} />
        {!subscription ? (
          <Total>Total <span>${!!discount
            ? formatPrice((plan?.price  - (plan?.discounts[0]?.amount || 0)))
            : formatPrice(plan?.price)}<small> /{planPeriod(plan)}</small></span>
          </Total>
        ) : (
          <Total>Total <span>${formatPrice(plan?.price - (plan?.price - subscription?.current_amount))} <small>/{planPeriod(plan)}</small></span></Total>
        )}
        <div style={{ height: 20 }} />
        <Currency>Monto total a pagar en {plan?.currency}.</Currency>
        <div style={{ height: 10 }} />
        <FooterMessage>{explainText(plan)} Los cobros se harán por débito automático a tu tarjeta y puedes cancelar en cualquier momento.</FooterMessage>
      </div>
    </Column>
  )
}

const state = ({ groupStore, planStore, subscriptionAdminStore }) => {
  const { data: group } = groupStore?.group;
  const { data: plan } = planStore?.onePlan;
  const { data: subscription, states: subscriptionStates } = subscriptionAdminStore.subscriptionAdmin;

  return { group, plan, subscription, subscriptionStates };
};

export default connect(state)(Component);