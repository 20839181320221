import { Step, CarouselContent, SlideContent, Text, Actions } from "./styles";
import Icon from "../../../../../../../components/Icon";
import GoToTelegram from "../../../../../components/GoToTelegram";
import TelegramIcon from "../../../../../../../assets/icons/telegram.svg";
import WhatsappIcon from "../../../../../../../assets/icons/whatsapp.svg";
import { connect } from "react-redux";
import { Group } from "../../../../../../../types";

interface ComponentProps {
  id: string;
  group: Group;
}

const typesLabels = {
  broadcast: "canal de novedades ",
  broadcast_with_comments: "canal de novedades",
  broadcast_with_comments_and_chat: "canal de novedades + chat de la comunidad ",
  group_chat: "chat de la comunidad",
};

const platformsSettings = {
  whatsapp: {
    icon: WhatsappIcon,
    name: "Whatsapp",
    color: "#24d366",
  },
  telegram: {
    icon: TelegramIcon,
    name: "Telegram",
    color: "#039be5",
  },
};

export const Component = ({ id, group }: ComponentProps) => {
  return (
    <CarouselContent type={platformsSettings[group?.telegram_groups[0]?.platform]?.name}>
      <Step
        color={platformsSettings[group?.telegram_groups[0]?.platform]?.color}
      >
        <Icon
          icon={platformsSettings[group?.telegram_groups[0]?.platform]?.icon}
          color="white"
          size="48px"
        />
      </Step>

      <SlideContent>
        <Text>
          {group?.recorded ? <>
            ¡Hey! 👋 comparte tu experiencia con otros miembros.{" "}
            <b>
              {`Unete al ${typesLabels[group?.telegram_groups[0]?.type]} en 
            ${platformsSettings[group?.telegram_groups[0]?.platform]?.name}`}
            </b>
          </> : <>
            ¡Hey! 👋 Comienza por aquí.{" "}
            <b>
              {`Unete al ${typesLabels[group?.telegram_groups[0]?.type]} en 
            ${platformsSettings[group?.telegram_groups[0]?.platform]?.name}`}
            </b>
          </>}
          .
        </Text>

        <Actions>
          <GoToTelegram
            label={`Ingresar a ${platformsSettings[group?.telegram_groups[0]?.platform]?.name
              }`}
            style={{
              backgroundColor:
                platformsSettings[group?.telegram_groups[0]?.platform]?.color,
              borderColor:
                platformsSettings[group?.telegram_groups[0]?.platform]?.color,
            }}
          />
        </Actions>
      </SlideContent>
    </CarouselContent>
  );
};

const state = ({ groupStore }) => {
  const { data: group } = groupStore.group;

  return {
    group,
  };
};

export default connect(state)(Component);
