import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { Title, Text, Link } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import actions from "../../../../../helpers/rest-client";
import {
  reactivateSubscription,
  resetReactivateSubscription,
} from "../../../../../store/actions/subscription-admin";
import { Subscription, User } from "../../../../../types";
import { useCallback, useEffect, useState } from "react";
import { getUserSubscriptions } from "../../../../../store/actions/user";
import { useWindowSize } from "../../../../../hooks/useWindowSize";

interface ComponentProps {
  subscription: Subscription | null;
  reactivateSubscriptionStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  userInformation: User;
}

const Component = ({
  subscription,
  reactivateSubscriptionStates,
  userInformation,
}: ComponentProps) => {
  const { isMobile } = useWindowSize();
  const [isGettingUrl, setIsGettingUrl] = useState<boolean>(false)
  const dispatch = useDispatch();

  const reactivateWithSameCC = useCallback(() => {
    if (subscription && subscription?.payment_processor === 'mercadopago') {
      dispatch(hideModal())

      const url = `/checkout/payment?planId=${subscription?.plan?.id}&groupSlug=${subscription?.group?.slug}&subscriptionId=${subscription?.id}`
      // Crear un enlace <a> oculto
      const link = document.createElement("a");
      link.href = url;
      link.target = "_self";
      link.rel = "noopener noreferrer"; // Seguridad adicional

      // Simular clic en el enlace
      document.body.appendChild(link);
      link.click();

      // Eliminar el enlace después de usarlo
      document.body.removeChild(link);
    } else {
      dispatch(reactivateSubscription({ id: subscription?.id }));
    }
  }, [subscription]);

  const changePaymentMethod = useCallback(() => {
    const idSubscription = subscription?.id
    setIsGettingUrl(true)

    actions.Get({
      url: `/api/subscription/${idSubscription}/chargebee-change-payment-method-url`
    }).then((response: any) => {
      if (response && response.hostedPageUrl) {
        // Crear un enlace <a> oculto
        const link = document.createElement("a");
        link.href = response.hostedPageUrl;
        link.target = "_blank";
        link.rel = "noopener noreferrer"; // Seguridad adicional

        // Simular clic en el enlace
        document.body.appendChild(link);
        link.click();

        // Eliminar el enlace después de usarlo
        document.body.removeChild(link);
      } else {
        console.error("The response did not contain a 'hostedPageUrl'.");
      }
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
      dispatch(resetReactivateSubscription())
      dispatch(hideModal())
      setIsGettingUrl(false)
    })
  }, [subscription])

  useEffect(() => {
    if (reactivateSubscriptionStates?.success) {
      dispatch(resetReactivateSubscription());
      dispatch(getUserSubscriptions());
    }
  }, [reactivateSubscriptionStates]);

  return (
    <Modal name="confirm-reactivate-cancelled-subscription-modal" width="650">
      <>
        <Title>Reactivar suscripción</Title>

        <div style={{ height: "15px" }}></div>

        {reactivateSubscriptionStates.success ? (
          <>
            <Text style={{ color: "green", fontWeight: "bold" }}>
              ✅ ¡Suscripción reactivada con éxito!
            </Text>
            <Button
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
              }}

              onClick={() => dispatch(hideModal())}
            >
              Cerrar
            </Button>
          </>
        ) : reactivateSubscriptionStates.error ? (
          <>
            <Text style={{ color: "red", fontWeight: "bold" }}>
              ⚠️ Error: Hubo un error al intentar reactivar tu subscripcion.
              Prueba cambiando el metodo de pago y luego volver a intentar.
            </Text>
            <Button
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
              }}
              loading={isGettingUrl}
              disabled={isGettingUrl}
              onClick={() => changePaymentMethod()}
            >
              Cambiar método de pago
            </Button>
          </>
        ) : (
          <>
            <Text>
              Membresía: <strong>{subscription?.group?.group_name}</strong>
            </Text>

            <Text>Estás por reactivar tu suscripción cancelada. Se te realizará un cobro para reactivar la suscripción</Text>

            <Button
              options={{
                type: "filled",
                skin: "purple",
                size: "lg",
              }}
              style={!isMobile ? { marginRight: "16px" } : { marginBottom: "10px" }}
              onClick={reactivateWithSameCC}
              disabled={reactivateSubscriptionStates.loading}
              loading={reactivateSubscriptionStates.loading}
            >
              Reactivar
            </Button>

            <Button
              options={{
                type: "outline",
                skin: "purple",
                size: "lg",
              }}
              onClick={() => dispatch(hideModal())}
              disabled={reactivateSubscriptionStates.loading}
            >
              Cancelar
            </Button>
          </>
        )}
      </>
    </Modal>
  );
};

const state = ({ userStore, subscriptionAdminStore }) => {
  const { data: userInformation } = userStore.information;
  const { states: reactivateSubscriptionStates } =
    subscriptionAdminStore.reactivateSubscription;

  return {
    reactivateSubscriptionStates,
    userInformation,
  };
};

export default connect(state)(Component);
