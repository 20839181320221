import Helmet from "react-helmet";
import { connect, useDispatch } from "react-redux";
import NoSubscribed from "../components/NoSubscribed";
import { Page } from "../components/styles";
import Grid from "../../../components/Grid";
import { useEffect, useMemo, useState } from "react";
import { getGroupBySlug, resetGetGroup } from "../../../store/actions/group";
import { resetGetPost } from "../../../store/actions/post";
import Loader from "../../../components/LogoLoader";
import { Group, Subscription, User } from "../../../types";
import GridContent from "./components/GridContent";
import CarouselContent from "./components/CarouselContent";
import { Layout, useLayout } from "../../../providers/LayoutProvider";
import { isSubscribed } from "../../../helpers/isSubscribed";
import { Redirect } from "react-router-dom";
import { isCongreso } from "../../../helpers/isCongreso";
import { isCommunity } from "../../../helpers/isCommunity";
import { isOwnGroup } from "../../../helpers/isOwnGroup";
import TelegramBanner from "../ActivitySummary/components/TelegramBanner";
import { getFreeSubscriptions } from "../../../helpers/getFreeSubscriptions";
import WelcomeSection from "../components/WelcomeSection";
import { GetWelcomeContentPostInDto } from "../../../types/welcome-section";
import { getUserPreferences } from "../../../store/actions/preferences";
import MPVerifyingPaymentSection from "../components/MPVerifyingPaymentSection";
import MPDisapprovedPaymentSection from "../components/MPNewSubscribersDisapprovedPaymentSection";
import { useWindowSize } from "../../../hooks/useWindowSize";
import MPOldSubscribersDisapprovedPaymentSection from "../components/MPOldSubscribersDisapprovedPaymentSection";
import MPVerifyYourPaymentInformationSection from "../components/MPVerifyYourPaymentInformationSection";
import { showPreference } from "../../../helpers/preferenceVisible";
import { getUserSubscriptions } from "../../../store/actions/user";

interface ComponentProps {
  isLoggedIn: boolean;
  welcomeContentSection: GetWelcomeContentPostInDto
  subscriptions: Subscription[];
  subscriptionsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  user: User;
  userStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  match;
  preferences: {
    id: number
    userId: string
    userEmail: string
    preferenceSlug: string
    preferenceValue: string
  }[]
  preferencesStates
}

const Component = ({
  isLoggedIn,
  subscriptions,
  subscriptionsStates,
  group,
  groupStates,
  match,
  user,
  userStates,
  welcomeContentSection,
  preferences,
  preferencesStates
}: ComponentProps) => {
  const dispatch = useDispatch();
  const { setLayout, layout } = useLayout();
  const { isMobile } = useWindowSize()
  const [goToSurvey, setGoTuSurvey] = useState<boolean>(false)

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(subscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [subscriptions, group, isLoggedIn])

  const showMinimized = useMemo(() => {
    if (!!preferences && !!group) {
      const slug = `minimize-welcome-section-${group?.welcome_section}`
      return preferences?.find(preference => preference.preferenceSlug === slug)
    }
  }, [preferences, group])

  const redirect = useMemo(() => {
    if (!!group) {
      return groupStates?.error || isCongreso(group?.id) || isCommunity(group?.group_type) || (!isOwnGroup({ group, expertId: user?.expert?.id }) && group?.status === 'draft')
    }

    return false
  }, [group, user])


  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      header: {
        visible: true,
        beforeHeader: <><MPVerifyingPaymentSection /><MPVerifyYourPaymentInformationSection /><MPDisapprovedPaymentSection /><MPOldSubscribersDisapprovedPaymentSection /></>
      },
      toolbar: {
        visible: isOwnGroup({ group, expertId: user?.expert?.id })
      },
      body: {
        visible: true,
      },
    }),
    [layout, subscriptions, group, user]
  );

  useEffect(() => {
    if (
      !!match?.params && !groupStates?.loading &&
      (!group?.slug || (!!group?.slug && group?.slug !== match?.params?.group))
    ) {
      dispatch(resetGetPost());
      match?.params && dispatch(getGroupBySlug(match?.params?.group));
    }
  }, [match]);

  useEffect(() => {
    setLayout(layoutData);
  }, [subscriptions, group, user]);

  useEffect(() => {
    dispatch(getUserPreferences())
    dispatch(getUserSubscriptions());

    return () => {
      dispatch(resetGetGroup())
    }
  }, [])

  useEffect(() => {
    if (group?.survey) {
      setGoTuSurvey(
        showPreference({
          preferences,
          preferencesStates,
          id: `survey-${group?.survey}`,
        })
      )
    }
  }, [preferences, preferencesStates, group])

  if (
    preferencesStates.loading ||
    groupStates.loading ||
    subscriptionsStates.loading ||
    userStates.loading
  ) {
    return <Loader />;
  }

  if (goToSurvey && !isOwnGroup({ group, expertId: user?.expert?.id }) && isSubscribed(subscriptions, group?.id)) {
    return <Redirect to={`/group/${group.slug}/survey`} />;
  }

  if (redirect) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <Page>
        <Helmet>
          ‍<title></title>‍
          <meta name="description" content="" />
        </Helmet>

        <Grid.Container>
          <Grid.Row>
            <Grid.Col lg={10} offset={{ lg: 1 }}>
              {(group?.group_type === "free_experience" && (isUserSubscribed ||
                isOwnGroup({
                  group,
                  expertId: user?.expert?.id,
                }))) && (
                  <TelegramBanner />
                )}
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>

        {!showMinimized && (
          <Grid.Container>
            {isMobile && (<div style={{ height: 36 }} />)}
            <Grid.Row>
              <Grid.Col lg={10} offset={{ lg: 1 }}>
                <WelcomeSection minimized={true} showCloseButton={true} />
              </Grid.Col>
            </Grid.Row>
          </Grid.Container>
        )}

        <div style={{ height: 36 }} />

        {!!group && group?.library_template_view === 'all_posts' && <GridContent />}
        {!!group && group?.library_template_view === 'by_category' && <CarouselContent />}

      </Page>

      <NoSubscribed />
    </>
  );
};

const state = ({ userStore, postStore, groupStore, preferencesStore, welcomeContentStore }) => {
  const { isLoggedIn } = userStore;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;
  const { data: user, states: userStates } =
    userStore.information;
  const { data: posts, states: postsStates } = postStore.listPosts;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: welcomeContentSection } = welcomeContentStore.welcomeContentSection;
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;

  return {
    subscriptions,
    subscriptionsStates,
    user,
    userStates,
    posts,
    postsStates,
    group,
    groupStates,
    isLoggedIn,
    welcomeContentSection,
    preferences,
    preferencesStates
  };
};

export default connect(state)(Component);
