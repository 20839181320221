import Grid from "./../../components/Grid";
import {
  NotFound,
  Img,
  Section,
  Title,
  SubTitle,
  Text
} from "./styles";
import Helmet from "react-helmet";
import Woman from "./../../assets/woman-error.svg";

const Component = () => (
  <>
    <Helmet>
      ‍<title>Mantenimiento - Estamos trabajando</title>‍
      <meta name="description" content="404" />
    </Helmet>

    <NotFound>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col offset={{ sm: 1, md: 1 }} sm={10} md={4}>
            <Img src={Woman} alt="Error" width="175px" height="156px" />
          </Grid.Col>
          <Grid.Col offset={{ sm: 1, md: 1 }} sm={10} md={5}>
            <Section>
              <Title>🛠️ En breve estamos devuelta </Title>
              <SubTitle>
              Estamos trabajando para mejorar tu experiencia y en unos minutos podrás acceder nuevamente! Desde ya, gracias por tu paciencia
              </SubTitle>
              <Text>
              No puedes acceder ahora mismo ya que estamos haciendo tareas de mantenimiento. Te invitamos a reintentar en unos minutos  
              </Text>
            </Section>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </NotFound>
  </>
);

export default Component;
