import { put, call, takeLatest, all } from "redux-saga/effects";
import services from "../../services";
import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  SIGN_UP_ERROR,
  LOCAL_SIGN_UP_SUCCESS,
  LOCAL_SIGN_UP_ERROR,
  SIGN_OUT,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_ERROR,
  PASSWORD_RECOVERY,
  PASSWORD_RECOVERY_SUCCESS,
  PASSWORD_RECOVERY_ERROR,
  RESET_PASSWORD_RECOVERY,
  SEND_PASSWORD_RECOVERY_EMAIL,
  SEND_PASSWORD_RECOVERY_EMAIL_SUCCESS,
  SEND_PASSWORD_RECOVERY_EMAIL_ERROR,
  RESET_SEND_PASSWORD_RECOVERY_EMAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  SignIn,
  SignUp,
} from "../types/auth";

function* signIn({ payload }: SignIn): any {
  try {
    const { response, error } = yield call(services.auth.signIn, payload);

    if (!!error) {
      yield put({ type: SIGN_IN_ERROR, payload: error.code });
    } else {
      yield put({ type: SIGN_IN_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({ type: SIGN_IN_ERROR, payload: 'Error al iniciar sesión' });
  }
}

function* signUp({ payload }: SignUp): any {
  const { error } = yield call(services.auth.signUpWithCredentials, payload);

  if (!!error) {
    yield put({ type: SIGN_UP_ERROR, payload: error.code });
  } else {
    yield put({
      type: SIGN_UP_SUCCESS,
      payload: payload,
    });
  }
}

function* localSignUp({ payload }) {
  try {
    const { response, error } = yield call(
      services.auth.signUpLocalRegistration,
      payload
    );

    if (!!error) {
      yield put({ type: SIGN_UP_ERROR, payload: error.code });
    } else {
      yield all([
        put({ type: SIGN_UP_SUCCESS, payload: response }),
        put({ type: SIGN_IN, payload: response })
      ]);
    }
  } catch (error) {
    yield put({ type: SIGN_UP_ERROR, payload: 'Error al registrar usuario' });
  }
}

function* signOut(): any {
  try {
    const result = yield call(services.auth.signOut);
    yield put({ type: SIGN_OUT_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: SIGN_OUT_ERROR, payload: error });
  }
}

function* passwordRecovery({ payload }): any {
  try {
    const result = yield call(services.auth.passwordRecovery, payload);
    yield put({ type: PASSWORD_RECOVERY_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: PASSWORD_RECOVERY_ERROR, payload: error });
  }
}


function* sendPasswordRecoveryEmail({ payload }): any {
  try {
    const { response, error } = yield call(services.auth.sendPasswordRecoveryEmail, payload);
    if (!!error) {
      yield put({ type: SEND_PASSWORD_RECOVERY_EMAIL_ERROR, payload: error });
    } else {
      yield put({ type: SEND_PASSWORD_RECOVERY_EMAIL_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({ type: SEND_PASSWORD_RECOVERY_EMAIL_ERROR, payload: 'Error al enviar email de recuperación de contraseña.' });
  }
}


function* changePassword({ payload }): any {
  try {
    const { response, error } = yield call(services.auth.changePassword, payload);
    if (!!error) {
      yield put({ type: CHANGE_PASSWORD_ERROR, payload: error });
    } else {
      yield put({ type: CHANGE_PASSWORD_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_ERROR, payload: 'Error al enviar email de recuperación de contraseña.' });
  }
}

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(SIGN_UP, localSignUp);
  yield takeLatest<any>(SIGN_IN, signIn);
  yield takeLatest<any>(SIGN_OUT, signOut);
  yield takeLatest<any>(PASSWORD_RECOVERY, passwordRecovery);
  yield takeLatest<any>(SEND_PASSWORD_RECOVERY_EMAIL, sendPasswordRecoveryEmail);
  yield takeLatest<any>(CHANGE_PASSWORD, changePassword);
}
