import { SignInData, SignUpData, ChangePasswordOutDto, SignOutData, SendPasswordRecoveryEmail } from "../../types";
import { SignIn, SignOut, SignUp } from "../types/auth";
import { SIGN_IN, SIGN_UP, SIGN_OUT } from "../types/auth";
import {
  PASSWORD_RECOVERY,
  RESET_PASSWORD_RECOVERY,
  SEND_PASSWORD_RECOVERY_EMAIL,
  RESET_SEND_PASSWORD_RECOVERY_EMAIL,

  ResetSendPasswordRecoveryEmail,
  CHANGE_PASSWORD,
  ChangePassword,
  RESET_CHANGE_PASSWORD,
  ResetChangePassword,

  PasswordRecovery,
  ResetPasswordRecovery,
} from "../types/auth";

export const signIn = (value: SignInData): SignIn => {
  return { type: SIGN_IN, payload: value };
};

export const signUp = (value: SignUpData): SignUp => {
  return { type: SIGN_UP, payload: value };
};

export const signOut = (value: SignOutData = {}): SignOut => {
  return { type: SIGN_OUT, payload: value };
};

export const passwordRecovery = (email: string): PasswordRecovery => {
  return { type: PASSWORD_RECOVERY, payload: email };
};

export const resetPasswordRecovery = (): ResetPasswordRecovery => {
  return { type: RESET_PASSWORD_RECOVERY, payload: null };
};


export const sendPasswordRecoveryEmail = (payload: SendPasswordRecoveryEmail) => {
  return { type: SEND_PASSWORD_RECOVERY_EMAIL, payload };
};

export const resetSendPasswordRecoveryEmail = () => {
  return { type: RESET_SEND_PASSWORD_RECOVERY_EMAIL, payload: null };
};

export const changePassword = (payload: ChangePasswordOutDto) => {
  return { type: CHANGE_PASSWORD, payload };
};

export const resetChangePassword = () => {
  return { type: RESET_CHANGE_PASSWORD, payload: null };
};
