import { Formik } from "formik";
import { initialValues, schema } from "../../../constants/forms/notifications";
import Snackbar from "../../../components/Snackbar";
import AutoSave from "../../../components/FormikForm/AutoSave";
import Submit from "../../../components/FormikForm/Submit";
import Grid from "../../../components/Grid";
import Checkbox from "../../../components/FormikForm/Checkbox";
import Collapse from "./components/Collpase";
import { useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useWindowSize } from "../../../hooks/useWindowSize";
import {
  getAllNotifications,
  newNotification,
  resetUpdateNotification,
  updateNotification,
} from "../../../store/actions/notification";
import WelcomeNotificationPage from "./components/WelcomeNotificationPage";
import { NotificationInDto } from "../../../types/notification";
import { Layout, useLayout } from "../../../providers/LayoutProvider";
import RecordSetting from "../../../components/RecordSetting";
import { showModal } from "../../../store/actions/modal";

interface ComponentProps {
  setActiveTab?;
  notifications: NotificationInDto[];
  updateNotificationsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
}

const Component = ({
  setActiveTab,
  notifications,
  updateNotificationsStates,
}: ComponentProps) => {
  const { layout, setLayout } = useLayout();
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);

  const onSubmit = ({ values, actions }) => {
    !!notifications &&
      dispatch(
        updateNotification(
          notifications?.map((notification) => ({
            ...notification,
            enabled: values[notification?.type]?.length > 0,
          }))
        )
      );
  };

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      header: {
        visible: true,
        actions: [
          <Submit
            form="edit-notifications"
            isSubmmiting={updateNotificationsStates.loading}
            options={{
              size: "lg",
              skin: isMobile ? "white" : "purple",
              type: "filled",
              block: isMobile,
            }}
          >
            Guardar cambios
          </Submit>,
        ],
      },
      page: {
        title: "Configuración de cuenta",
        description: "Configuración de cuenta",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      body: {
        visible: true,
      },
    }),
    [layout, updateNotificationsStates, isMobile]
  );

  const defaultValues = useMemo(() => {
    let obj = {};

    for (const key in notifications) {
      Object.assign(obj, {
        [notifications[key].type]: notifications[key].enabled ? ["true"] : [],
      });
    }

    return obj;
  }, [notifications]);

  useEffect(() => {
    setActiveTab("notifications");
    setLayout(layoutData);
    !notifications && dispatch(getAllNotifications());
  }, []);

  useEffect(() => {
    setLayout(layoutData);
  }, [updateNotificationsStates, isMobile]);

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <Formik
            initialValues={{
              ...initialValues,
              ...defaultValues,
            }}
            onSubmit={(values, actions) => {
              if (onSubmit) onSubmit({ values, actions });
            }}
            validateOnChange={false}
            validateOnBlur={formSubmmited}
            validationSchema={schema}
            enableReinitialize
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {

              return (
                <form
                  style={{ width: "100%", maxWidth: "690px" }}
                  className="theme-form"
                  onSubmit={(event) => {
                    setFormSubmmited(true);
                    handleSubmit(event);
                  }}
                  id="edit-notifications"
                >
                  <Collapse toggleLabel="Membresía" toggleIcon="">
                    <Checkbox
                      name="membership_new_subscription"
                      error={errors["membership_new_subscription"]}
                      touched={touched["membership_new_subscription"]}
                      value={values["membership_new_subscription"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      items={[
                        {
                          label: "Nuevas suscripciones",
                          value: "true",
                          id: "nuevas-suscripciones-membresias",
                        },
                      ]}
                      options={{
                        marginBottom: 32,
                        helperText:
                          "Notificación por Telegram o Whatsapp sobre una nueva persona dada de alta en la membresía (de forma automática e instantáneamente después de suscrita la persona).",
                      }}
                    />

                    <Checkbox
                      name="membership_subscriptions_cancellation"
                      error={errors["membership_subscriptions_cancellation"]}
                      touched={touched["membership_subscriptions_cancellation"]}
                      value={values["membership_subscriptions_cancellation"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      items={[
                        {
                          label: "Baja de membresía",
                          value: "true",
                          id: "membership-subscriptions-cancellation",
                        },
                      ]}
                      options={{
                        marginBottom: 32,
                        helperText:
                          "Notificación por Telegram o Whatsapp sobre una nueva persona dada de baja en la membresía (de forma automática e instantáneamente después de suscrita la persona).",
                      }}
                    />

                    <Checkbox
                      name="membership_reactivation_subscription"
                      error={errors["membership_reactivation_subscription"]}
                      touched={touched["membership_reactivation_subscription"]}
                      value={values["membership_reactivation_subscription"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      items={[
                        {
                          label: "Reactivación de membresía",
                          value: "true",
                          id: "membership-reactivation-subscription",
                        },
                      ]}
                      options={{
                        marginBottom: 0,
                        helperText:
                          "Notificación por Telegram o Whatsapp sobre una reactivación de la membresía (de forma automática e instantáneamente después de suscrita la persona).",
                      }}
                    />
                  </Collapse>

                  <div style={{ height: "16px" }} />

                  <Collapse
                    toggleLabel="Talleres y eventos gratuitos"
                    toggleIcon=""
                  >
                    <Checkbox
                      name="experience_free_subscription"
                      error={errors["experience_free_subscription"]}
                      touched={touched["experience_free_subscription"]}
                      value={values["experience_free_subscription"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      items={[
                        {
                          label: "Nuevas suscripciones",
                          value: "true",
                          id: "nuevas-suscripciones-talleres",
                        },
                      ]}
                      options={{
                        marginBottom: 0,
                        helperText:
                          "Notificación por Telegram sobre una nueva persona dada de alta en la membresía (de forma automática e instantáneamente después de suscrita la persona).",
                      }}
                    />
                  </Collapse>

                  <div style={{ height: "16px" }} />

                  <Collapse toggleLabel="Artículos" toggleIcon="">
                    <Checkbox
                      name="new_post"
                      error={errors["new_post"]}
                      touched={touched["new_post"]}
                      value={values["new_post"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      items={[
                        {
                          label: "Nuevo artículo",
                          value: "true",
                        },
                      ]}
                      options={{
                        marginBottom: 0,
                        helperText:
                          "Notificación por Telegram o Whatsapp y/o email sobre un nuevo artículo publicado en la comunidad, taller/es o membresía (de forma automática e instantáneamente después de crearlo).",
                      }}
                    />
                  </Collapse>

                  <div style={{ height: "16px" }} />

                  <Collapse toggleLabel="Encuentros" toggleIcon="">
                    <Checkbox
                      name="new_session"
                      error={errors["new_session"]}
                      touched={touched["new_session"]}
                      value={values["new_session"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      items={[
                        {
                          label: "Creacion de un nuevo encuentro",
                          value: "true",
                        },
                      ]}
                      options={{
                        marginBottom: 32,
                        helperText:
                          "Notificación por Telegram o Whatsapp y/o email sobre un nuevo encuentro (de forma automática e instantáneamente después de crearla).",
                      }}
                    />
                    <Checkbox
                      name="update_session"
                      error={errors["update_session"]}
                      touched={touched["update_session"]}
                      value={values["update_session"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      items={[
                        {
                          label: "Update de un encuentro existente",
                          value: "true",
                        },
                      ]}
                      options={{
                        marginBottom: 32,
                        helperText:
                          "Notificacion automatica por Telegram o Whatsapp y/o email sobre una actualización de fecha y/o hora de una session.",
                      }}
                    />
                    <Checkbox
                      name="session_cancelled"
                      error={errors["session_cancelled"]}
                      touched={touched["session_cancelled"]}
                      value={values["session_cancelled"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      items={[
                        {
                          label: "Borrado de un encuentro existente",
                          value: "true",
                        },
                      ]}
                      options={{
                        marginBottom: 32,
                        helperText:
                          "Notificación automática por Telegram o Whatsapp y/o email sobre la eliminacion de un evento.",
                      }}
                    />
                    <Checkbox
                      name="session_reminder"
                      error={errors["session_reminder"]}
                      touched={touched["session_reminder"]}
                      value={values["session_reminder"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      items={[
                        {
                          label:
                            "Recordatorio una hora antes del inicio del encuentro",
                          value: "true",
                        },
                      ]}
                      options={{
                        marginBottom: 32,
                        helperText:
                          "Notificación por el canal de Telegram o Whatsapp y/o email una hora antes de que inicie el encuentro, a modo recordatorio.",
                      }}
                    />
                    <Checkbox
                      name="live_session"
                      error={errors["live_session"]}
                      touched={touched["live_session"]}
                      value={values["live_session"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      items={[
                        {
                          label: "On Live",
                          value: "true",
                        },
                      ]}
                      options={{
                        marginBottom: 32,
                        helperText:
                          "Notificación por el canal de Telegram o Whatsapp y/o email al momento de inicio del encuentro para que los suscriptores del evento se unan.",
                      }}
                    />
                    <Checkbox
                      name="uploaded_recording"
                      error={errors["uploaded_recording"]}
                      touched={touched["uploaded_recording"]}
                      value={values["uploaded_recording"]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      items={[
                        {
                          label: "Grabación subida",
                          value: "true",
                        },
                      ]}
                      options={{
                        marginBottom: 0,
                        helperText:
                          "Notificación por el canal de Telegram o Whatsapp y/o email al momento de subir la grabación de un encuentro.",
                      }}
                    />
                  </Collapse>

                  <div style={{ height: "30px" }} />

                  {!isMobile && (
                    <Submit
                      form="edit-notifications"
                      isSubmmiting={updateNotificationsStates.loading}
                    >
                      Guardar cambios
                    </Submit>
                  )}
                </form>
              );
            }}
          </Formik>
        </Grid.Col>
      </Grid.Row>

      <WelcomeNotificationPage />

      <Snackbar
        visible={
          updateNotificationsStates.success || !!updateNotificationsStates.error
        }
        options={{
          time: 1500,
          type: updateNotificationsStates.success ? "success" : "error",
        }}
        onHide={() => dispatch(resetUpdateNotification())}
      >
        {updateNotificationsStates.success
          ? "Notificaciones actualizadas."
          : "Error al actualizar las notificaciones."}
      </Snackbar>
    </Grid.Container>
  );
};

const states = ({ notificationStore }) => {
  const { data: notifications } = notificationStore.allNotifications;
  const { states: updateNotificationsStates } =
    notificationStore.updateNotification;
  return {
    notifications,
    updateNotificationsStates,
  };
};

export default connect(states)(Component);
