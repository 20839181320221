import PixelFacebook from "../components/PixelFacebook";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";

import { AuthProvider } from "../providers/Auth";
import LayoutProvider from "../providers/LayoutProvider";
import SubscriptionProvider from "../providers/Subscription";
import Themes from "../components/Theme";

import RainbowLayout from "../components/Layout/RainbowLayout";
import PrivateRoute from "./../components/PrivateRoute";
import ScrollToTop from "../helpers/ScrollToTop";

import Home from "./Home";
import Subscriber from "./Subscriber";
import Expert from "./Expert";
import Group from "./Group";
import GroupsList from "./Group/List";
import Profile from "./Profile";
import NotFound from "./NotFound";
import Checkout from "./Checkout";
import Dashboard from "./Dashboard";
import Terms from "./Terms";
import Auth from './Auth';


import Congreso from "./Congreso";
import { useEffect } from "react";
import forward from "../helpers/fowardUrl";
import { FileUploadProgressProvider } from "../providers/FileUploader";
import Manteinance from "./Manteinance";

const Component = () => {
  return (
    <AuthProvider>
      <SubscriptionProvider>
        <FileUploadProgressProvider>
          <Router>
            <LayoutProvider useLocation={useLocation}>
              <PixelFacebook />
              <ScrollToTop />
              <Switch>
                <Route
                  exact
                  path="/"
                  component={() => (
                    <Themes.Klouser>
                      <RainbowLayout useLocation={useLocation}>
                        <Home />
                      </RainbowLayout>
                    </Themes.Klouser>
                  )}
                />

                <Route
                  path="/congreso"
                  component={() => (
                    <Themes.Klouser>
                      <Congreso />
                    </Themes.Klouser>
                  )}
                />
                <Route
                  path="/subscriber"
                  component={() => (
                    <Themes.Klouser><Subscriber /></Themes.Klouser>
                  )}
                />

                <Route
                  path="/expert"
                  component={() => (
                    <Themes.Klouser><Expert /></Themes.Klouser>
                  )}
                />

                <Route
                  path="/terms"
                  component={() => (
                    <Themes.Klouser><Terms /></Themes.Klouser>
                  )}
                />

                <PrivateRoute
                  path="/profile"
                  component={() => (
                    <Themes.Klouser><Profile /></Themes.Klouser>
                  )}
                />

                <Route
                  path="/checkout"
                  component={() => (
                    <Themes.Klouser>
                      <Checkout />
                    </Themes.Klouser>
                  )}
                />

                <PrivateRoute
                  path="/dashboard"
                  component={() => (
                    <Themes.Klouser>
                      <Dashboard />
                    </Themes.Klouser>
                  )}
                />

                <Route
                  path="/404"
                  component={() => (
                    <Themes.Klouser>
                      <RainbowLayout useLocation={useLocation}>
                        <NotFound />
                      </RainbowLayout>
                    </Themes.Klouser>
                  )}
                />

                <Route
                  path="/manteinance"
                  component={() => (
                    <Themes.Klouser>
                      <RainbowLayout useLocation={useLocation}>
                        <Manteinance />
                      </RainbowLayout>
                    </Themes.Klouser>
                  )}
                />

                <Route
                  path="/500"
                  component={() => (
                    <Themes.Klouser>
                      <RainbowLayout useLocation={useLocation}>
                        <NotFound />
                      </RainbowLayout>
                    </Themes.Klouser>
                  )}
                />

                <Route
                  path="/503"
                  component={() => (
                    <Themes.Klouser>
                      <RainbowLayout useLocation={useLocation}>
                        <NotFound />
                      </RainbowLayout>
                    </Themes.Klouser>
                  )}
                />

                <Route
                  path={"/group"}
                  component={(props) => (
                    <Themes.CustomTheme>
                      <Group {...props} />
                    </Themes.CustomTheme>
                  )}
                />

                <Route
                  path={"/auth"}
                  component={(props) => (
                    <Themes.CustomTheme>
                      <Auth {...props} />
                    </Themes.CustomTheme>
                  )}
                />

                <Route
                  exact
                  path={"/:username"}
                  component={(props) => (
                    <Themes.CustomTheme>
                      <RainbowLayout useLocation={useLocation}>
                        <GroupsList {...props} />
                      </RainbowLayout>
                    </Themes.CustomTheme>
                  )}
                />

                <Route
                  exact
                  path="*"
                  component={() => (
                    <Themes.Klouser>
                      <NotFound />
                    </Themes.Klouser>
                  )}
                />
              </Switch>
            </LayoutProvider>
          </Router>
        </FileUploadProgressProvider>
      </SubscriptionProvider>
    </AuthProvider>
  );
};

export default Component;
