import styled from "styled-components";

const ScrollButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  color: #000000;
  border: none;
  padding: 0px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: -1;
  opacity: 0;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.1);
  transition: all .1s ease-in-out;
  display: none;

  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  &:before {
    content: '';
    diplay: none;
    position: absolute;
    top: 0px;
    width: 100px;
    background: transparent;
    z-index: 1;
    height: 100%;

    @media(min-width: 1024px){
      display: block;
    }
  }

  @media(min-width: 1024px){
    display: flex;
  }
`;


export const LeftButton = styled(ScrollButton)`
  left: -15px;

  &:before {
    left: 0px
  }
`;

export const RightButton = styled(ScrollButton)`
  right: -15px;

  &:before {
    right: 0px;
  }
`;

export const HorizontalScrollWrapper = styled('div')`
  margin: 0px 0px 0px -10px;
  position: relative;

  &:hover {
    ${ScrollButton} {
      opacity: 1;
      z-index: 12;

      @media(min-width: 1200px){
        z-index: 10;
      }
    }

    ${LeftButton} {
      left: 0px;
      
      @media(min-width: 1200px){
        left: -50px;
      }
    }

    ${RightButton}{
      right: 0px;
      
      @media(min-width: 1200px){
        right: -50px;
      }
    }
  }

  @media(min-width: 768px){
    margin: 0px calc(-1 * ((100vw * 8.33333%) + 10px));
  }
`

export const HorizontalScroll = styled('div') <{ cardWidth?}>`
  overflow-x: scroll;
  display: flex;
  padding: 0px 0px 10px;
  scroll-snap-type: x mandatory;
  scroll-padding: 0px 10px;
  scroll-behavior: smooth;
  white-space: nowrap;
  position: relative;
  z-index: 11;

  ::-webkit-scrollbar {
    display: none;
  }

  & > * {
    scroll-snap-align: start;
    ${({ cardWidth }) => cardWidth ? `max-width: ${cardWidth};` : ''}

    &:not(:last-child){
      margin-right: 16px;
    }
  }
`


