import { Column, Title, Message, ProfileImage } from "./styles";
import { connect } from "react-redux";
import Button from '../../../../components/Button';
import userAvatar from "../../../../assets/avatar.svg";
import { ReactElement, useMemo } from 'react';
import Image from '../../../../components/Image'
import mercadoPagoImg from '../../../../assets/mercado-pago.png'
import { useTheme } from "styled-components";
import constants from "../../../../constants";
import Link from "../../../../components/Link";
import { Group, User } from "../../../../types";

interface ComponentProps {
  group: Group
  user: User
  text?: ReactElement
  title?: ReactElement
  retryCtaText?: string
}

const Component = ({ group, user, text, title, retryCtaText }: ComponentProps) => {
  const theme = useTheme() as { logo: { small: string, big: string } };

  const profilePicture = useMemo(() => {
    return group?.expert?.profile_pic?.url || userAvatar
  }, [group])

  return (
    <Column>
      <div style={{ gap: 24, display: 'flex', justifyContent: 'center', width: '100%', maxWidth: 410 }}>
        <Image
          src={theme?.logo?.big}
          style={{ display: 'block' }}
          width="107px"
          height="auto"
          className="logo-desktop"
          alt="Klouser"
          lazy
        />

        <div style={{ height: 30, width: 1, backgroundColor: '#B1C6E4' }} />

        <Image
          src={mercadoPagoImg}
          style={{ display: 'block' }}
          width="101px"
          height="auto"
          className="logo-desktop"
          alt="Mercado pago"
          lazy
        />
      </div>

      <div style={{ height: 52 }} />

      <ProfileImage loading="lazy" src={profilePicture} alt={"Profile Image"} />

      <div style={{ height: 42 }} />

      <Title>{title ? title : "Hubo un inconveniente​"}</Title>

      <div style={{ height: 42 }} />

      <Message>{text ? text : <>Hola {user?.name}, tuvimos un problema al<br />procesar el pago de tu suscripción a<br />{group?.group_name}<br />porque algunos datos de tu tarjeta parecen ser incorrectos.</>}</Message>

      <div style={{ height: 42 }} />

      <Button options={{ type: 'filled', size: 'lg', skin: 'purple', block: true }} onClick={() => window.location.reload()}>{retryCtaText ? retryCtaText : "Reintentar el pago"}</Button>
      <div style={{ height: 15 }} />
      <Link
        options={{ type: 'outline', size: 'lg', skin: 'purple', block: true, native: false }}
        href={`${constants.whatsapp.url}?phone=${constants.whatsapp.number
          }&text=${constants.whatsapp.checkout_failure.replace("{name}", user?.name).replace("{email}", user?.email)}`}
        target="_blank"
      >
        Contactarme con soporte
      </Link>
    </Column>
  )
}

const state = ({ groupStore, userStore }) => {
  const { data: group } = groupStore?.group;
  const { data: user } = userStore.information;
  return { group, user };
};

export default connect(state)(Component);