import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import { Title, Text } from "./styles";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import {
    reactivateSubscription,
    resetReactivateSubscription,
} from "../../../../../store/actions/subscription-admin";
import { Subscription, User } from "../../../../../types";
import { useEffect } from "react";
import { getUserSubscriptions } from "../../../../../store/actions/user";
import dateHelpers from "../../../../../helpers/dates-hanlder";
import { useWindowSize } from "../../../../../hooks/useWindowSize";

interface ComponentProps {
    subscription: Subscription | null;
    reactivateSubscriptionStates: {
        loading: boolean;
        success: boolean;
        error: string | boolean;
    };
    userInformation: User;
}

const Component = ({
    subscription,
    reactivateSubscriptionStates,
    userInformation,
}: ComponentProps) => {
    const { isMobile } = useWindowSize();
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(reactivateSubscription({ id: subscription?.id }));
    };

    useEffect(() => {
        if (reactivateSubscriptionStates?.success) {
            dispatch(resetReactivateSubscription());
            dispatch(getUserSubscriptions());
            dispatch(hideModal());
        }
    }, [reactivateSubscriptionStates]);

    return (
        <Modal
            name="confirm-reactivate-non_renewing-subscription-modal"
            width="650"
        >
            {/* {reactivateSubscriptionStates.success ? (
                <>
                    <div style={{ textAlign: "center" }}>
                        <Image src={remove} width="102px" height="117px" alt={remove} />
                    </div>
                    <Text>
                        <strong style={{ textAlign: "center", display: "block" }}>
                            ¡Tu suscripción fue reactivada con éxito! <br /><br />
                            {!!subscription?.current_term_end && dateHelpers.isFutureDate(dateHelpers.timestampToDate(subscription?.current_term_end)) &&
                                `Puedes disfrutar de tu acceso premium hasta el ${dateHelpers.timestampToDate(subscription?.current_term_end, "DD/MM/AAAA")}`
                            }
                        </strong>
                    </Text>
                </>
            ) : ( */}
            <>
                <Title>Reactivar suscripción </Title>

                <div style={{ height: "15px" }}></div>

                <Text>
                    Membresia: <strong>{subscription?.group?.group_name}</strong>
                </Text>

                <Text>
                    Estás por reactivar tu suscripción. No se realizará ningún cobro por
                    esta acción y tu próximo pago será el{" "}
                    {dateHelpers.inputDate(subscription?.next_billing_at, "DD/MM/YYYY")}
                </Text>

                <Button
                    options={{
                        type: "filled",
                        skin: "purple",
                        size: "lg",
                    }}
                    style={!isMobile ? { marginRight: "16px" } : {marginBottom: "10px"}}
                    onClick={handleClick}
                    disabled={reactivateSubscriptionStates.loading}
                    loading={reactivateSubscriptionStates.loading}
                >
                    Reactivar subscripción
                </Button>

                <Button
                    options={{
                        type: "outline",
                        skin: "purple",
                        size: "lg",
                    }}
                    style={!isMobile ? { marginRight: "16px" } : {}}
                    onClick={() => dispatch(hideModal())}
                    disabled={reactivateSubscriptionStates.loading}
                >
                    Cancelar
                </Button>
            </>
            {/* )} */}
        </Modal>
    );
};

const state = ({ userStore, subscriptionAdminStore }) => {
    const { data: userInformation } = userStore.information;
    const { states: reactivateSubscriptionStates } =
        subscriptionAdminStore.reactivateSubscription;

    return {
        reactivateSubscriptionStates,
        userInformation,
    };
};

export default connect(state)(Component);
