import styled, { keyframes } from 'styled-components';
import UiButton from '../Button';

export const MainWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  
  @media(min-width: 768px){
    gap: 50px;
  }

  &.fullscreen-mode {
    padding: 15px 0;
  }
`

export const Toolbar = styled('div')`
  border-radius: 20px;
  padding: 10px 20px;
  height: 60px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12), 0px 12px 40px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  gap: 5px;
  
  &:not(.fullscreen-mode){
    position: sticky;
    bottom: 75px;
    z-index: 100;
    // left: 50%;
    // transform: translate(-50%, 0px);
    max-width: calc(100% + 20px);

    @media(min-width: 768px){
      bottom: 10px;
    }
  }

  @media(min-width: 768px){
    height: 60px;
    gap: 15px;
    padding: 10px 32px;
    max-width: calc(100% - 20px);
  }

  &.fullscreen-mode {
    position: sticky;
    z-index: 2;
    bottom: 10px;
    left: calc(100% - 600px);
    width: auto;
    max-width: 100%;

    @media(min-width: 768px){
      max-width: 600px;
    }
  }
`

export const Tools = styled('div')`
  display: flex; 
  width: 100%; 
  gap: 5px;

  @media(min-width: 768px){
    gap: 15px;
  }
`

export const ThumbnailsSidebar = styled('div')`
 
`

export const PreviewMainWrapper = styled('div')`
  width: 100%;
  position: relative;

  &.fullscreen-mode {
    width: 100%;
  }

  @media(min-width: 768px){
    width: calc(100% - 50px - 220px);
    height: calc(100% - 50px - 220px);

    &.fullscreen-mode {
      width: 100%;
    }
  }
`

export const PreviewWrapper = styled('div') <{ height: number, width: number, isMobile: boolean }>`
  border-radius: 6px;
  border: 2px solid #D9D9D9;
  overflow: auto;
  scroll-behavior: smooth;
  max-width: 100%;
  max-height: calc(100vh - 200px);

  ::-webkit-scrollbar {
    display: none;
  }

  @media(min-width: 768px){
    ${({ width, height, isMobile }) => {
    const maxWidth = isMobile ? 355 : 670;
    const aspectRatio = width / height;
    const scaledHeight = maxWidth / aspectRatio;

    return `
        width: ${maxWidth}px;
        height: ${scaledHeight}px;
        max-width: ${maxWidth}px;
        max-height: ${scaledHeight}px;
      `;
  }}
  }

  &.fullscreen-mode {
    border: none;
    overflow: auto;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
  }
`


export const ThumbnailsWrapper = styled('div')`
  width: 220px;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: #FAFAFA;
  padding: 0px;
  max-height: calc(-100px + 100vh);
  position: sticky;
  top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const ThumbnailsList = styled('div')`
  display: block;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;

  @media(min-width: 768px){
  
  }
`

export const Scroll = styled('div') <{ height, width, isMobile }>`
  ${({ width, height, isMobile }) => {
    const maxWidth = isMobile ? 355 : 670;
    const aspectRatio = width / height;
    const scaledHeight = maxWidth / aspectRatio + 80;

    return `
        height: ${scaledHeight}px;
        max-height: ${scaledHeight}px;
        max-height: calc(100vh - 100px);
      `;
  }}
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }

  &.fullscreen-mode {
    height: 100vh;
    max-height: 100vh;
  }
`
export const Button = styled('button') <{ skin?}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  border: 0px;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background-color: ${({ skin, theme }) => skin === 'primary' ? theme.colors.Primary : theme.colors.Primary25}; 
  cursor: pointer;
  opacity: 1;
  transition: all .2s ease-in-out;
  flex-shrink: 0;

  &:hover {
    opacity: .75;
  }

  &:disabled {
    background-color: ${({ skin, theme }) => skin === 'primary' ? theme.colors.Primary25 : theme.colors.Primary25};
    i {
      &:before {
        background-color: white;
      }
    }
  }

  @media(min-width: 768px){
    width: 40px;
    height: 40px;
  }

  @media(min-width: 1314px){
    width: 60px;
  }
`

export const DownloadLink = styled('a') <{ skin?}>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 10px;
  margin: 0;
  border: 0;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  background-color: ${({ skin, theme }) => skin === 'primary' ? theme.colors.Primary : theme.colors.Primary25}; 
  cursor: pointer;
  opacity: 1;
  transition: all .2s ease-in-out;
  flex-shrink: 0;
  color: #000000;

  &:hover {
    opacity: .75;
  }
  
  &:visited {
    color: inherit;
  }

  &:disabled {
    background-color: ${({ skin, theme }) => skin === 'primary' ? theme.colors.Primary25 : theme.colors.Primary25};
    i {
      &:before {
        background-color: white;
      }
    }
  }

  @media(min-width: 768px){
    width: 40px;
    height: 40px;
  }

  @media(min-width: 1314px){
    width: auto;
  }
`

export const Input = styled('input')`
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 6px;
  border: 1px solid rgba(45, 52, 54, .75);
  color: rgba(45, 52, 54, .75);
  background: #FFFFFF;

  @media(min-width: 768px){
    height: 40px;
    line-height: 40px;
    width: 50px;
  }
`

export const Pagination = styled('div')`
  min-width: max-content;
  color: rgba(45, 52, 54, .75);
  display: flex;
  align-items: center;
  gap: 15px;
`

const progressIndeterminate = keyframes`
  0 % {
    transform: translateX(0 %);
  }
  50 % {
    transform: translateX(100 %);
  }
  100 % {
    transform: translateX(100 %);
  }
`;

export const LoaderWrapper = styled('div')`
  padding: 40px;
  width: 100%;
  max-width: 500px;
  margin: 50px auto;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 12px 0px, rgba(0, 0, 0, 0.12) 0px 12px 40px 0px;
`

export const Loader = styled('div') <{ progress }>`
  --progressBar-percent: ${({ progress }) => progress}%;
    position: relative;
    border-radius: 6px;
    height: 12px;
    background-color: ${({ theme }) => theme.colors.Primary25};

  .progress {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    transform: scaleX(var(--progressBar-percent));
    transform-origin: 0 0;
    background-color: ${({ theme }) => theme.colors.Primary};
    overflow: hidden;
    transition: transform 200ms;
  }
`

export const LoaderText = styled('div')`
  text-align: center;
  margin-top: 10px;
`



export const ZoomControl = styled('div') <{ zoom, height, width }>`
  ${({ height, zoom }) => height > 1 ? `height: ${height * zoom}px` : ''};
  ${({ width, zoom }) => width > 1 ? `width: ${width * zoom}px` : ''};
  transition: all .2s ease-in-out;
  margin: 0 auto;
`
