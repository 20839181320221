import * as Yup from "yup";

export const schema = Yup.object().shape({
  newPassword: Yup.string().required("Debes ingresar una contraseña"),
  confirmPassword: Yup.string()
    .required("Debes confirmar la contraseña")
    .oneOf([Yup.ref('newPassword'), null], 'Las contraseñas deben coincidir'), // Aquí se valida que sea igual a newPassword
});

interface FormValues {
  newPassword: string,
  confirmPassword: string
}

export const initialValues: FormValues = {
  newPassword: '',
  confirmPassword: ''
};