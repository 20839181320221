import Helmet from "react-helmet";
import { connect, useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import Submit from "../../../components/FormikForm/Submit";
import Grid from "../../../components/Grid";
import Input from "../../../components/FormikForm/Input";
import {
  Separator,
} from "../../../components/StyledComponents";
import {
  initialValues,
  schema,
} from "../../../constants/forms/survey/new-answer";
import Loader from "../../../components/LogoLoader";
import { getGroupBySlug, resetGetGroup } from "../../../store/actions/group";
import {
  Group,
  Subscription,
  SurveyInDto,
  User,
} from "../../../types";
import { Card } from "./styles";
import NoSubscribed from "../components/NoSubscribed";
import {
  getSurvey,
  newSurveyAnswer,
} from "../../../store/actions/survey";
import { isSubscribed } from "../../../helpers/isSubscribed";
import { Center } from "../../Dashboard/components/styles";
import { getUserPreferences, savePreference } from "../../../store/actions/preferences";
import { Preference } from "../../../types/preferences";
import { Redirect } from "react-router";
import { showPreference } from "../../../helpers/preferenceVisible";
import { isCongreso } from "../../../helpers/isCongreso";
import { isCommunity } from "../../../helpers/isCommunity";
import { isOwnGroup } from "../../../helpers/isOwnGroup";
import { getUserFreeSubscriptions, getUserSubscriptions } from "../../../store/actions/user";
import { getFreeSubscriptions } from "../../../helpers/getFreeSubscriptions";

const groupTypes = {
  membership: "a la membresía",
  experience: "al taller",
  free_experience: "al evento gratuito",
  community: "a la comunidad",
};

const titleByGroupTypes = {
  membership: "una nueva membresía",
  experience: "un nuevo taller",
  free_experience: "un nuevo evento",
  community: "una nueva comunidad",
};

interface ComponentProps {
  subscriptions: Subscription[];
  isLoggedIn: boolean;
  survey: SurveyInDto;
  surveyStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  newAnswer;
  newAnswerStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  preferences: Preference[];
  preferencesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  savePreferenceStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  user: User
  match;
}

const Component = ({
  subscriptions,
  isLoggedIn,
  survey,
  surveyStates,
  group,
  groupStates,
  newAnswerStates,
  preferences,
  preferencesStates,
  savePreferenceStates,
  user,
  match,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [showSurvey, setShowSurvey] = useState<boolean | undefined>();
  const [subscription, setSubscription] = useState<Subscription>();
  const [formSubmmited, setFormSubmmited] = useState(false);

  const redirect = useMemo(() => {
    if (!!group) {
      return groupStates?.error || isCongreso(group?.id) || isCommunity(group?.group_type) || (!isOwnGroup({ group, expertId: user?.expert?.id }) && group?.status === 'draft')
    }

    return false
  }, [group, user])

  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn || group?.group_type === 'free_experience'
  }, [isLoggedIn, group])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(subscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [subscriptions, group, isLoggedIn])

  const onSubmit = ({ values, actions }) => {
    dispatch(newSurveyAnswer(values));
  };

  const hideSurvey = () => {
    dispatch(
      savePreference({
        preferenceSlug: `survey-${survey?.id}`,
        preferenceValue: false,
      })
    );
  };

  useEffect(() => {
    if (
      !!match?.params &&
      (!group?.slug || (!!group?.slug && group?.slug !== match?.params?.group))
    ) {
      console.log('Survey', match?.params?.group)
      match?.params && dispatch(getGroupBySlug(match?.params?.group));
    }
  }, [match]);

  useEffect(() => {
    if (group?.slug === match?.params?.group && group?.survey) {
      dispatch(getSurvey({ idSurvey: group?.survey }));

      if (group?.group_type === 'free_experience') {
        dispatch(getUserFreeSubscriptions())
      }
    }
  }, [group]);

  useEffect(() => {
    if (
      group?.slug === match?.params?.group &&
      !!subscriptions &&
      subscriptions?.length > 0
    ) {
      setSubscription(
        subscriptions.filter((item) => item?.group?.id === group?.id)[0]
      );
    }
  }, [subscriptions, group]);

  useEffect(() => {
    if (newAnswerStates.success) {
      hideSurvey();
    }
  }, [newAnswerStates]);

  useEffect(() => {
    setShowSurvey(
      showPreference({
        preferences,
        preferencesStates,
        id: `survey-${survey?.id}`,
      })
    );
  }, [preferences, survey]);

  useEffect(() => {
    if (savePreferenceStates.success) {
      if (group?.recorded) {
        window.location.href = `/group/${match.params.group}/modules`;
      } else if (group?.group_type === 'free_experience') {
        window.location.href = `/group/${match.params.group}/library`;
      } else {
        window.location.href = `/group/${match.params.group}/activity-summary`;
      }
    }
  }, [savePreferenceStates]);

  useEffect(() => {
    if (
      group?.slug === match?.params?.group &&
      ((!!group && groupStates.success && !group.survey) ||
        (showSurvey === false && surveyStates.success))
    ) {

      if (group?.recorded) {
        window.location.href = `/group/${match.params.group}/modules`;
      } else if (group?.group_type === 'free_experience') {
        window.location.href = `/group/${match.params.group}/library`;
      } else {
        window.location.href = `/group/${match.params.group}/activity-summary`;
      }
    }
  }, [group, showSurvey, survey]);


  useEffect(() => {
    dispatch(getUserPreferences())
    dispatch(getUserSubscriptions());

    return () => {
      dispatch(resetGetGroup())
    }
  }, [])

  if (
    !group ||
    (!!group && groupStates.success && !group.survey) ||
    (!showSurvey && surveyStates.success) ||
    !isUserSubscribed ||
    !isUserAuthenticated
  ) {
    return <Loader />;
  }

  if (redirect) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <Helmet>
        ‍<title>Encuesta</title>‍
        <meta name="description" content="Encuesta" />
      </Helmet>

      <Separator height={{ desktop: "70px", mobile: "40px" }} />

      <Grid.Container>
        {/* <Separator height={{ desktop: "63px", mobile: "40px" }} /> */}

        {/* <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            <RainbowSectionName>
              {!!group?.creator_name &&
                `${groupTypes[group?.group_type]} de ${group?.creator_name
                }
                `}
            </RainbowSectionName>
            <Center horizontal="center" vertical="top">
              <Title style={{ maxWidth: "582px" }}>
                ¡Felicidades! Te suscribiste a{" "}
                {titleByGroupTypes[group?.group_type]} 💫
              </Title>
            </Center>
          </Grid.Col>
        </Grid.Row> */}

        {/* <Separator height={{ desktop: "40px", mobile: "16px" }} /> */}

        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            <Center horizontal="center" vertical="top">
              <Card>
                Antes de continuar {groupTypes[group?.group_type]}, tomate unos minutos para completar
                la siguiente encuesta para brindarte una mejor experiencia.

                <div style={{ height: "32px" }} />

                <Formik
                  initialValues={{
                    ...initialValues,
                    ...(!!survey && {
                      answers: survey?.fields?.map((field, index) => ({
                        value: null,
                      })),
                    }),
                    survey: survey?.id,
                    subscription: subscription?.id,
                  }}
                  onSubmit={(values, actions) => {
                    if (onSubmit) onSubmit({ values, actions });
                  }}
                  validateOnChange={false}
                  validateOnBlur={formSubmmited}
                  validationSchema={schema}
                  enableReinitialize
                >
                  {({
                    errors,
                    values,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => {
                    return (
                      <form
                        className="theme-form"
                        onSubmit={(event) => {
                          setFormSubmmited(true);
                          handleSubmit(event);
                        }}
                        id="new-survey-answer-form"
                      >
                        {survey?.fields?.map((field, index) => {
                          if (field.field_type === "short_text") {
                            return (
                              <Input
                                disabled={newAnswerStates.success}
                                key={`answers-${index}`}
                                name={`answers.${index}.value`}
                                error={errors[`answers.${index}.value`]}
                                touched={touched[`answers.${index}.value`]}
                                value={values[`answers.${index}.value`]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Ingresa tu respuesta"
                                options={{
                                  label: field.caption,
                                  marginBottom: 40,
                                }}
                              />
                            );
                          }
                        })}
                      </form>
                    );
                  }}
                </Formik>
                <div style={{ height: "32px" }} />
                <Center horizontal="right" vertical="center" gap={10}>
                  {/* <Button
                        disabled={
                          newAnswerStates.loading || newAnswerStates.success
                        }
                        options={{
                          type: "outline",
                          skin: "purple",
                          size: "lg",
                          block: true,
                        }}
                        onClick={hideSurvey}
                        style={{ maxWidth: "205px" }}
                      >
                        No responder
                      </Button> */}

                  <Submit
                    isSubmmiting={
                      newAnswerStates.loading || newAnswerStates.success
                    }
                    form="new-survey-answer-form"
                    options={{
                      type: "filled",
                      skin: "purple",
                      size: "lg",
                      block: true,
                    }}
                    style={{ maxWidth: "205px" }}
                  >
                    Enviar
                  </Submit>
                </Center>
              </Card>
            </Center>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <div style={{ height: "32px" }} />

      <NoSubscribed />
    </>
  );
};

const state = ({ userStore, surveyStore, groupStore, preferencesStore }) => {
  const { isLoggedIn } = userStore;
  const { data: user } = userStore.information;
  const { data: survey, states: surveyStates } = surveyStore.selected;
  const { data: newAnswer, states: newAnswerStates } = surveyStore.newAnswer;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: subscriptions } = userStore.subscriptions;
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;
  const { states: savePreferenceStates } = preferencesStore.savePreference;

  return {
    subscriptions,
    isLoggedIn,
    survey,
    surveyStates,
    group,
    groupStates,
    newAnswer,
    newAnswerStates,
    preferences,
    preferencesStates,
    savePreferenceStates,
    user
  };
};

export default connect(state)(Component);
