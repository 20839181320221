
import Grid from "../../../components/Grid";
import { initialValues, schema } from "../../../constants/forms/password-recovery";
import { Formik } from "formik";
import Image from '../../../components/Image'
import Link from "../../../components/Link";
import Input from "../../../components/FormikForm/Input";
import Submit from "../../../components/FormikForm/Submit";
import { Content, Title, Text } from "./styles";
import { connect, useDispatch } from "react-redux";
import Snackbar from "../../../components/Snackbar";
import {
  resetSendPasswordRecoveryEmail,
  sendPasswordRecoveryEmail,
} from "../../../store/actions/auth";
import emailIcon from "../../../assets/icons/mail.svg";
import { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";


interface ComponentProps {
  sendPasswordRecoveryEmailStates;
}

const Component = ({
  sendPasswordRecoveryEmailStates,
}: ComponentProps) => {
  const theme = useTheme() as { logo: { small: string, big: string } };
  const [isValidating, setIsValidating] = useState(false)
  const [email, setEmail] = useState<string>('')
  const dispatch = useDispatch();
  const [formSubmmited, setFormSubmmited] = useState(false);
  const history = useHistory();

  const onSubmit = async ({ values, actions }) => {
    const { email } = values
    setEmail(email)

      // Guardar el flag en localStorage
    localStorage.setItem("passwordRecoveryStartedForEmail", email);

    dispatch(sendPasswordRecoveryEmail({
      email
    }));
  };


  useEffect(() => {
    if (sendPasswordRecoveryEmailStates.success) {
      setTimeout(() => {
        dispatch(resetSendPasswordRecoveryEmail())
        setEmail('')
        setFormSubmmited(false)
        history.push("/auth/password-recovery-sent")
      }, 500);
    }
  }, [sendPasswordRecoveryEmailStates.success]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
      <Grid.Container style={{ maxWidth: "370px" }}>
        <Grid.Row>
          <Grid.Col>
            <Content>
              <Image
                src={theme?.logo?.big}
                style={{ display: 'block' }}
                width="128px"
                height="auto"
                className="logo-desktop"
                alt="Klouser"
                lazy
              />
            </Content>
          </Grid.Col>
        </Grid.Row>
        <div style={{ height: 34 }} />
        <Grid.Row>
          <Grid.Col>
            <Title>Restablece tu contraseña</Title>
          </Grid.Col>
        </Grid.Row>
        <div style={{ height: 22 }} />
        {/* {!sendPasswordRecoveryEmailStates.success ? <> */}
        <Grid.Row>
          <Grid.Col>
            <Text>
              Ingresa tu correo electrónico y te enviaremos un enlace válido por 48 horas para restablecer tu contraseña. Si expira, solicita uno nuevo.
            </Text>
          </Grid.Col>
        </Grid.Row>
        <div style={{ height: 22 }} />
        <Grid.Row>
          <Grid.Col>
            <Content>
              <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => {
                  if (onSubmit) onSubmit({ values, actions });
                }}
                validateOnChange={false}
                validateOnBlur={formSubmmited}
                validationSchema={schema({ validationRunning: setIsValidating})}
                enableReinitialize
              >
                {({ handleSubmit, ...rest }) => {
                  return (
                    <form
                      style={{ width: '100%' }}
                      className="theme-form"
                      onSubmit={(event) => {
                        setFormSubmmited(true);
                        handleSubmit(event);
                      }}
                      id="password-recovery-form"
                    >
                      <Input
                        name="email"
                        error={rest.errors["email"]}
                        touched={rest.touched["email"]}
                        value={rest.values["email"]}
                        onChange={rest.handleChange}
                        onBlur={rest.handleBlur}
                        placeholder={"Ingresa tu e-mail"}
                        options={{
                          after: <img src={emailIcon} alt="Email" />,
                          label: "Email",
                          marginBottom: 0,
                        }}
                      />
                    </form>
                  );
                }}
              </Formik>
              <div style={{ height: 22 }} />
              <div style={{ display: 'flex', gap: 16 }}>
                <Link
                  href='/subscriber/signin'
                  disabled={sendPasswordRecoveryEmailStates.loading}
                  options={{
                    skin: "purple",
                    size: "lg",
                    type: "outline",
                  }}
                >
                  Volver atrás
                </Link>

                <Submit
                  form="password-recovery-form"
                  disabled={sendPasswordRecoveryEmailStates.loading || isValidating}
                  isSubmmiting={sendPasswordRecoveryEmailStates.loading}
                  options={{
                    skin: "purple",
                    size: "lg",
                    type: "filled",
                  }}
                >
                  Continuar
                </Submit>
              </div>
            </Content>
          </Grid.Col>
        </Grid.Row>
        {/* </> : <>
        <Grid.Row>
          <Grid.Col>
            <Content>
              <Image src={AccentMark} lazy />
              <div style={{ height: 22 }} />
              <Text>Si el correo electrónico {email} existe, recibirás un e-mail para continuar.</Text>
            </Content>
          </Grid.Col>
        </Grid.Row> 
       </>} */}
      </Grid.Container>
{/* 
      <Snackbar
        visible={sendPasswordRecoveryEmailStates.success || sendPasswordRecoveryEmailStates.error}
        options={{
          type: sendPasswordRecoveryEmailStates.success ? "success" : "error",
          position: "left",
          time: 10000
        }}
      >
        {sendPasswordRecoveryEmailStates.success
          ? "Ingresa a tu correo, te enviamos el enlace para restablecer tu contraseña."
          : "Error al enviar e-mail."}
      </Snackbar> */}
    </div>
  );
};

const state = ({ authStore }) => {
  const { states: sendPasswordRecoveryEmailStates } =
    authStore.sendPasswordRecoveryEmail;

  return {
    sendPasswordRecoveryEmailStates,
  };
};

export default connect(state)(Component);
