import action from "./../helpers/rest-client";
import { SignInData, SignUpData } from "../types";
import firebase from "./../firebase";
import constants from "../constants";
import { getUrlParamByName } from "../helpers/get-url-param";

export class ErrorAuth extends Error { }

class Auth {
  public async signUpWithCredentials({
    email,
    password,
    name,
    initial_profile,
  }: SignUpData) {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;

        if (user && name) {
          await user.updateProfile({
            displayName: name,
          });
        }

        return { response: { user, initial_profile } };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async signUpLocalRegistration(payload) {
    return action
      .Post({
        url: `/api/auth/signup`,
        body: payload,
      })
      .then((response) => {
        sessionStorage.setItem("FbRefreshToken", "true");
        return { response };
      })
      .catch((error) => {
        console.log('signUpLocalRegistration', error.response)
        return { error: error.response.data };
        // return "Error al dar de alta usuario localmente";
      });
  }

  public async signIn({ email, password }: SignInData) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        return { response: { user } };
      })
      .catch((error) => {
        return { error };
      });
  }

  public async signOut() {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        const hasExpertProfile = !!sessionStorage.getItem("hasExpertProfile");

        if (hasExpertProfile) {
          window.location.href = constants.urls.expert.signin.url;
        } else {
          window.location.href = constants.urls.subscriber.signin.url;
        }

        return true;
      })
      .catch((error) => {
        throw new ErrorAuth(error.response.statusText);
      });
  }

  public async passwordRecovery(payload) {
    const { email } = payload;
    let url = `${process.env.REACT_APP_SITE_BASE_URL}/profile?email=${email}`;

    /**
     * Si contamos con una suscripcion en progreso
     * devolvemos al usuario a la suscripcion y no al profile
     */
    const subscription = JSON.parse(
      sessionStorage.getItem("subscription") || ""
    );

    const subscriptionInProgress =
      subscription.subscriptionState === "INPROGRESS";
    const stepAuthentication =
      subscription.subscriptionStep === "AUTHENTICATION";

    if (subscriptionInProgress && stepAuthentication) {
      url = `${process.env.REACT_APP_SITE_BASE_URL}${subscription.groupUrl}?email=${email}`;
    }

    return firebase
      .auth()
      .sendPasswordResetEmail(email, {
        url,
        handleCodeInApp: true,
      })
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem("emailForSignIn", email);
        // ...
      })
      .catch((error) => {
        throw new ErrorAuth(error.message);
      });
  }

  public async validateEmail(email: string) {
    return action
      .Post({
        url: `/api/auth/validate-email`,
        body: { email },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
  }

  public async sendPasswordRecoveryEmail(payload) {
    return action
      .Post({
        url: `/api/auth/send-password-recovery-email`,
        body: payload,
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        throw { error };
      });
  }

  public async changePassword(payload) {
    const { token, ...rest } = payload
    return action
      .Post({
        url: `/api/auth/change-password`,
        body: rest,
        config: {
          headers: {
            "x-token": token
          }
        }
      })
      .then((response) => {
        return { response };
      })
      .catch((error) => {
        throw { error };
      });
  }
}

const auth = new Auth();
export default auth;
