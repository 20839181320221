import Helmet from "react-helmet";
import { connect, useDispatch } from "react-redux";
import { getUserSubscriptions } from "../../../store/actions/user";
import Card from "../../../components/SubscriptionCard";
import PendingInvititations from './components/PendingInvitationModal'
import { useEffect, useMemo, useState, useContext } from "react";
import Loader from "../../../components/LogoLoader";
import Grid from "../../../components/Grid";
import { CardsGrid } from "./styles";
import Alert from "./../../../components/Alert";
import {
  Title,
  RainbowSectionName,
  Separator,
} from "../../../components/StyledComponents";
import { Layout, useLayout } from "../../../providers/LayoutProvider";
import ModalPhoneNumberContext from "../../../../src/components/Layout/components/ModalPhoneNumberContext";
import { Subscription, User } from "../../../types";
import { showModal } from "../../../store/actions/modal";

interface ComponentProps {
  subscriptions: Subscription[];
  subscriptionsStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  userInformation: User;
  userInformationStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}


const Component = ({
  subscriptions,
  subscriptionsStates,
  userInformation,
  userInformationStates,
}: ComponentProps) => {
  const dispatch = useDispatch();
  const [subscriptionInvited, setSubscriptionInvited] = useState<Subscription>()
  const { setLayout, layout } = useLayout();
  const phoneNumberModalVisible = useContext(ModalPhoneNumberContext);

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      header: {
        visible: false,
        actions: [],
      },
      page: {
        title: "Mis suscripciones",
        description: "Mis suscripciones",
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      body: {
        visible: true,
      },
    }),
    [layout, subscriptions, userInformation]
  );

  useEffect(() => {
    setLayout(layoutData);
  }, [subscriptions, userInformation]);

  useEffect(() => {
    if (!!subscriptions) {
      const pendingInvite = subscriptions?.find((subscrition) => subscrition.status === 'pending_invite')

      if (!!pendingInvite && !phoneNumberModalVisible) {
        setSubscriptionInvited(pendingInvite)
        dispatch(showModal("pending-invitation"))
      }
    }
  }, [subscriptions, phoneNumberModalVisible]);

  useEffect(() => {
    setLayout(layoutData);
  }, []);

  useEffect(() => {
    !!userInformation && dispatch(getUserSubscriptions(
      {
        filters: {
          status_in: "active,non_renewing,paid,pending_invite,waiting_for_gateway,not_paid,retrying,renewal_retrying",
        }
      }
    ))
  }, [userInformation]);

  if (subscriptionsStates.loading || userInformationStates.loading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        ‍<title></title>‍
        <meta name="description" content="" />
      </Helmet>

      <Grid.Container>
        <Grid.Row>
          <Grid.Col lg={10} offset={{ lg: 1 }}>
            <Separator height={{ desktop: "40px", mobile: "40px" }} />

            <RainbowSectionName>
              Hola de nuevo, {userInformation?.name}
            </RainbowSectionName>

            <Title>Mis Suscripciones</Title>

            <Separator height={{ desktop: "32px", mobile: "16px" }} />

            {/* <Alert
              visible={true}
              title="Comienza tu experiencia uniéndote al grupo de Telegram!"
              text="Ingresa a los canales para enterarte de todas las novedades, próximos encuentros, actividades y mucho más... Te esperamos!!"
              id="unete-a-telegram"
            /> */}

            <Separator height={{ desktop: "32px", mobile: "16px" }} />

            {subscriptions?.length > 0 ? (
              <CardsGrid>
                {subscriptions?.map((subscription, index2) => {
                  const { group } = subscription;
                  let url = `/group/${group?.slug}/activity-summary`;

                  if (group?.recorded) {
                    url = `/group/${group?.slug}/modules`;
                  } else if (group?.group_type === 'free_experience') {
                    url = `/group/${group?.slug}/library`;
                  }

                  return (
                    <Card
                      key={`subscription-${index2}`}
                      group={group}
                      expert={group.expert}
                      link={url}
                      subscription={subscription}
                    //action={() => {
                    //  setSubscriptionInvited(subscription);
                    //dispatch(showModal("pending-invitation"));
                    //}}
                    />
                  );
                })}
              </CardsGrid>
            ) : (
              <h3>Aun no estas suscripto a ningun grupo</h3>
            )}

            <div style={{ height: "32px" }} />
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>

      <PendingInvititations />
    </>
  );
};

const state = ({ userStore }) => {
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;
  const { data: userInformation, states: userInformationStates } =
    userStore.information;

  return {
    subscriptions,
    subscriptionsStates,
    userInformation,
    userInformationStates,
  };
};

export default connect(state)(Component);
