import { useMemo } from 'react';
import { getFreeSubscriptions } from '../../../../helpers/getFreeSubscriptions';
import { isSubscribed } from '../../../../helpers/isSubscribed';
import { Group } from '../../../../types';
import Icon from '../../../../components/Icon';
import arrowLinkIcon from '../../../../assets/icons/arrow_forward_up_right-semibold.svg'
import {
  Card,
  Title,
  Text,
  Link
} from './styles'
import { connect } from 'react-redux';
import MPRetryingSubscriptionButton from '../MPRetryingSubscriptionButton';
import { getPriorityGroupSubscription } from '../../../../helpers/mpStatusPriority';

interface ComponentProps {
  group: Group;
  userSubscriptions
  isLoggedIn: Boolean;
}

const Component = ({
  group,
  userSubscriptions,
  isLoggedIn,
}: ComponentProps) => {

  const isUserAuthenticated = useMemo(() => {
    return isLoggedIn || group?.group_type === 'free_experience'
  }, [isLoggedIn, group])

  const isUserSubscribed = useMemo(() => {
    if (isLoggedIn) {
      return isSubscribed(userSubscriptions, group?.id)
    } else if (!isLoggedIn || group?.group_type === 'free_experience') {
      return isSubscribed(getFreeSubscriptions(), group?.id)
    } else {
      return false
    }
  }, [userSubscriptions, group, isLoggedIn])

  const subscription = useMemo(() => {
    return getPriorityGroupSubscription(userSubscriptions, group?.id)
  }, [userSubscriptions, group])

  const isDisapprovedPayment = useMemo(() => {
    return subscription?.status === 'renewal_retrying'
  }, [subscription])

  const cardUpdatedForRetrying = useMemo(() => {
    return !!subscription?.card_updated_for_retrying
  }, [subscription])

  const isPaidSubscription = useMemo(() => {
    const freeSubscriptionTypes = ["guest", "free"]
    return !freeSubscriptionTypes?.includes(subscription?.type)
  }, [subscription])

  if (!isUserAuthenticated || !isUserSubscribed || !isDisapprovedPayment || !isPaidSubscription) {
    return <></>
  }

  

  return (
    !cardUpdatedForRetrying ? (
      <Card>
      <Title>Tu pago ha fallado</Title>
      <div style={{ height: 18 }} />
      <Text>Para conservar tu acceso y tu tarifa actual<br />en {group?.group_name}, por favor<br />actualiza tus datos de pago.</Text>
      <div style={{ height: 18 }} />
      {console.log(subscription)}
      <Link
        options={{ skin: 'white', size: 'sm', type: 'filled' }}
        href={subscription?.status === "renewal_retrying" &&  subscription?.payment_processor === "rebill" 
          && !!subscription?.migration_token && subscription?.migration_flag === "to_migrate" ?
          `/checkout/change-payment-method?t=${subscription?.migration_token}` :
          `/profile/manage-subscriptions/update?subscriptionId=${subscription?.id}`
        }
        target="_blank"
      >
        Actualizar método de pago
        <Icon icon={arrowLinkIcon} color={'white'} size="20px" />
      </Link>
      <div style={{ height: 18 }} />
      <Text>¡Es rápido y sencillo!</Text>
    </Card>
    ) : (
      <Card style={{padding: 15}}>
      {/* <div style={{ height: 18 }} /> */}
      <Text>¡Has actualizado tu Método de pago exitosamente!<br/>Haremos un reintento de cobro en los próximos días</Text>
      </Card>
    )
  )
}


const state = ({ userStore, groupStore }) => {
  const { data: group } = groupStore.group;
  const { data: userSubscriptions } = userStore.subscriptions;
  const { isLoggedIn } = userStore;

  return {
    group,
    userSubscriptions,
    isLoggedIn,
  };
};

export default connect(state)(Component);
