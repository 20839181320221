import { ThemeProvider } from 'styled-components'
import klouserSkin from '../../skins/main'
import customSkin from '../../skins/custom'
import GlobalStyles from '../../GlobalStyles'
import { useMemo } from 'react'
import { connect } from 'react-redux'
import { Group, User } from '../../../../types'

interface ComponentProps {
  children: any
  group: Group,
}

const Component = ({ group, children }: ComponentProps) => {
  const skin = useMemo(() => {
    const primary = group?.expert?.primary_color;
    const secondary = group?.expert?.secondary_color || group?.expert?.primary_color;
    const logoBig = group?.expert?.big_logo?.url;
    const logoSmall = group?.expert?.small_logo?.url;

    return {
      ...klouserSkin,
      ...(!!primary || !!secondary ? {
        colors: customSkin.colors({ primary, secondary }),
        form: customSkin.form({ primary, secondary }),
        button: {
          ...klouserSkin.button,
          colors: { ...customSkin.button.colors },
        },
        link: {
          ...klouserSkin.link,
          colors: { ...customSkin.link.colors },
        },
        bullet: {
          ...klouserSkin.bullet,
          color: customSkin.bullet({ primary, secondary }).color,
        },
        footer: customSkin.footer({ primary, secondary }),
        howItsWorks: customSkin.howItsWorks({ primary, secondary }),
      } : {}),
      logo: {
        big: !!logoBig ? logoBig : klouserSkin?.logo?.big,
        small: !!logoSmall ? logoSmall : klouserSkin?.logo?.small
      }
    }
  }, [group])


  return (
    <ThemeProvider theme={skin}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  )
}

const state = ({ groupStore, userStore }) => {
  const { data: group } = groupStore.group;

  return { group };
};

export default connect(state)(Component);
