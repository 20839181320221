import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";

export const Card = styled("div") <{
  isInProgress?: boolean;
  isExpired?: boolean;
}>`
  text-decoration: none;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  padding: 10px 10px;
  background-color: ${({ isInProgress }) =>
    isInProgress ? "#e5e2f7" : "white"};
  box-shadow: 0px 0px 10px rgba(108, 92, 231, 0.15);
  border-radius: 14px;
  overflow: hidden;
  width: 100%;
`;

export const Cover = styled(Link)`
text-decoration: none;
`;

export const ExpertName = styled("p")`
  margin: 0px;
  font-size: 12px;
  line-height: 15px;
  min-height: 15px;
  color: ${(props) => props.theme.colors.BlueLight};
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Information = styled("div")`
  width: 100%;
  height: 100%;
  padding: 0px;
`;

export const Title = styled("h2")`
  margin: 0px;
  font-weight: 600;
  font-size: 16px;
  line-height: normal;
  min-height: 40.5px;
  color: ${({ theme }) => theme.colors.Black};
  overflow: hidden;
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

export const StyledNextDays = styled.div`
  display: inline-block;
  padding: 3px 6px;
  border-radius: 20px;
  background: linear-gradient(90deg, rgb(66, 178, 252, 0.2) 0%, rgb(108, 92, 231, 0.2)  31%, rgb(207, 171, 242, 0.2) 99%);
  color: black;
  font-size: 12px;
  text-align: center;
`;


export const Status = styled("div")``;

export const InLine = styled("div")`
  display: flex;
  align-items: center;
`;

export const Date = styled("div") <{ backgroundImage }>`
  position: relative;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-size: cover;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    left:  0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.Black32};
  }

  & > * {
    position: relative;
    z-index: 10;
  }
`;

export const Day = styled("div")`
  font-weight: 600;
  font-size: 22px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.White};
  line-height: normal;
`;

export const Number = styled("div")`
  font-weight: 700;
  font-size: 40px;
  color: ${({ theme }) => theme.colors.White};
  line-height: normal;
`;

export const When = styled("span")`
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.Black};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const sonarWave = keyframes`
from {
  opacity: 0.4;
}
to {
  transform: scale(3);
  opacity: 0;
}`;

export const Sonar = styled("div")`
  position: relative;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.Primary};
  margin-right: 4px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.Primary};
    opacity: 0;
    pointer-events: none;
    animation: ${sonarWave} 1.5s linear infinite;
  }
`;

export const Hour = styled("div")`
  font-weight: 400;
  font-size: 14px;
  line-height: normal;
  color: ${({ theme }) => theme.colors.Black};
  text-transform: capitalize;
`;
