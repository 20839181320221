import React, { useState, useCallback, useRef, useEffect } from 'react';
import Icon from '../Icon';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { pdfjs, Document, Page } from 'react-pdf';
import { useWindowSize } from '../../hooks/useWindowSize';
import Thumbnail from './components/Thumbnail'
import { PdfLink } from "../../pages/Group/Modules/components/SelectedModule/modules/Post/styles";
import Spinner from '../Loader';
import GoBackIconSVG from '../../assets/icons/go-back-arrow.svg';
import GoFowardIconSVG from '../../assets/icons/go-foward-arrow.svg';
import ZoomInSVG from '../../assets/icons/zoom_in.svg';
import downloadFileIcon from "../../assets/icons/download-file.svg";
import ZoomOutSVG from '../../assets/icons/zoom_out.svg';
import CollapsePdfSVG from '../../assets/icons/collapse.svg';
import SizePdfSVG from '../../assets/icons/size.svg';

import {
  MainWrapper,
  Toolbar,
  Tools,
  ThumbnailsSidebar,
  PreviewMainWrapper,
  PreviewWrapper,
  ThumbnailsWrapper,
  ThumbnailsList,
  Scroll,
  Button,
  Input,
  Pagination,
  ZoomControl,
  LoaderWrapper,
  Loader,
  LoaderText,
  DownloadLink,
} from './styles';
import slugify from 'slugify';

const DEFAULT_ZOOM_DELTA = 0.05;
const DEFAULT_SCALE = 1;

interface PdfViewerProps {
  fileUrl: string;
  fileName: string;
  blockDownloadFiles?: boolean;
  key?: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`,
  import.meta.url,
).toString();


const PdfViewer: React.FC<PdfViewerProps> = ({ fileUrl, fileName, blockDownloadFiles, key }) => {
  const { isMobile, isDesktop } = useWindowSize();
  const scrollRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const mainWrapRef = useRef<HTMLDivElement>(null);
  const thumbnailsListRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [isPdfLibrarySupported, setIsPdfLibrarySupported] = useState<boolean>(true)
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pdfLoadingPercent, setPdfLoadingPercent] = useState<number>(0);
  const [pdfError, setPdfError] = useState<string>('')
  const [pdfZoom, setPdfZoom] = useState<number>(1);
  const [pdfWidth, setPdfWidth] = useState<number>(0);
  const [pdfHeight, setPdfHeight] = useState<number>(0);
  const handle = useFullScreenHandle();

  const INITIAL_PAGES = isMobile ? 1 : 10000;
  const PADDING_PAGES = INITIAL_PAGES - 1;

  const calculateZoom = useCallback((pdfWidth: number) => {
    const containerWidth = isMobile ? 355 : 670;
    return containerWidth / pdfWidth;
  }, [isMobile]);

  const scrollToThumbnail = (pageNumber: number) => {
    if (thumbnailsListRef && thumbnailsListRef?.current) {
      const thumbnails = thumbnailsListRef?.current.querySelectorAll('.thumbnail');
      if (thumbnails.length >= pageNumber) {
        const selectedThumbnail = thumbnails[pageNumber - 1];
        selectedThumbnail.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  // // Function to go to the next page

  // const goToNextPage = useCallback(async () => {
  //   if (!pdfDoc || pdfIsLoading) return;

  //   const nextPageNumber = currentPage + 1;

  //   if (nextPageNumber > totalPages) return; // Evita ir más allá de la última página

  //   // Cargar la página PADDING_PAGES adelante
  //   if (nextPageNumber <= totalPages - PADDING_PAGES) {
  //     const futurePageNumber = nextPageNumber + PADDING_PAGES;

  //     if (futurePageNumber <= totalPages) {
  //       const page = await pdfDoc.getPage(futurePageNumber);
  //       const canvasPage = await renderPdfPage(page, futurePageNumber);

  //       if (canvasPage) {
  //         setCanvasPages((oldState) => {
  //           const updatedCanvasPages = [...oldState.slice(1), canvasPage];
  //           return updatedCanvasPages;
  //         });
  //       }

      }
    }
  };

  const scrollToSelectedPage = (pageNumber) => {
    if (scrollRef && scrollRef?.current) {
      const pageElements = scrollRef.current.querySelectorAll('.pdf-page');
      if (pageElements.length > pageNumber) {
        const containerHeight = scrollRef.current.clientHeight;
        const itemHeight = scrollRef.current.scrollHeight / pageElements.length;
        const scrollTop = pageNumber * itemHeight - containerHeight + itemHeight + 45.5;
        scrollRef.current.scrollTop = scrollTop;
      }
    }
  };

  // Handlers para paginación
  const goToNextPage = useCallback(() => {
    if (currentPage < totalPages) {
      setCurrentPage(prev => prev + 1);
    }
  }, [currentPage, totalPages]);

  const goToPreviousPage = useCallback(() => {
    if (currentPage > 1) {
      setCurrentPage(prev => prev - 1);
    }
  }, [currentPage]);

  const goToPage = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const zoomIn = () => {
    setPdfZoom(prev => Number((prev + DEFAULT_ZOOM_DELTA).toFixed(2)));
  };

  const zoomOut = () => {
    setPdfZoom(prev => Number((prev - DEFAULT_ZOOM_DELTA).toFixed(2)));
  };

  const handleInputBlur = () => {
    if (inputRef?.current?.value) {
      const pageNumber = parseInt(inputRef.current.value, 10);
      goToPage(pageNumber);
      scrollToSelectedPage(pageNumber);
    }
  };

  // Renderizado de cada página del PDF
  const onLoadSuccess = async (pdf: pdfjs.PDFDocumentProxy) => {
    setTotalPages(pdf.numPages);

    // Obtener el tamaño de la primera página
    const page = await pdf.getPage(1);
    const { width, height } = page.getViewport({ scale: 1 });

    setCurrentPage(1);
    setPdfZoom((prevZoom) => (prevZoom === 1 && !isFullScreen ? calculateZoom(width) : prevZoom));
    setPdfWidth(width);
    setPdfHeight(height);
  };

  const enterFullScreen = async () => {
    try {
      await handle.enter();
      setIsFullScreen(true);
    } catch (error) {
      console.error("Error entering full-screen mode:", error);
    }
  };

  const exitFullScreen = async () => {
    try {
      await handle.exit();
      setIsFullScreen(false);
    } catch (error) {
      console.error("Error exiting full-screen mode:", error);
    }
  };

  // Efecto para desplazarse automáticamente a la miniatura seleccionada
  useEffect(() => {
    scrollToThumbnail(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = String(currentPage);
    }
  }, [inputRef, currentPage, totalPages]);

  return (
    <>
      {isPdfLibrarySupported ? (
        <Document
          file={fileUrl}
          onLoadError={(error) => setIsPdfLibrarySupported(false)}
          onSourceError={(error) => setPdfError('Error while loading document! ' + error.message)}
          onLoadProgress={({ loaded, total }) => setPdfLoadingPercent((loaded / total) * 100)}
          onLoadSuccess={onLoadSuccess}
          loading={<Spinner />}
          renderMode="canvas"
        >
          <FullScreen
            handle={handle}
            onChange={(state, handle) => {
              if (state) {
                setIsFullScreen(true);
              } else {
                setIsFullScreen(false);
              }
            }}
            className={`pdf-wrapper ${isFullScreen ? 'fullscreen-mode' : ''}`}
          >
            <MainWrapper className={`${isFullScreen ? 'fullscreen-mode' : ''}`}>
              {pdfLoadingPercent < 100 && (
                <LoaderWrapper>
                  <LoaderText>
                    {pdfLoadingPercent}
                  </LoaderText>
                </LoaderWrapper>
              )}

              {!!pdfError && (
                <LoaderWrapper>
                  <LoaderText>
                    {pdfError}
                  </LoaderText>
                </LoaderWrapper>
              )}

              <ThumbnailsWrapper style={{ opacity: !isFullScreen && !isMobile ? 1 : 0, zIndex: !isFullScreen && !isMobile ? 1 : -1, ...((isFullScreen || isMobile) && { width: 0 }) }}>
                <Scroll isMobile={isMobile} ref={thumbnailsListRef} width={pdfWidth} height={pdfHeight} className={`${isFullScreen ? 'fullscreen-mode' : ''}`}>
                  <ThumbnailsList >
                    <Thumbnail totalPages={totalPages} setCurrentPage={setCurrentPage} currentPage={currentPage} />
                  </ThumbnailsList>
                </Scroll>
              </ThumbnailsWrapper>

              <PreviewMainWrapper className={`${isFullScreen ? 'fullscreen-mode' : ''}`}>
                <PreviewWrapper isMobile={isMobile} ref={mainWrapRef} height={pdfHeight} width={pdfWidth} className={`${isFullScreen ? 'fullscreen-mode' : ''}`} >
                  <Page
                    pageNumber={currentPage}
                    scale={pdfZoom}
                    width={pdfWidth}
                    height={pdfHeight}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                </PreviewWrapper>

                <div style={{ height: 20 }} />

                <Toolbar ref={toolbarRef} className={`${isFullScreen ? 'fullscreen-mode' : ''}`} >
                  <Button
                    onClick={goToPreviousPage}
                    disabled={currentPage === 1}
                    skin="primary"
                  >
                    <Icon icon={GoBackIconSVG} color="#FFFFFF" />
                  </Button>

                  <Tools>
                    <Pagination>
                      {!isFullScreen && !isMobile && <span>Página</span>} <Input
                        ref={inputRef}
                        onBlur={handleInputBlur}
                      /> <span>/ {totalPages}</span>
                    </Pagination>

                    <Tools style={{ justifyContent: 'flex-end' }}>
                      {!blockDownloadFiles && (
                        <DownloadLink
                          target="_blank"
                          href={fileUrl}
                          key={key}
                        >
                          <Icon icon={downloadFileIcon} color="#000000" />
                          {/* {!isMobile && (<span style={{ marginLeft: "4px", fontWeight: 500 }}>Descargar</span>)} */}

                        </DownloadLink>
                      )}
                      <Button onClick={zoomIn}>
                        <img src={ZoomInSVG} alt="Zoom in" />
                      </Button>
                      <Button onClick={zoomOut}>
                        <img src={ZoomOutSVG} alt="Zoom out" />
                      </Button>

                      {isFullScreen ? (
                        <Button
                          onClick={exitFullScreen}
                        >
                          <Icon icon={CollapsePdfSVG} color="#000000" />
                        </Button>
                      ) : (
                        <Button
                          onClick={enterFullScreen}
                        >
                          <Icon icon={SizePdfSVG} color="#000000" />
                        </Button>
                      )}
                    </Tools>

                    <Button
                      onClick={goToNextPage}
                      disabled={currentPage === totalPages}
                      skin="primary"
                    >
                      <Icon icon={GoFowardIconSVG} color="#FFFFFF" />
                    </Button>
                  </Tools>
                </Toolbar>
              </PreviewMainWrapper>
            </MainWrapper>
          </FullScreen>
        </Document>

      ) : (
        <PdfLink
          target="_blank"
          href={fileUrl}
          key={`attached-file-${slugify(fileName)}`}
        >
          <Icon icon={fileUrl} color="white" />
          Clickea aqui para descargar: {fileName}
        </PdfLink>
      )}

      <div style={{ height: 50 }} />

    </>
  );
};

export default PdfViewer;
