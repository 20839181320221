import { Title } from "./styles";
import Carousel from "../../../../../components/Carousel";
import CardAticle from "../../../../Dashboard/Posts/components/CardArticle";
import { Separator } from "../../../../../components/StyledComponents";
import { Post } from "../../../../../types/post";
import { connect } from "react-redux";
import { Group, SessionInDto } from "../../../../../types";

interface ComponentProps {
  group: Group;
  lastActivities: {
    type: string;
    data: Post | SessionInDto;
  }[];
  lastActivitiesStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
}

const Component = ({
  group,
  lastActivities,
  lastActivitiesStates,
}: ComponentProps) => {

  if (
    !lastActivities ||
    lastActivities.filter(la => la.type === "post").length <= 0 ||
    !!lastActivitiesStates.loading
  ) {
    return null;
  }

  return (
    <>
      <Title>Últimos artículos</Title>

      <Separator height={{ desktop: "16px", mobile: "16px" }} />

      <Carousel
        items={lastActivities?.filter(item => item?.type === "post")}
        renderItem={(lastActivity, index) => (
          <CardAticle
            key={`last-activity-${index}`}
            post={lastActivity?.data as Post}
            groupInformation={group}
            showPinAction={false}
          />
        )}
        slidesToShow={3}
        scrollAmount={250}
        animationSpeed={400}
        showArrows={lastActivities?.filter(item => item?.type === "post")?.length >= 4}
      />

      <Separator height={{ desktop: '26px', mobile: '26px' }} />
    </>
  );
};

const state = ({ groupStore }) => {
  const { data: lastActivities, states: lastActivitiesStates } =
    groupStore.lastActivity;

  const { data: group } = groupStore.group;
  return {
    group,
    lastActivities,
    lastActivitiesStates,
  };
};

export default connect(state)(Component);
