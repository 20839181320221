import { useRef } from "react";
import Icon from '../Icon'
import IconChevronNext from '../../assets/icons/chevron-next.svg'
import IconChevronPrev from '../../assets/icons/chevron-prev.svg'
import { LeftButton, RightButton, HorizontalScrollWrapper, HorizontalScroll } from "./styles";

interface CarouselProps<T> {
  items: T[];
  renderItem: (item: T, index: number) => JSX.Element;
  slidesToShow?: number;
  scrollAmount?: number;
  animationSpeed?: number;
  showArrows?: boolean;
  cardWidth?: string
}

const Carousel = <T,>({
  items,
  renderItem,
  slidesToShow = 3,
  scrollAmount = 200,
  animationSpeed = 300,
  showArrows = true,
  cardWidth = undefined
}: CarouselProps<T>) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const smoothScroll = (direction: "left" | "right") => {
    if (!scrollRef.current) return;

    const start = scrollRef.current.scrollLeft;
    const end = direction === "left" ? start - scrollAmount : start + scrollAmount;
    const startTime = performance.now();

    const animateScroll = (currentTime: number) => {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / animationSpeed, 1);
      const easeInOutQuad = progress < 0.5 ? 2 * progress * progress : 1 - Math.pow(-2 * progress + 2, 2) / 2;

      if (scrollRef.current) {
        scrollRef.current.scrollLeft = start + (end - start) * easeInOutQuad;
      }

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  return (
    <div style={{ padding: '0px 10px' }}>
      <HorizontalScrollWrapper>
        {showArrows && (
          <LeftButton onClick={() => smoothScroll("left")}>
            <Icon size="16px" icon={IconChevronPrev} />
          </LeftButton>
        )}
        <HorizontalScroll ref={scrollRef} cardWidth={cardWidth}>
          {items?.map((item, index) => renderItem(item, index))}
        </HorizontalScroll>
        {showArrows && (
          <RightButton onClick={() => smoothScroll("right")}>
            <Icon size="16px" icon={IconChevronNext} />
          </RightButton>
        )}
      </HorizontalScrollWrapper>
    </div>
  );
};

export default Carousel;
