import { connect, useDispatch } from "react-redux";
import { Link as RrdLink, useLocation } from "react-router-dom";
import LinkUi from "../../../../components/Link";
import { Alert, Text, Center, CardsGrid, CategoryName, HorizontalScrollWrapper, HorizontalScroll } from "./styles";
import CallToAction from "../../components/CallToAction";
import InfinityScroll from '../../../../components/InfinityScroll'
import Dropdown from "../../../../components/DropdownBis";
import Button from "../../../../components/Button";
import Grid from "../../../../components/Grid";
import CardAticle from "../components/CardArticle";
import ReorderPinedPostsSidebar from './components/ReorderPinedPostsSidebar'
import PostCardSkeleton from "../components/PostCardSkeleton";
import NoPostFound from './components/NoPostFound'
import { useEffect, useMemo, useState } from "react";
import { getGroupById, resetGetGroup } from "../../../../store/actions/group";
import ModalConfirmDelete from "../components/ModalConfirmDelete";
import ModalShareLink from "../../components/ModalShareLink";
import {
  getFeaturedPosts,
  resetGetFeaturedPosts,
  getPosts,
  resetGetPosts,
  getScheduledPosts,
  resetGetScheduledPosts,
  resetSetFeaturedPost
} from "../../../../store/actions/post";
import slugify from "slugify";

// import FilterByCategory from "../components/FilterByCategory";
import { getAllCategories } from "../../../../store/actions/category";
import { Group } from "../../../../types";
import { GetPostsOutDto, Post } from "../../../../types/post";
import { SectionTitle, SectionDescription, Line } from "../../components/Statistics/styles";
import EmptyCard from "../../components/EmptyCard";
import { useLayout } from "../../../../providers/LayoutProvider";
import { showModal } from "../../../../store/actions/modal";

import postPinCheckedIcon from "../../../../assets/icons/post-pin-checked-icon.svg";

import fileIcon from "../../../../assets/icons/file.svg";

import freeContent from "../../../../assets/icons/free-content.svg";
import privateContent from "../../../../assets/icons/private-content.svg";

import postIcon from "../../../../assets/icons/post-icon.svg";
import categoryIcon from "../../../../assets/icons/category-icon.svg";
import sortIcon from "../../../../assets/icons/sort-icon.svg";
import Category from "../../Category";
import Snackbar from "../../../../components/Snackbar";
import { CategoryDto } from "../../../../types/category";
import Carousel from "../../../../components/Carousel";

const groupTypes = {
  membership: "membresía",
  experience: "taller",
  free_experience: "evento",
};

interface ComponentProps {
  group: Group;
  groupStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  posts: { pagination: { total: number }, data: Post[] };
  postsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  featuredPosts: Post[];
  featuredPostsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  setFeaturedPostStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  scheduledPosts: { pagination: { total: number }, data: Post[] };
  scheduledPostsStates: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  allCategories;
  allCategoriesState: {
    loading: boolean;
    success: boolean;
    error: string | boolean;
  };
  match;
  children?;
  isLoggedIn: boolean
}

const Component = ({
  group,
  groupStates,
  posts,
  postsStates,
  setFeaturedPostStates,
  featuredPosts,
  featuredPostsStates,
  scheduledPosts,
  scheduledPostsStates,
  match,
  allCategories,
  allCategoriesState,
  children,
  isLoggedIn
}: ComponentProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [unpinedItem, setUnpinedItem] = useState<Post>()
  const { layout, setLayout } = useLayout();
  const [idCategoryToOrder, setIdCategoryToOrder] = useState()
  const [showReorderPinedPosts, setShowReorderPinedPosts] = useState<boolean>(false)
  const [categoryManagerVisible, setCategoryManagerVisible] = useState<boolean>(false)
  const [createNewCategoryVisible, setCreateNewCategoryVisible] = useState<boolean>(false)
  const [reorderContentVisible, setReorderContentVisible] = useState<boolean>(false)
  const [idArticleToDelete, setIdArticleToDelete] = useState(null);

  // const [page, setPage] = useState(0)
  // const [thereAreItems, setThereAreItems] = useState(true)
  // const [mergedItems, setMergedItems] = useState<Post[]>([])
  // const [totalElements, setTotalElements] = useState<number>(0)
  // const [selectedCategory, setSelectedCategory] = useState<number | undefined>();

  // const fetchData = ({ page, content_categories }: { page: number, content_categories?: CategoryDto }) => {
  //   if (!postsStates.loading) {
  //     let payload: GetPostsOutDto = {
  //       pagination: { page, per_parge: 12 },
  //       filters: {
  //         group_id: group?.id,
  //         ...(content_categories ? { content_categories } : {}),
  //         published_at_null: false
  //       }
  //     };

  //     dispatch(getPosts(payload));
  //   }
  // }

  /** Start the new pagination with new filter */
  // const filterByCategory = (category) => {
  //   const newSelectedCategory = category === "show-all" ? undefined : category;
  //   setMergedItems([])
  //   setPage(1)
  //   setSelectedCategory(newSelectedCategory);
  //   fetchData({ page: 1, content_categories: newSelectedCategory });
  // }

  /** Paginate with the already selected filter */
  // const paginate = (page) => {
  //   fetchData({ page, content_categories: selectedCategory });
  // }

  const thereAreCategories = useMemo(() => {
    return !!allCategories && allCategories?.length > 0
  }, [allCategories])

  const thereArePosts = useMemo(() => {
    const foundPosts = allCategories?.map((categories) => categories?.last_posts).flat()
    return foundPosts?.length > 0
  }, [allCategories])

  const initPage = () => {
    if (!!group) {
      if (group?.group_type === 'experience' || group?.group_type === 'membership' || group?.group_type === 'free_experience') {
        dispatch(
          getScheduledPosts({
            pagination: { _sort: 'asc', _sort_by: 'schedule_publish_at', page: 1, per_parge: 12 },
            filters: { group_id: group?.id }
          })
        );
      }

      if (group?.group_type === 'experience' || group?.group_type === 'membership') {
        dispatch(
          getFeaturedPosts({
            pagination: { _sort: 'asc', _sort_by: 'pinned_order' },
            filters: { group_id: group?.id }
          })
        );
      }

      dispatch(getAllCategories({ group_id: group?.id, with_posts: true, only_showable_posts: false, posts_per_category: 8, sort_by: 'category_order:asc' }));
    }
  }

  useEffect(() => {
    const otherGroup = group && group?.id !== Number(match?.params?.idGroup);

    if (!!match.params) {
      if ((otherGroup || !group) && !groupStates.loading) {

        dispatch(getGroupById({ group_id: match.params.idGroup }));
      }
    }
  }, [match]);

  useEffect(() => {
    if (!!group) {
      initPage()
    }
  }, [group]);

  // useEffect(() => {
  //   if (!!posts?.data) {
  //     const newItemsObj = [...mergedItems, ...posts.data];
  //     setMergedItems(newItemsObj);
  //     setThereAreItems(!!posts && posts?.data?.length > 0 && newItemsObj?.length < posts?.pagination?.total);

  //     if (!selectedCategory) {
  //       setTotalElements(posts?.pagination?.total)
  //     }
  //   }
  // }, [posts])

  // useEffect(() => {
  //   const items = mergedItems.map((item) => {
  //     if (item.id === unpinedItem?.idPost) {
  //       return { ...item, pinned: unpinedItem?.pinned }
  //     } else {
  //       return item
  //     }
  //   })

  //   setMergedItems(items)
  // }, [unpinedItem])

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: group?.group_name,
        description: "Listado de artículos",
        back: `/dashboard/${group?.group_type === 'free_experience' ? 'community/events' : group?.group_type}/${group?.group_type === "community"
          ? ""
          : group?.id
          }`,
      },
      dashboard: {
        tabs: {
          visible: false,
        },
      },
      header: {
        visible: !!group,
        actions: [
          ...(group?.group_type !== 'community' ? [
            <Dropdown>
              <RrdLink
                to={`/group/${group?.slug}/library`}
                key={'go-to-preview-btn'}
                target="_blank"
              >
                Vista previa
              </RrdLink>

              <button
                disabled={group?.status === 'draft'}
                onClick={() => {
                  const link = `${process.env.REACT_APP_SITE_BASE_URL}/group/${group?.slug}`;
                  navigator.clipboard.writeText(link);
                  dispatch(showModal("modal-share-group-link"));
                }}
                key={'share-experience-btn'}
              >
                Compartir taller
              </button>
            </Dropdown>
          ] : []),
          < LinkUi
            options={{
              type: "filled",
              size: "lg",
              skin: "purple",
              native: true,
            }}
            href={
              group?.group_type === "free_experience"
                ? `/dashboard/community/events/${group?.id}/posts/new`
                : `/dashboard/${group?.group_type}/${group?.id}/posts/new`
            }
            style={group?.group_type === "community" ? { minWidth: "210px" } : {}}
          >
            {group?.group_type === "community"
              ? "Crear contenido gratuíto"
              : "Crear contenido"}
          </LinkUi >,
        ],
      },
    });
  }, [group]);

  useEffect(() => {
    if (!!group) {
      initPage()
    }

    return () => {
      dispatch(resetGetPosts())
      dispatch(resetGetScheduledPosts())
      dispatch(resetGetFeaturedPosts())
    }
  }, [])


  useEffect(() => {
    return () => {
      dispatch(resetGetPosts())
      dispatch(resetGetScheduledPosts())
      dispatch(resetGetFeaturedPosts())
    }
  }, [isLoggedIn])

  useEffect(() => {
    if (featuredPosts) {
      if (!!unpinedItem?.pinned) {
        featuredPosts.push(unpinedItem)
      } else {
        featuredPosts.filter(x => x.id !== unpinedItem?.id)
      }
    }
  }, [unpinedItem])

  // useEffect(() => {
  //   if (setFeaturedPostStates.success) {
  //     dispatch(
  //       getFeaturedPosts({
  //         pagination: { _sort: 'asc', _sort_by: 'pinned_date' },
  //         filters: { group_id: group?.id, published_at_null: false }
  //       })
  //     );
  //   }
  // }, [setFeaturedPostStates])

  return (
    <>
      {children}

      <div style={{ height: "36px" }}></div>

      {/* <Alert
        visible={true}
        title="Crea y comparte contenido con tus suscriptores ✏️"
        text={`Esta es la biblioteca de contenidos de tu ${groupTypes[group?.group_type]}. Aquí podrás organizar y crear material para compartir con los suscriptores.`}
        id="escribe-y-comparte-articulos-con-tus-suscriptores"
      /> */}

      {!!group &&
        group?.group_type !== "community" && (
          <>
            {group?.group_type !== "free_experience" && (
              <>
                <Grid.Container>
                  <Grid.Row>
                    <Grid.Col md={4}>
                      <CallToAction
                        title="Subir nuevo artículo"
                        link={`/dashboard/${group?.group_type}/${group?.id}/posts/new`}
                        options={{
                          size: 'small',
                          preffix: postIcon,
                        }}
                      />
                      <div style={{ height: 10 }} />
                    </Grid.Col>

                    <Grid.Col md={4}>
                      <CallToAction
                        title="Crear nueva categoría"
                        action={() => setCreateNewCategoryVisible(!createNewCategoryVisible)}
                        options={{
                          size: 'small',
                          preffix: categoryIcon,
                        }}
                      />
                      <div style={{ height: 10 }} />
                    </Grid.Col>

                    <Grid.Col md={4}>
                      <CallToAction
                        title="Administrar categorías"
                        action={() => setCategoryManagerVisible(!categoryManagerVisible)}
                        options={{
                          size: 'small',
                          preffix: sortIcon,
                        }}
                      />
                    </Grid.Col>
                    <div style={{ height: 10 }} />
                  </Grid.Row>
                </Grid.Container>

                <div style={{ height: "36px" }}></div>
              </>
            )}

            {group?.group_type !== "free_experience" && (
              <>
                <Grid.Container>
                  <Grid.Row>
                    <Grid.Col>
                      <Center horizontal="space" vertical="center">
                        <SectionTitle>Artículos pineados</SectionTitle>

                        <Button
                          onClick={() => {
                            setShowReorderPinedPosts(!showReorderPinedPosts)
                          }}
                          options={{
                            skin: 'purple',
                            type: 'link',
                            size: 'sm',
                          }}
                          style={{
                            textDecoration: 'none',
                            borderBottom: '0px solid',
                            fontSize: '16px',
                            color: 'rgb(108, 92, 231)',
                          }}
                        >
                          Reordenar contenido
                        </Button>
                      </Center>
                      <div style={{ height: 8 }} />
                      <SectionDescription>Estos articulos se mostraran en la pagina de inicio de tu membresia </SectionDescription>
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Container>

                <div style={{ height: 6 }} />

                {!featuredPosts && featuredPostsStates.loading && (
                  <CardsGrid>
                    <PostCardSkeleton />
                    <PostCardSkeleton />
                    <PostCardSkeleton />
                  </CardsGrid>
                )}

                {(!featuredPosts && !featuredPostsStates.loading) ||
                  ((featuredPosts?.length <= 0 ||
                    featuredPostsStates.error) &&
                    !featuredPostsStates.loading) ? (
                  <EmptyCard
                    title="No tienes artículos destacados"
                    text={`Destaca aquellos artíulos que consideres mayor relevancia para los suscriptores de tu ${groupTypes[group?.group_type]
                      }.`}
                    icon={postPinCheckedIcon}
                  />
                ) : (
                  <Grid.Container>
                    <Grid.Row>
                      <Grid.Col>
                        <Carousel
                          items={featuredPosts}
                          renderItem={(post: Post, index: number) => (
                            <CardAticle
                              key={slugify(post.title + index, {
                                lower: true,
                              })}
                              post={post}
                              onDelete={(idPost) =>
                                setIdArticleToDelete(idPost)
                              }
                              groupInformation={group}
                              onUnpinIt={setUnpinedItem}
                            />
                          )}
                          slidesToShow={4}
                          scrollAmount={250}
                          animationSpeed={400}
                          showArrows={featuredPosts?.length >= 4}
                          cardWidth="300px"
                        />
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Container>
                )}

                <div style={{ height: 26 }} />

                <Grid.Container>
                  <Grid.Row>
                    <Grid.Col>
                      <Line />
                    </Grid.Col>
                  </Grid.Row>
                </Grid.Container>

                <div style={{ height: 36 }} />
              </>
            )}

            {(!scheduledPosts && !scheduledPostsStates.loading) ||
              ((scheduledPosts?.data?.length > 0 ||
                scheduledPostsStates.error) && !scheduledPostsStates.loading) && (
                <>
                  <Grid.Container>
                    <Grid.Row>
                      <Grid.Col>
                        <SectionTitle>Artículos programados</SectionTitle>
                        <div style={{ height: 8 }} />
                        <SectionDescription>Estos articulos están programados para publicarse en un momento exacto en el futuro. Tus suscriptores no podrán acceder al contenido hasta que esten publicados. </SectionDescription>
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Container>

                  <div style={{ height: 6 }} />

                  {scheduledPostsStates.loading && (
                    <CardsGrid>
                      <PostCardSkeleton />
                      <PostCardSkeleton />
                      <PostCardSkeleton />
                    </CardsGrid>
                  )}

                  <Grid.Container>
                    <Grid.Row>
                      <Grid.Col>
                        <Carousel
                          items={scheduledPosts?.data}
                          renderItem={(post: Post, index: number) => (
                            <CardAticle
                              key={slugify(post.title + index, {
                                lower: true,
                              })}
                              post={post}
                              onDelete={(idPost) =>
                                setIdArticleToDelete(idPost)
                              }
                              groupInformation={group}
                            />
                          )}
                          slidesToShow={4}
                          scrollAmount={250}
                          animationSpeed={400}
                          showArrows={scheduledPosts?.data?.length >= 4}
                          cardWidth="300px"
                        />
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Container>

                  <div style={{ height: 26 }} />

                  <Grid.Container>
                    <Grid.Row>
                      <Grid.Col>
                        <Line />
                      </Grid.Col>
                    </Grid.Row>
                  </Grid.Container>

                  <div style={{ height: 36 }} />
                </>
              )}
          </>
        )}

      {!!group && (
        <>
          <Grid.Container>
            <Grid.Row>
              <Grid.Col>
                <SectionTitle>
                  {group?.group_type === "community" ? "Tus Lead Magnets" : "Tu Biblioteca"}
                </SectionTitle>
              </Grid.Col>
            </Grid.Row>
          </Grid.Container>

          <div style={{ height: 36 }} />

          {!thereAreCategories && !thereArePosts && allCategoriesState.loading && (
            <CardsGrid>
              <PostCardSkeleton />
              <PostCardSkeleton />
              <PostCardSkeleton />
            </CardsGrid>
          )}

          {(!thereAreCategories && !thereArePosts && !allCategoriesState.loading) ||
            (((!thereAreCategories && !thereArePosts) ||
              allCategoriesState.error) &&
              !allCategoriesState.loading) ? (
            <EmptyCard
              title="No tienes artículos en la biblioteca"
              text={`Crea artículos para compartir con tus seguidores ${groupTypes[group?.group_type]
                }.`}
              icon={postPinCheckedIcon}
            />
          ) : (
            <>
              {allCategories?.map((category, index) => {
                return (
                  <>
                    <Grid.Container>
                      {group?.group_type !== "community" && (
                        <Grid.Row>
                          <Grid.Col>
                            <Center horizontal="space" vertical="center">
                              <CategoryName>
                                {category?.name}
                              </CategoryName>

                              {category?.last_posts && category?.last_posts.length > 0 && (
                                <span style={{ display: 'flex', gap: 10, color: '#cfcfcf', }}>
                                  <RrdLink
                                    to={{
                                      pathname: `/group/${group?.id}/p/c/${category?.id || 'without_category'}`,
                                      state: { from: location?.pathname }
                                    }}
                                    style={{ textDecoration: 'none', borderBottom: '0px solid', fontSize: 16, color: "#6C5CE7" }}
                                  >
                                    Ver todos
                                  </RrdLink>

                                  {group?.library_template_view === "by_category" && category?.id !== 'without_category' && (
                                    <>
                                      |

                                      <Button
                                        onClick={() => {
                                          setIdCategoryToOrder(category?.id)
                                          setReorderContentVisible(!reorderContentVisible)
                                        }}
                                        options={{
                                          skin: 'purple',
                                          type: 'link',
                                          size: 'sm',
                                        }}
                                        style={{
                                          textDecoration: 'none',
                                          borderBottom: '0px solid',
                                          fontSize: '16px',
                                          color: 'rgb(108, 92, 231)',
                                        }}
                                      >
                                        Reordenar artículos
                                      </Button>
                                    </>
                                  )}
                                </span>
                              )}
                            </Center>
                          </Grid.Col>
                        </Grid.Row>
                      )}

                      <div style={{ height: 6 }} />

                      <Grid.Row>
                        <Grid.Col>
                          {category?.last_posts && category?.last_posts.length > 0 ? (
                            <Carousel
                              items={category?.last_posts}
                              renderItem={(post: Post, index: number) => (
                                <CardAticle
                                  key={slugify(post.title + index, { lower: true })}

                                  post={post}
                                  onDelete={(idPost) => setIdArticleToDelete(idPost)}
                                  groupInformation={group}
                                  onUnpinIt={setUnpinedItem}
                                />
                              )}
                              slidesToShow={4}
                              scrollAmount={250}
                              animationSpeed={400}
                              showArrows={category?.last_posts?.length >= 4}
                              cardWidth="300px"
                            />
                          ) : (
                            <NoPostFound groupType={group?.group_type} />
                          )}
                        </Grid.Col>
                      </Grid.Row>

                      <div style={{ height: 26 }} />
                    </Grid.Container>
                  </>
                )
              })}
            </>
          )}

        </>
      )}

      <Snackbar
        visible={(setFeaturedPostStates.success || !!setFeaturedPostStates.error || setFeaturedPostStates.loading)}
        options={{
          time: 500,
          type: setFeaturedPostStates.success ? "success" : (setFeaturedPostStates.loading ? "info" : "error"),
        }}
        onHide={() => dispatch(resetSetFeaturedPost())}
      >
        {setFeaturedPostStates.success && "Accion realizada exitosamente!"}
        {setFeaturedPostStates.error && "Error al destacar el artículo."}
      </Snackbar>
      <ModalShareLink />
      <ModalConfirmDelete idArticle={idArticleToDelete} />
      <Category showReorderContent={reorderContentVisible} idCategoryToOrder={idCategoryToOrder} showCategories={categoryManagerVisible} showCreateCategory={createNewCategoryVisible} onClose={() => { }} />
      <ReorderPinedPostsSidebar onClose={() => { }} isVisible={showReorderPinedPosts} />
    </>
  );
};

const state = ({ groupStore, userStore, postStore, categoryStore }) => {
  const { data: allCategories, states: allCategoriesState } = categoryStore.allCategories;
  const { isLoggedIn } = userStore
  const { data: userInformation } = userStore.information;
  const { data: group, states: groupStates } =
    groupStore.group;
  const { data: posts, states: postsStates } = postStore.listPosts;
  const { states: setFeaturedPostStates } = postStore.setFeaturedPost;
  const { data: featuredPosts, states: featuredPostsStates } =
    postStore.featuredPosts;
  const { data: scheduledPosts, states: scheduledPostsStates } =
    postStore.scheduledPosts;

  return {
    userInformation,
    isLoggedIn,
    group,
    groupStates,
    posts,
    postsStates,
    setFeaturedPostStates,
    allCategories,
    allCategoriesState,
    featuredPosts,
    featuredPostsStates,
    scheduledPosts,
    scheduledPostsStates
  };
};

export default connect(state)(Component);
