import styled from "styled-components";

export const Title = styled.h2`
  font-weight: 500;
  line-height: normal;
  font-size: 28px;
  margin: 0px;
  text-align: center;
`;

export const Text = styled.div`
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
  margin: 0px;
  text-align: center;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`


export const SmallMessage = styled('p')`
  text-align: center;
  margin: 0px;
  font-size: 16px;
`