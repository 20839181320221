import Input from "../../../components/FormikForm/Input";
import Submit from "../../../components/FormikForm/Submit";
import circleCheckImg from '../../../assets/circle-check.svg'
import alertTriangleImg from '../../../assets/alert-triangle.svg'
import { initialValues, schema } from "../../../constants/forms/change-password";
import { Formik } from "formik";
import { Title, Text, Content, SmallMessage } from "./styles";
import Link from '../../../components/Link'
import { connect, useDispatch } from "react-redux";
import Snackbar from "../../../components/Snackbar";
import { changePassword } from "../../../store/actions/auth";
import { getUrlParamByName } from "../../../helpers/get-url-param";
import { useEffect, useMemo, useState } from "react";
import Image from "../../../components/Image";
import Grid from "../../../components/Grid";
import { useTheme } from "styled-components";

interface ComponentProps {
  changePasswordData;
  changePasswordStates;
}

const Component = ({ changePasswordData, changePasswordStates }: ComponentProps) => {
  const theme = useTheme() as { logo: { small: string, big: string } };
  const dispatch = useDispatch();
  const [countdown, setCountdown] = useState(10);
  const [email, setEmail] = useState<string>('')
  const [formSubmmited, setFormSubmmited] = useState(false);

  const token = getUrlParamByName('token');

  const onSubmit = async ({ values, actions }) => {
    const { newPassword } = values
    if (token) {
      dispatch(changePassword({
        newPassword,
        token
      }));
    }
  };

  const isTokenExpired = useMemo(() => {
    try {
      if (token) {
        // Los tokens JWT tienen tres partes: header.payload.signature
        const payloadBase64 = token.split(".")[1]; // Extraemos el payload (segunda parte)
        const payloadJSON = JSON.parse(atob(payloadBase64.replace(/-/g, "+").replace(/_/g, "/"))); // Decodificamos

        if (!!payloadJSON.email) {
          setEmail(payloadJSON.email)
        }

        if (!payloadJSON.exp) {
          console.log("⚠️ El token no tiene fecha de expiración.");
          return true;
        }

        const currentTime = Math.floor(Date.now() / 1000); // Tiempo actual en segundos
        return payloadJSON.exp < currentTime; // Devuelve true si el token expiró
      }
    } catch (error: any) {
      console.error("❌ Error al decodificar el token:", error.message);
      return true;
    }
  }, [token])

  useEffect(() => {
    if (changePasswordStates.success || isTokenExpired) {
      if (countdown > 0) {
        const timer = setTimeout(() => {
          setCountdown(countdown - 1);
        }, 1000);

        return () => clearTimeout(timer);
      } else {
        window.location.href = isTokenExpired ? `/auth/password-recovery` : `/subscriber/signin`;
      }
    }
  }, [countdown, changePasswordStates, isTokenExpired]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>

      <Grid.Container style={{ maxWidth: "430px" }}>
        <Grid.Row>
          <Grid.Col>
            <Content>
              <Image
                src={theme?.logo?.big}
                style={{ display: 'block' }}
                width="128px"
                height="auto"
                className="logo-desktop"
                alt="Klouser"
                lazy
              />
            </Content>
          </Grid.Col>
        </Grid.Row>
        <div style={{ height: 34 }} />

        {isTokenExpired ?
          <Grid.Row>
            <Grid.Col>
              <Content>
                <Image src={alertTriangleImg} lazy />
                <div style={{ height: 22 }} />
                <Text>El enlace al que intentas acceder ha expirado. Para recuperar tu contraseña, deberás verificar tu correo electrónico nuevamente.</Text>
                <div style={{ height: 22 }} />
                <Link
                  href="/auth/password-recovery"
                  options={{
                    type: 'filled',
                    size: 'lg',
                    skin: 'purple'
                  }}
                >
                  Recuperar contraseña
                </Link>
                <div style={{ height: 22 }} />
                <SmallMessage>Toca "Recuperar contraseña" o aguarda ({countdown} seg.)<br />y seras redirigido </SmallMessage>
              </Content>
            </Grid.Col>
          </Grid.Row>
          :
          <>
            {!changePasswordStates.success ? <>
              <Grid.Row>
                <Grid.Col>
                  <Title>Introduce tu nueva contraseña</Title>
                </Grid.Col>
              </Grid.Row>
              <div style={{ height: 22 }} />
              <Grid.Row>
                <Grid.Col>
                  <Text>
                    Ingresa una nueva contraseña para {email}
                  </Text>
                </Grid.Col>
              </Grid.Row>
              <div style={{ height: 22 }} />
              <Grid.Row>
                <Grid.Col>
                  <Content>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={(values, actions) => {
                        if (onSubmit) onSubmit({ values, actions });
                      }}
                      validateOnChange={false}
                      validateOnBlur={formSubmmited}
                      validationSchema={schema}
                      enableReinitialize
                    >
                      {({ handleSubmit, ...rest }) => {
                        return (
                          <form
                            style={{ width: '100%' }}
                            className="theme-form"
                            onSubmit={(event) => {
                              setFormSubmmited(true);
                              handleSubmit(event);
                            }}
                            id="change-password-form"
                          >
                            <Input
                              name="newPassword"
                              error={rest.errors["newPassword"]}
                              touched={rest.touched["newPassword"]}
                              value={rest.values["newPassword"]}
                              onChange={rest.handleChange}
                              onBlur={rest.handleBlur}
                              placeholder="Nueva contraseña"
                              type="password"
                              options={{
                                label: "Nueva contraseña",
                                marginBottom: 22,
                              }}
                            />

                            <Input
                              name="confirmPassword"
                              error={rest.errors["confirmPassword"]}
                              touched={rest.touched["confirmPassword"]}
                              value={rest.values["confirmPassword"]}
                              onChange={rest.handleChange}
                              onBlur={rest.handleBlur}
                              placeholder="Repite la nueva contraseña"
                              type="password"
                              options={{
                                label: "Repite la nueva contraseña",
                                marginBottom: 0,
                              }}
                            />
                          </form>
                        );
                      }}
                    </Formik>
                    <div style={{ height: 22 }} />
                    <div style={{ display: 'flex', gap: 16 }}>
                      <Submit
                        isSubmmiting={changePasswordStates.loading}
                        options={{
                          type: "filled",
                          size: "lg",
                          skin: "purple",
                        }}
                        form="change-password-form"
                      >
                        Cambiar contraseña
                      </Submit>
                    </div>
                  </Content>
                </Grid.Col>
              </Grid.Row>
            </> : <>
              <Grid.Row>
                <Grid.Col>
                  <Content>
                    <Image src={circleCheckImg} lazy />
                    <div style={{ height: 22 }} />
                    <Text>Tu contraseña se restablecio correctamente</Text>
                    <div style={{ height: 22 }} />
                    <Link
                      href="/subscriber/signin"
                      options={{
                        type: 'filled',
                        size: 'lg',
                        skin: 'purple'
                      }}
                    >
                      Ingresar
                    </Link>
                    <div style={{ height: 22 }} />
                    <SmallMessage>Toca "Ingresar" o aguarda ({countdown} seg.)<br />y seras redirigido </SmallMessage>
                  </Content>
                </Grid.Col>
              </Grid.Row>
            </>}
          </>
        }
      </Grid.Container>

      <Snackbar
        visible={changePasswordStates.error}
        options={{ type: "error", position: "left" }}
      >
        Error al cambiar la contraseña
      </Snackbar>
    </div>
  );
};

const state = ({ authStore }) => {
  const { data: changePasswordData, states: changePasswordStates } = authStore.changePassword;

  return {
    changePasswordData,
    changePasswordStates,
  };
};

export default connect(state)(Component);
