import Input from "../../../../../components/FormikForm/Input";
import RichTextEditor from "../../../../../components/FormikForm/RichTextEditor";
import File from "../../../../../components/FormikForm/File";
import { connect, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getVimeoVideoIdFromUrl, getYouTubeUrl, isVimeoUrl, isYoutubeUrl } from "../../../../../helpers/you-tube";
import { deleteFile } from "../../../../../store/actions/post";
import Switch from "../../../../../components/FormikForm/Switch";
import { Center } from "../../../../../components/StyledComponents";
import VimeoVideo from "../VimeoVideo";

const Component = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  groupInformation,
  ...rest
}) => {
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!!values["attached_url"] && isYoutubeUrl(values["attached_url"])) {
      setEmbedUrl(getYouTubeUrl(values["attached_url"]))
    } else if (!!values["attached_url"] && isVimeoUrl(values["attached_url"])) {
      setEmbedUrl(values["attached_url"])
    } else {
      setEmbedUrl(null);
    }
  }, [values]);

  return (
    <>
      <Input
        name="title"
        error={errors["title"]}
        touched={touched["title"]}
        value={values["title"]}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Ingresa un título"
        options={{
          label: "Título del contenido",
          marginBottom: 40,
        }}
      />

      <File
        name="cover_pic"
        error={errors["cover_pic"]}
        touched={touched["cover_pic"]}
        value={values["cover_pic"]}
        rules={{
          maxFiles: 1,
          typeFiles: ["image"],
          maxSize: 5 * 1024 * 1024
        }}
        onRemoveItem={(event, file) => {
          if (file?.id) {
            dispatch(deleteFile({ idFile: file?.id }));
          }
        }}
        options={{
          label: "Adjuntar portada (opcional)",
          explainText:
            "Puedes agregar una imagen de portada para tu artículo. Formatos válidos: JPG o PNG de hasta un maximo de 5Mb. El tamaño ideal es de 333px (ancho) x 174px (alto), pero si subes de otro tamaño funcionará!",
          marginBottom: 40,
          showLoadFileCta: false,
        }}
      />

      <RichTextEditor
        name="body"
        error={errors["body"]}
        touched={touched["body"]}
        value={values["body"]}
        placeholder="Todos tenemos..."
        max={10000}
        onBlur={handleBlur}
        onChange={({ isCountValid }) => {
          if (!isCountValid) {
            setFieldError(
              "body",
              "Superaste la cantidad de caracteres máximos permitidos"
            );
          } else {
            setFieldTouched("body", false, false);
          }
        }}
        options={{
          label: "Redactar contenido",
          marginBottom: 40,
        }}
      />

      <File
        name="attached_files"
        error={errors["attached_files"]}
        touched={touched["attached_files"]}
        value={values["attached_files"]}
        rules={{
          maxFiles: 3,
          typeFiles: ["image", "audio", "video", "other"],
        }}
        onRemoveItem={(event, file) => {
          if (file?.id) {
            //dispatch(deleteFile({ idFile: file?.id }));
          }
        }}
        options={{
          label: "Adjuntar contenido (opcional)",
          explainText:
            "Puedes agregar imágenes, audios, pdf y videos en tu artículo de hasta mâximo 1GB.",
          marginBottom: 20,
          showLoadFileCta: false,
          helperText: "Puedes adjuntar un máximo de 3 archivos. Si estás subiendo videos pesados ten en cuenta que puede demorar varios minutos hasta que se suba."
        }}
      />

      <Center horizontal="left" vertical="center" gap={10} style={{ width: 'auto', height: 'auto', marginBottom: "30px", fontSize: "15px" }}>
        <Switch
          name="block_files_download"
          error={errors["block_files_download"]}
          touched={touched["block_files_download"]}
          value={values["block_files_download"]}
          onChange={handleChange}
          onBlur={handleBlur}
          items={[
            {
              label: "Impedir la descarga de los archivos",
              value: "true",
            },
          ]}
          options={{
            helperText: "Al activar esta opción los suscriptores no podrán descargarse los archivos que subes en este articulo"
          }}
        />
      </Center>

      <Input
        name="attached_url"
        error={errors["attached_url"]}
        touched={touched["attached_url"]}
        value={values["attached_url"]}
        onChange={(event) => {
          handleChange(event);
          if (isYoutubeUrl(event.target.value)) {
            setEmbedUrl(getYouTubeUrl(event.target.value));
          } else if (isVimeoUrl(event.target.value)) {
            setEmbedUrl(getVimeoVideoIdFromUrl(event.target.value));
          } else {
            setEmbedUrl(null);
          }
        }}
        onBlur={handleBlur}
        placeholder="http://"
        options={{
          label: "Agregar link relacionado (opcional)",
          explainText:
            "Embebe directamente videos de Youtube, Vimeo, Instagram o simplemente comparte cualquier link que quieras que tus suscriptores abran!.",
          marginBottom: !!embedUrl ? 16 : 40,
        }}
      />

      {!!embedUrl && isYoutubeUrl(embedUrl) && (
        <>
          <iframe
            src={embedUrl}
            id="myIframe"
            width="320"
            height="200"
            frameBorder="0"
            allowFullScreen
          ></iframe>
          <div style={{ height: "40px" }} />
        </>
      )}

      {!!embedUrl && isVimeoUrl(embedUrl) && (
        <>
          <VimeoVideo controls={false} videoId={getVimeoVideoIdFromUrl(embedUrl)} showLink={false} isPreview={false} />
          <div style={{ height: "40px" }} />
        </>
      )}
    </>
  );
};

const state = ({ groupStore }) => {
  const { data: groupInformation } = groupStore.group;

  return {
    groupInformation,
  };
};

export default connect(state)(Component);
