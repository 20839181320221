import Modal from "../../../../../components/Modal";
import Button from "../../../../../components/Button";
import Submit from "../../../../../components/FormikForm/Submit";
import {
    Title,
    Text,
    AlignEnd,
    Error,
    LossBenefit,
    StyledSlide,
    StyledUl,
    AlignCenter,
} from "./styles";
import chevronNext from "../../../../../assets/icons/chevron-next.svg";
import { connect, useDispatch } from "react-redux";
import { hideModal } from "../../../../../store/actions/modal";
import {
    cancelSubscription,
    resetCancelSubscription,
} from "../../../../../store/actions/subscription-admin";
import { Subscription, User } from "../../../../../types";
import remove from "../../../../../assets/remove.svg";
import Image from "../../../../../components/Image";
import { Formik } from "formik";
import { useState, useRef, useEffect } from "react";
import Textarea from "../../../../../components/FormikForm/TextArea";
import Icon from "../../../../../components/Icon";
import DeleteCircleCross from "../../../../../assets/icons/delete-circle-cross.svg";
import {
    schema,
    initialValues,
} from "../../../../../constants/forms/subscription-admin/cancel-subscription";
import constants from "../../../../../constants";
import Link from "../../../../../components/Link";
import { getUserSubscriptions } from "../../../../../store/actions/user";
import dateHelpers from "../../../../../helpers/dates-hanlder";
import { CarouselProvider, Slider } from "pure-react-carousel";
import { useWindowSize } from "../../../../../hooks/useWindowSize";

interface ComponentProps {
    subscription: Subscription | null;
    cancelSubscriptionStates: {
        loading: boolean;
        success: boolean;
        error: string | boolean;
    };
    userInformation: User;
}

const Component = ({
    subscription,
    cancelSubscriptionStates,
    userInformation,
}: ComponentProps) => {
    const dispatch = useDispatch();
    const { isMobile } = useWindowSize();
    const [formSubmmited, setFormSubmmited] = useState(false);
    const { whatsapp } = constants;
    const [index, setIndex] = useState(0);
    const totalSlides = 2;
    const [message, setMessage] = useState(whatsapp.message);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [disableButton, setDisableButton] = useState(false);

    const messageFieldRef = useRef<HTMLTextAreaElement>(null);

    const handleNextButtonPressed = () => {
        if (index === totalSlides - 1) {
            return;
        } else {
            setIndex(index + 1);
        }
    };

    const onSubmit = (values, actions) => {
        setDisableButton(true)
        dispatch(cancelSubscription({ ...values, id: subscription?.id }));
    };

    const onModalClose = () => {
        dispatch(hideModal());
        setIndex(0);
    };

    useEffect(() => {
        setName(userInformation?.name);
        setEmail(userInformation?.email);
        setMessage(whatsapp.admin_subscriptions);
    }, [userInformation]);

    useEffect(() => {
        if (cancelSubscriptionStates?.success) {
            dispatch(resetCancelSubscription());
            dispatch(getUserSubscriptions());
            dispatch(hideModal());
            setDisableButton(false)
        }
    }, [cancelSubscriptionStates]);

    return (
        <Modal name="confirm-cancellation-modal" width="550">
            {cancelSubscriptionStates.success ? (
                <>
                    <div style={{ textAlign: "center" }}>
                        <Image src={remove} width="102px" height="117px" alt={remove} />
                    </div>
                    <Text>
                        <strong style={{ textAlign: "center", display: "block" }}>
                            ¡Tu suscripción fue cancelada con éxito! <br />
                            <br />
                            {!!subscription?.current_term_end &&
                                dateHelpers.isFutureDate(
                                    dateHelpers.timestampToDate(subscription?.current_term_end)
                                ) &&
                                `Puedes disfrutar de tu acceso premium hasta el ${dateHelpers.timestampToDate(
                                    subscription?.current_term_end,
                                    "DD/MM/YYYY"
                                )}`}
                        </strong>
                    </Text>
                </>
            ) : (
                <CarouselProvider
                    currentSlide={index}
                    naturalSlideWidth={isMobile ? 150 : 305}
                    naturalSlideHeight={isMobile ? 150 : 150}
                    totalSlides={totalSlides}
                    isIntrinsicHeight={true}
                    dragEnabled={false}
                    touchEnabled={false}
                    infinite={false}
                    disableKeyboard={true} // Disable keyboard navigation
                >
                    <Slider>
                        <StyledSlide index={0}>
                            <Title>⚠️ Atención: Estás por cancelar tu suscripción </Title>
                            <div style={{ height: "15px" }}></div>
                            <Text>
                                Membresia: <strong>{subscription?.group?.group_name}</strong>
                            </Text>

                            <Text>
                                Ten en cuenta que al cancelar la suscripción{" "}
                                <strong>perderas</strong> acceso a:
                            </Text>
                            <StyledUl>
                                <LossBenefit>
                                    <Icon
                                        icon={DeleteCircleCross}
                                        size={"15px"}
                                        color="#990000"
                                    />
                                    Todos los Artículos Exclusivos
                                </LossBenefit>

                                <LossBenefit>
                                    <Icon
                                        icon={DeleteCircleCross}
                                        size={"15px"}
                                        color="#990000"
                                    />
                                    Todos los Encuentros en Vivo
                                </LossBenefit>

                                <LossBenefit>
                                    <Icon
                                        icon={DeleteCircleCross}
                                        size={"15px"}
                                        color="#990000"
                                    />
                                    Todas las Grabaciones
                                </LossBenefit>

                                <LossBenefit>
                                    <Icon
                                        icon={DeleteCircleCross}
                                        size={"15px"}
                                        color="#990000"
                                    />
                                    Los Canales y Grupos de Chat
                                </LossBenefit>

                                <LossBenefit>
                                    <Icon
                                        icon={DeleteCircleCross}
                                        size={"15px"}
                                        color="#990000"
                                    />
                                    Y muchos beneficios más...!
                                </LossBenefit>
                            </StyledUl>

                            <AlignCenter>
                                <Button
                                    onClick={handleNextButtonPressed}
                                    //disabled={newTelegramGroupStates.loading}
                                    //loading={newTelegramGroupStates.loading}
                                    options={{
                                        type: "filled",
                                        size: "lg",
                                        skin: "purple",
                                    }}
                                >
                                    Entiendo <Icon icon={chevronNext} color="white" size="14px" />
                                </Button>
                            </AlignCenter>
                        </StyledSlide>

                        <StyledSlide index={1}>
                            <Formik
                                initialValues={{
                                    ...initialValues,
                                }}
                                validationSchema={schema}
                                onSubmit={(values, actions) => {
                                    if (onSubmit) onSubmit(values, actions);
                                }}
                                validateOnChange={false}
                                validateOnBlur={formSubmmited}
                                enableReinitialize
                            >
                                {({
                                    dirty,
                                    touched,
                                    errors,
                                    values,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    setFieldValue,
                                }) => {
                                    return (
                                        <form
                                            className="theme-form"
                                            onSubmit={(event) => {
                                                setFormSubmmited(true);
                                                handleSubmit(event);
                                            }}
                                            id="cancel-subscription-form"
                                        >
                                            <Title>
                                                ✍🏻 {!isMobile && <span>Antes de confirmar:</span>} ¿Me
                                                ayudas a mejorar?
                                            </Title>
                                            <div style={{ height: "20px" }} />
                                            <Textarea
                                                name="cancellation_reason"
                                                placeholder="He decidido cancelar mi suscripción ya que..."
                                                touched={touched["cancellation_reason"]}
                                                error={errors["cancellation_reason"]}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                forwardRef={messageFieldRef}
                                                options={{
                                                    label:
                                                        "¿Cuál es el motivo por el qué cancelas la suscripción? (es anónimo!)",
                                                    marginBottom: 20,
                                                    minHeight: 130,
                                                }}
                                            />

                                            <div style={{ height: "20px" }} />

                                            <AlignEnd>
                                                <Button
                                                    options={{
                                                        type: "outline",
                                                        skin: "purple",
                                                        size: "lg",
                                                    }}
                                                    style={!isMobile ? { marginRight: "16px" } : {}}
                                                    onClick={() => onModalClose()}
                                                    disabled={cancelSubscriptionStates.loading}
                                                >
                                                    {"< "} Volver
                                                </Button>
                                                <Submit
                                                    form="cancel-subscription-form"
                                                    disabled={cancelSubscriptionStates.loading || !!disableButton}
                                                    isSubmmiting={cancelSubscriptionStates.loading}
                                                    options={{
                                                        type: "filled",
                                                        skin: "purple",
                                                        size: "lg",
                                                    }}
                                                >
                                                    Darme de baja
                                                </Submit>
                                            </AlignEnd>

                                            {cancelSubscriptionStates.error && (
                                                <Error>
                                                    Se ha producido un error inesperado.
                                                    <Link
                                                        href={`${constants.whatsapp.url}?phone=${whatsapp.number
                                                            }&text=${message
                                                                .replace("{name}", name)
                                                                .replace("{email}", email)}`}
                                                        target={"_blank"}
                                                        options={{
                                                            type: "link",
                                                            size: "sm",
                                                            skin: "lightBlue",
                                                            native: false,
                                                        }}
                                                    >
                                                        Contacta a nuestro equipo de soporte
                                                    </Link>
                                                </Error>
                                            )}
                                        </form>
                                    );
                                }}
                            </Formik>
                        </StyledSlide>
                    </Slider>
                </CarouselProvider>
            )}
        </Modal>
    );
};

const state = ({ userStore, subscriptionAdminStore }) => {
    const { data: userInformation } = userStore.information;
    const { states: cancelSubscriptionStates } =
        subscriptionAdminStore.cancelSubscription;

    return {
        cancelSubscriptionStates,
        userInformation,
    };
};

export default connect(state)(Component);
