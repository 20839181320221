import Helmet from "react-helmet";
import { connect, useDispatch } from "react-redux";
import Link from '../../../components/Link'
import Tabs from "../components/Tabs";
import {
  Title,
  RainbowSectionName,
  Separator,
  Center
} from "../../../components/StyledComponents";
import { CategoryDescription } from './styles'
import NoSubscribed from "../components/NoSubscribed";
import { Page } from "../components/styles";
import Grid from "../../../components/Grid";
import { useEffect, useMemo, useState } from "react";
import { getGroupBySlug, resetGetGroup } from "../../../store/actions/group";
import { resetGetPost } from "../../../store/actions/post";
import { GetPostsOutDto, Post } from "../../../types/post";
import Loader from "../../../components/LogoLoader";
import { Group, Subscription, User } from "../../../types";
import Content from "./components/Content";
import { Layout, useLayout } from "../../../providers/LayoutProvider";
import GoToTelegram from "../components/GoToTelegram";
import { isSubscribed } from "../../../helpers/isSubscribed";
import { Redirect } from "react-router-dom";
import { isCongreso } from "../../../helpers/isCongreso";
import { isCommunity } from "../../../helpers/isCommunity";
import { isOwnGroup } from "../../../helpers/isOwnGroup";
import TelegramBanner from "../ActivitySummary/components/TelegramBanner";
import { getFreeSubscriptions } from "../../../helpers/getFreeSubscriptions";
import { CategoryDto } from "../../../types/category";
import { getCategory } from "../../../store/actions/category";
import { getUserPreferences } from "../../../store/actions/preferences";
import { getUserSubscriptions } from "../../../store/actions/user";
import { showPreference } from "../../../helpers/preferenceVisible";

const groupTypes = {
  membership: "Membresía",
  experience: "Taller",
  free_experience: "Evento Gratuito",
  community: "Comunidad",
};

interface ComponentProps {
  isLoggedIn: boolean;
  subscriptions: Subscription[];
  user: User;
  group: Group;
  groupStates: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  category: CategoryDto;
  categoryState: {
    loading: boolean;
    error: string | boolean;
    success: boolean;
  };
  match;
  preferences,
  preferencesStates
}

const Component = ({
  isLoggedIn,
  subscriptions,
  group,
  match,
  groupStates,
  user,
  category,
  categoryState,
  preferences,
  preferencesStates
}: ComponentProps) => {
  const dispatch = useDispatch();
  const { setLayout, layout } = useLayout();
  const [goToSurvey, setGoTuSurvey] = useState<boolean>(false)

  const redirect = useMemo(() => {
    if (!!group) {
      return groupStates?.error || isCongreso(group?.id) || isCommunity(group?.group_type) || (!isOwnGroup({ group, expertId: user?.expert?.id }) && group?.status === 'draft')
    }

    return false
  }, [group, user])

  const layoutData = useMemo(
    (): Layout => ({
      ...layout,
      skin: "plain",
      footer: {
        visible: false,
      },
      header: {
        visible: true,
      },
      toolbar: {
        visible: isOwnGroup({ group, expertId: user?.expert?.id })
      },
      body: {
        visible: true,
      },
    }),
    [layout, subscriptions, group, user]
  );

  useEffect(() => {
    setLayout(layoutData);
  }, [subscriptions, group, user]);

  useEffect(() => {
    if (match?.params?.category && (!category?.id || (category?.id !== Number(match?.params?.category)))) {
      const idCategory = match?.params?.category;
      dispatch(getCategory({ id: idCategory, group_id: match?.params?.group }));
    }
  }, [match]);

  useEffect(() => {
    if (category && (!group?.slug || (group?.slug !== category?.group?.slug))) {
      dispatch(resetGetPost());
      match?.params && !groupStates.loading && dispatch(getGroupBySlug(category?.group?.slug));
    }
  }, [category])

  useEffect(() => {
    dispatch(getUserPreferences())
    dispatch(getUserSubscriptions());

    return () => {
      dispatch(resetGetGroup())
    }
  }, [])

  useEffect(() => {
    if (group?.survey) {
      setGoTuSurvey(
        showPreference({
          preferences,
          preferencesStates,
          id: `survey-${group?.survey}`,
        })
      )
    }
  }, [preferences, preferencesStates, group])

  useEffect(() => {
    setLayout({
      ...layout,
      page: {
        title: category?.name,
        back: `/group/${category?.group?.slug}/library/`,
        showBack: true
      },
    });
  }, [category]);

  if (!group || !category) {
    return <Loader />;
  }

  if (goToSurvey && !isOwnGroup({ group, expertId: user?.expert?.id }) && isSubscribed(subscriptions, group?.id)) {
    return <Redirect to={`/group/${group.slug}/survey`} />;
  }


  if (redirect) {
    return <Redirect to="/404" />;
  }

  return (
    <>
      <Page>
        <Helmet>
          <title>
            {`${group?.group_name} - Categoría: ${category?.name}`}
          </title>
          <meta name="description" content={`${group?.group_name} - ${category?.name}`} />
        </Helmet>



        <Grid.Container style={{ position: 'relative', zIndex: 1 }}>
          <div style={{ height: 36 }} />

          <Grid.Row>
            <Grid.Col lg={8} offset={{ lg: 2 }}>
              <CategoryDescription>{category.description}</CategoryDescription>
              {/* {isOwnGroup({ group, expertId: user?.expert?.id }) && (
                <>
                  <div style={{ height: 16 }} />
                  <Center horizontal="center" vertical="center">
                    <Link
                      href={
                        group?.group_type === "free_experience"
                          ? `/dashboard/community/events/${group?.id}/posts/new`
                          : `/dashboard/${group?.group_type}/${group?.id}/posts/new`
                      }
                      options={{
                        skin: 'purple',
                        size: 'lg',
                        type: 'filled',
                      }}
                    >
                      Agregar Articulo
                    </Link>
                  </Center>
                </>
              )} */}
            </Grid.Col>
          </Grid.Row>

          <div style={{ height: 36 }} />

          <Grid.Row>
            <Grid.Col lg={10} offset={{ lg: 1 }}>
              <Content category={category} />
              <Separator height={{ desktop: "32px", mobile: "16px" }} />
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </Page>

      <NoSubscribed />
    </>
  );
};

const state = ({ userStore, postStore, groupStore, categoryStore, preferencesStore }) => {
  const { data: category, states: categoryState } =
    categoryStore.singleCategory;
  const { isLoggedIn } = userStore;
  const { data: subscriptions, states: subscriptionsStates } =
    userStore.subscriptions;
  const { data: user, states: userStates } =
    userStore.information;
  const { data: posts, states: postsStates } = postStore.listPosts;
  const { data: group, states: groupStates } = groupStore.group;
  const { data: preferences, states: preferencesStates } =
    preferencesStore.preferences;

  return {
    subscriptions,
    subscriptionsStates,
    user,
    userStates,
    posts,
    postsStates,
    category,
    categoryState,
    group,
    groupStates,
    isLoggedIn,
    preferences,
    preferencesStates
  };
};

export default connect(state)(Component);
