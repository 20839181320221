import { put, all, call, select, takeLatest } from "redux-saga/effects";
import services from "../../services";

import {
  NEW_FILE,
  NEW_FILE_SUCCESS,
  NEW_FILE_ERROR,
  RESET_NEW_FILE,
} from "../types/storage/new";

import {
  DELETE_FILE,
  DELETE_FILE_SUCCESS,
  DELETE_FILE_ERROR,
  RESET_DELETE_FILE,
} from "../types/storage/delete";

import {
  GET_FILE,
  GET_FILE_SUCCESS,
  GET_FILE_ERROR,
  RESET_GET_FILE,
} from "../types/storage/one";

function* newFile({ payload }): any {
  try {
    const { response, error } = yield call(services.storage.saveFile, payload);

    if (error) {
      yield put({ type: NEW_FILE_ERROR, payload: error });
    } else {
      yield all([
        put({ type: NEW_FILE_SUCCESS, payload: response }),
      ]);
    }
  } catch {
    yield put({
      type: NEW_FILE_ERROR,
      payload: "Error al crear un nueva encuesta.",
    });
  }
}

function* getFile({ payload }) {
  try {
    const { response, error } = yield call(services.storage.getFile, payload);

    if (error) {
      yield put({ type: GET_FILE_ERROR, payload: error });
    } else {
      yield put({ type: GET_FILE_SUCCESS, payload: response });
    }
  } catch (error) {
    yield put({
      type: GET_FILE_ERROR,
      payload: "Error al buscar la encuesta",
    });
  }
}

// function* deleteFile({ payload }): any {
//   try {
//     const { response, error } = yield call(services.storage.deleteFile, payload);
//     if (error) {
//       yield put({ type: DELETE_FILE_ERROR, payload: error });
//     } else {
//       yield all([
//         put({ type: DELETE_FILE_SUCCESS, payload: response }),
//         put({ type: RESET_GET_FILE, payload: null }),
//       ]);
//     }
//   } catch (error) {
//     yield put({
//       type: DELETE_FILE_ERROR,
//       payload: "Error al eliminar la encuesta.",
//     });
//   }
// }

/**
 * Watchers
 */
export default function* applicant() {
  yield takeLatest<any>(NEW_FILE, newFile);
  yield takeLatest<any>(GET_FILE, getFile);
  //yield takeLatest<any>(DELETE_FILE, deleteFile);
}
